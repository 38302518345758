import React, { useContext, useEffect, useState } from "react";
import Courier from "../models/Courier";
import Order from "../models/Order";
import Product from "../models/Product";
import ProductCategory from "../models/ProductCategory";
import RestaurantInfos from "../models/RestaurantInfos";
import WorkingDays from "../models/WorkingDays";
import API from "../services";
import ProductsOption from "../models/ProductsOptions";
import buildEnv from "../build.env.json";
import { useIonAlert } from "@ionic/react";
import i18n from "../translations/i18n";
import AuthContext from "./auth.ctx";

const defaultRestaurantId =
  buildEnv.BUILD_ENV == "PROD"
    ? "9fce979a-02f0-4156-b33d-9f2c215bf8cf" //HappyB
    : "f8edcd4e-e195-4285-96ae-b65e3ccb89d3";

const RestaurantDataContext = React.createContext({
  isInit: false,
  restaurantId: "",
  restaurantFound: false,
  setRestaurantIdHandler: (restaurantId: string) => {},
  productCategoriesList: [] as ProductCategory[],
  productsList: [] as Product[],
  productsOptionsList: [] as ProductsOption[],
  initRestaurantData: (restaurantId: string) => {},
  restaurantInfos: {} as RestaurantInfos,
  workingDays: {} as WorkingDays,
});

export const RestaurantDataContextProvider: React.FC = (props) => {
  const [isInit, setIsInit] = useState(false);
  const [restaurantFound, setRestaurantFound] = useState(false);
  const [restaurantInfos, setRestaurantInfos] = useState({} as RestaurantInfos);
  const [productCategoriesList, setProductCategoriesList] = useState(
    [] as ProductCategory[]
  );
  const [present, dismiss] = useIonAlert();

  const authCtx = useContext(AuthContext);
  //Working days
  const [workingDays, setWorkingDays] = useState({} as WorkingDays);

  const [restaurantId, setRestaurantId] = useState(defaultRestaurantId);

  const [productsList, setProductsList] = useState([] as Product[]);
  const [productsOptionsList, setProductsOptionsList] = useState(
    [] as ProductsOption[]
  );

  const setRestaurantIdHandler = (restaurantId: string) => {
    setRestaurantId(restaurantId);
  };

  useEffect(() => {
    const restaurantFavoriteOrders = authCtx.favoriteOrders?.filter(
      (favoriteOrder) => favoriteOrder.restaurantId === restaurantId
    );
    if (restaurantFavoriteOrders && restaurantFavoriteOrders.length) {
      const mostRecentFavOrder = restaurantFavoriteOrders.reduce(
        (mostRecentFavOrder, currentFavOrder) => {
          const mostRecentFavDate =
            mostRecentFavOrder.createdAt._seconds * 1000 +
            mostRecentFavOrder.createdAt._nanoseconds / 1e6;
          const currentFavOrderDate =
            currentFavOrder.createdAt._seconds * 1000 +
            currentFavOrder.createdAt._nanoseconds / 1e6;
          return currentFavOrderDate > mostRecentFavDate
            ? currentFavOrder
            : mostRecentFavOrder;
        },
        restaurantFavoriteOrders[0]
      );

      authCtx.setRestaurantFavoriteOrder(mostRecentFavOrder);
    }
  }, [restaurantId, authCtx.favoriteOrders]);

  const initRestaurantData = async (restaurantUrlSlug: string) => {
    if (restaurantUrlSlug) {
      API.getRestaurantData(restaurantUrlSlug, restaurantUrlSlug)
        .then((data) => {
          document.title = restaurantUrlSlug;
          //console.log(data);
          setRestaurantFound(true);
          setRestaurantId(data.restaurantInfos.id);
          setRestaurantInfos(data.restaurantInfos);
          setProductsList(data.products);
          setProductCategoriesList(data.productCategories);
          setProductsOptionsList(data.productsOptions);
          setWorkingDays(data.workingDays);
          setIsInit(true);
          document.title = data.restaurantInfos.name
            ? data.restaurantInfos.name
            : "IziUp";
          let link: HTMLLinkElement | null =
            document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          if (data.restaurantInfos.logoThumbnailUrl) {
            link.href = data.restaurantInfos.logoThumbnailUrl;
          } else if (data.restaurantInfos.logoUrl) {
            link.href = data.restaurantInfos.logoUrl;
          }
        })
        .catch((e) => {
          setIsInit(true);
          setRestaurantFound(false);
          present({
            header: i18n.t("context.Error"),
            message: i18n.t("context.NoRestaurant"),
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {},
          });
          console.log(e);
        });
    }
  };

  return (
    <RestaurantDataContext.Provider
      value={{
        isInit,
        restaurantId,
        restaurantFound,
        setRestaurantIdHandler,
        productCategoriesList,
        productsList,
        productsOptionsList,
        initRestaurantData,
        restaurantInfos,
        workingDays,
      }}
    >
      {props.children}
    </RestaurantDataContext.Provider>
  );
};

export default RestaurantDataContext;
