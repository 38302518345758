import { IonIcon, IonImg } from "@ionic/react";
import { fastFood } from "ionicons/icons";
import "./ProductsGridNoStateItem.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const ProductsGridNoStateItem: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="products-grid-no-state-item"
      data-testid="products-grid-no-state-item"
    >
      <div className="products-grid-no-state-item__picture-and-infos">
        <IonIcon icon={fastFood} />
      </div>
      <div className="products-grid-no-state-item__product-name">
        {i18n.t("order.NoProduct")}
      </div>
    </div>
  );
};

export default ProductsGridNoStateItem;
