import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { PhoneNumberSignInResponse } from "../types";
import { $firebaseAuth, $firebaseFunctions } from "./firebase-service";
import buildEnv from "../build.env.json";
import axios from "axios";
import { customerData } from "../types";
import { constants } from "../utils/constants";
// import errors from "../../messages/error";
// import success from "../../messages/success";
const apiEndpoints = {
  getUserData: {
    dev: `${constants.API_URL}/customerApi/getUserData`,
    prod: `${constants.API_URL}/customerApi/getUserData`,
  },
  updateUserData: {
    dev: `${constants.API_URL}/customerApi/updateUserData`,
    prod: `${constants.API_URL}/customerApi/updateUserData`,
  },
};

const authServices = {
  signInWithPhoneNumber(
    phoneNumber: string,
    appVerifier: firebase.auth.ApplicationVerifier
  ): Promise<any> {
    return new Promise((res, rej) => {
      $firebaseAuth
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(async (data) => {
          res(data);
        })
        .catch((e) => {
          rej("error");
        });
    });
  },

  getAuthenticatedUserData(): Promise<any> {
    return new Promise(async (res, rej) => {
      const idToken = await $firebaseAuth.currentUser?.getIdToken();
      if (idToken) {
        const apiEndpoint =
          buildEnv.BUILD_ENV == "DEV"
            ? apiEndpoints.getUserData.dev
            : apiEndpoints.getUserData.prod;
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        axios
          .get(apiEndpoint, {
            headers: headers,
          })
          .then((response) => {
            res(response.data);
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        rej("no user authenticated");
      }
    });
  },
  updateUserData(data: customerData): Promise<any> {
    return new Promise(async (res, rej) => {
      const idToken = await $firebaseAuth.currentUser?.getIdToken();
      if (idToken) {
        const apiEndpoint =
          buildEnv.BUILD_ENV == "DEV"
            ? apiEndpoints.updateUserData.dev
            : apiEndpoints.updateUserData.prod;
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        axios
          .post(apiEndpoint, data, {
            headers: headers,
          })
          .then((response) => {
            res(response.data);
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        rej("no user authenticated");
      }
    });
  },
};
export default authServices;
