import { IonIcon, IonModal, useIonAlert } from "@ionic/react";
import {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import Product from "../../../models/Product";
import ProductsOptions, {
  OptionItem,
  OptionType,
} from "../../../models/ProductsOptions";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import NewOrderContext from "../../../contexts/new-order.ctx";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import "./OrderProductOptionsModal.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import { chevronDown, colorFill } from "ionicons/icons";

interface ContainerProps {
  isOpen: boolean;
  onDismiss: Function;
  product: Product;
  onOptionSubmission: Function;
  isModif?: boolean;
}

const OrderProductOptionsModal: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  const [mandatoryProductIndex, setMandatoryProductIndex] = useState<
    number | null
  >(null);
  const [productsOptionsList, setProductsOptionsList] = useState(
    [] as ProductsOptions[]
  );
  const [productQuantity, setProductQuantity] = useState(
    props.product.quantity
  );

  const [productsOptionsSelections, setProductsOptionsSelections] = useState(
    props.product.optionsPreset
      ? props.product.optionsPreset
      : ([] as OptionItem[])
  );
  const productOptionsRef = useRef<(React.RefObject<HTMLDivElement> | null)[]>(
    []
  );
  const [present] = useIonAlert();

  useEffect(() => {
    const productsOptions = [...restaurantDataCtx.productsOptionsList].filter(
      (options) => {
        return props.product.productsOptionsIds?.includes(options.id);
      }
    );

    const orderedProductsOptions: any = [];

    props.product.productsOptionsIds?.map((productOption) => {
      const index = productsOptions.findIndex(
        (item) => item.id == productOption
      );
      if (index !== -1) {
        orderedProductsOptions.push(productsOptions[index]);
      }
    });
    productOptionsRef.current = orderedProductsOptions.map(() =>
      createRef<HTMLElement>()
    );
    setProductsOptionsList([...orderedProductsOptions]);
    if (props.product.optionsPreset) {
      setProductsOptionsSelections(props.product.optionsPreset);
    }
    setProductQuantity(props.product.quantity);
  }, [props.product.productsOptionsIds]);

  const handleScroll = () => {
    if (!containerRef.current) return;

    if (
      containerRef.current.scrollTop < 15 &&
      containerRef.current.scrollHeight > containerRef.current.clientHeight
    ) {
      setShowScrollIcon(true);
    } else if (containerRef.current) {
      setShowScrollIcon(false);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      setTimeout(() => handleScroll(), 500);
    } else {
      setShowScrollIcon(false);
    }
  }, [productsOptionsList, containerRef.current?.scrollHeight]);

  const productsOptionsSelectionHandler = (
    selection: OptionItem,
    options: ProductsOptions
  ) => {
    if (options.type == "MULTI") {
      if (
        productsOptionsSelections.find((option) => option.id == selection.id)
      ) {
        setProductsOptionsSelections(
          [...productsOptionsSelections].filter((pOp) => pOp.id != selection.id)
        );
      } else {
        setProductsOptionsSelections([...productsOptionsSelections, selection]);
      }
    } else {
      const newSelection = [...productsOptionsSelections];
      const cleanedUpSelection = newSelection.filter((alreadySelected) => {
        if (
          options.optionItems.findIndex(
            (opts) => opts.id == alreadySelected.id
          ) == -1
        ) {
          return alreadySelected;
        }
      });

      if (
        !newSelection.find(
          (alreadySelected) => alreadySelected.id == selection.id
        )
      ) {
        cleanedUpSelection.push(selection);
      }
      setProductsOptionsSelections(cleanedUpSelection);
    }
  };

  const handleAddProduct = () => {
    try {
      const tmpProductsOptionsList = [...productsOptionsList];
      tmpProductsOptionsList.forEach((productOptions, index) => {
        if (productOptions.isMandatory) {
          let mandatoryOptionSelected = false;
          productOptions.optionItems.forEach((option) => {
            if (
              productsOptionsSelections.findIndex(
                (prodOpts) => prodOpts.id == option.id
              ) !== -1
            ) {
              mandatoryOptionSelected = true;
            }
          });
          if (!mandatoryOptionSelected) {
            setMandatoryProductIndex(index);
            productOptionsRef?.current[index]?.current?.scrollIntoView({
              behavior: "smooth",
            });
            throw new Error();
          }
        }
      });
      props.onOptionSubmission(
        props.product,
        productQuantity,
        productsOptionsSelections
      );
      setShowScrollIcon(false);
      setProductsOptionsSelections([]);
    } catch (err) {
      console.log(err);

      present({
        header: i18n.t("modals.Error"),
        message: i18n.t("modals.MandatoryOptions"),
        buttons: [
          {
            text: "Ok",
            handler: (d) => {},
          },
        ],
        onDidDismiss: (e) => {},
      });
    }
  };

  const feedOptionsPresets = () => {
    if (props.product.optionsPreset) {
      setProductsOptionsSelections(props.product.optionsPreset);
    }
    if (props.product.quantity) {
      setProductQuantity(props.product.quantity);
    }
  };
  return (
    <IonModal
      isOpen={props.isOpen}
      backdropDismiss={false}
      onDidPresent={() => feedOptionsPresets()}
    >
      <div className="order-product-options-modal">
        <BaseModalHeader
          onDismiss={() => {
            props.onDismiss();
            setShowScrollIcon(false);
            setProductsOptionsSelections([]);
          }}
        >
          {i18n.t("modals.ProductOption")}
          <div
            style={{
              color: "var(--ion-color-secondary)",
              fontSize: 12,
              fontWeight: "lighter",
              marginTop: "0.25rem",
            }}
          >
            {" "}
            {i18n.t("modals.scrollDown")}
          </div>
        </BaseModalHeader>

        <div
          className="order-product-options-modal__options-frame"
          ref={containerRef}
        >
          {productsOptionsList.map((options, index) => {
            return (
              <div
                className="order-product-options-modal__options-frame__options"
                ref={productOptionsRef.current[index]}
                key={options.id}
              >
                <div style={{ marginBottom: 10 }}>
                  <div className="order-product-options-modal__options-frame__options-name">
                    {options.name}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="order-product-options-modal__options-frame__options-type">
                      {options.type == "MULTI"
                        ? i18n.t("modals.Multiple")
                        : i18n.t("modals.Unique")}{" "}
                    </span>
                    <span
                      className="order-product-options-modal__options-frame__options-type"
                      style={{
                        color:
                          mandatoryProductIndex == index ? "red" : undefined,
                      }}
                    >
                      {options.isMandatory
                        ? i18n.t("modals.Mandatory")
                        : i18n.t("modals.Optional")}
                    </span>
                  </div>
                </div>
                {options.optionItems.map((option) => {
                  return (
                    <div
                      key={options.id + option.id}
                      onClick={() =>
                        productsOptionsSelectionHandler(option, options)
                      }
                      className={
                        productsOptionsSelections.find(
                          (pOp) => pOp.id == option.id
                        ) && !option.unavailable
                          ? "order-product-options-modal__options-frame__options__item order-product-options-modal__options-frame__options__item--active"
                          : !option.unavailable
                          ? "order-product-options-modal__options-frame__options__item"
                          : "order-product-options-modal__options-frame__options__item order-product-options-modal__options-frame__options__item--unavailable"
                      }
                    >
                      <span>{option.name}</span>
                      <span>
                        {option.price
                          ? constants.CURRENCY === "€"
                            ? (option.price / 100).toFixed(2) +
                              ` ${constants.CURRENCY}`
                            : `${constants.CURRENCY}` +
                              (option.price / 100).toFixed(2)
                          : constants.CURRENCY === "€"
                          ? `0 ${constants.CURRENCY}`
                          : `${constants.CURRENCY}0`}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="order-product-options-modal__quantity-container">
          <div className="order-product-options-modal__quantity-text">
            {i18n.t("modals.Quantity")}
          </div>
          <div className="order-product-options-modal__quantity-setter">
            <div
              className="order-product-options-modal__quantity-plus-minus"
              onClick={() => {
                setProductQuantity(Math.max(productQuantity - 1, 0));
              }}
            >
              -
            </div>
            <div className="order-product-options-modal__quantity-amount">
              {productQuantity}
            </div>
            <div
              className="order-product-options-modal__quantity-plus-minus"
              onClick={() => {
                setProductQuantity(productQuantity + 1);
              }}
            >
              +
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: -120,
              left: 0,
              right: 0,
              zIndex: 2,
              pointerEvents: "none",
            }}
          >
            {showScrollIcon ? (
              // <img src="/path/to/your/icon.png" alt="scroll icon" />
              <div className="chevrons">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <IonIcon
                    icon={chevronDown}
                    style={{
                      width: 25,
                      height: 25,
                      color: "var(--ion-color-secondary)",
                      marginBottom: -15,
                    }}
                  ></IonIcon>
                  <IonIcon
                    icon={chevronDown}
                    style={{
                      width: 25,
                      height: 25,
                      color: "var(--ion-color-secondary)",
                    }}
                  ></IonIcon>
                </div>
                <div
                  style={{
                    margin: "0 0.75rem",
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "var(--ion-color-primary)",
                  }}
                >
                  {" "}
                  {i18n.t("cart.scrollDown")}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <IonIcon
                    icon={chevronDown}
                    style={{
                      width: 25,
                      height: 25,
                      color: "var(--ion-color-secondary)",
                      marginBottom: -15,
                    }}
                  ></IonIcon>
                  <IonIcon
                    icon={chevronDown}
                    style={{
                      width: 25,
                      height: 25,
                      color: "var(--ion-color-secondary)",
                    }}
                  ></IonIcon>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="order-product-options-modal__confirm-button">
          <BaseButton
            expand
            onClick={() => {
              handleAddProduct();
            }}
          >
            {props.isModif
              ? i18n.t("modals.Modification")
              : i18n.t("modals.Add")}
          </BaseButton>
        </div>
      </div>
    </IonModal>
  );
};

export default OrderProductOptionsModal;
