import buildEnvConfig from "../build.env.json";

const devConfig = {
  envName: "DEV",
  googleMapsApiKey: "AIzaSyCALH-sYxgMNOGCxYifVmNEz85O1-ycxtU",
  googlePlacesApiKey: "AIzaSyDMB3ChHe6UUE3LE5LtMo8Z50SI-xlZSOk",
};
const prodConfig = {
  envName: "PROD",
  googleMapsApiKey: "AIzaSyDDs9REV6gPkuXvmH9miAwYdOwk4ErU3nI",
  googlePlacesApiKey: "AIzaSyAkZgi2IE3CVbuW2s2BAfWp-6-O3M7jo2c",
};

const emuConfig = {
  envName: "EMU",
  googleMapsApiKey: devConfig.googleMapsApiKey,
  googlePlacesApiKey: devConfig.googlePlacesApiKey,
};

export default () => {
  if (buildEnvConfig.BUILD_ENV == "PROD") {
    {
      return prodConfig;
    }
  } else if (buildEnvConfig.BUILD_ENV == "DEV") {
    return devConfig;
  } else {
    return emuConfig;
  }
};
