import { IonIcon, IonImg, useIonAlert } from "@ionic/react";
import { card, cash } from "ionicons/icons";
import { useContext, useEffect } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./OrderIsADeliverySelector.css";
import RoleButton from "../../@Shared/RoleButton/RoleButton";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
interface ContainerProps {}

const OrderIsADeliverySelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [present, dismiss] = useIonAlert();
  const goToPreviousStep = () => {
    newOrderCtx.currentOrderStepChangeHandler("PRODUCTS_SELECTION");
  };

  const onConfirmHandler = () => {
    if (newOrderCtx.orderType === "DELIVERY") {
      const minimumOrderAmount = restaurantDataCtx.restaurantInfos
        .minimumOrderAmount
        ? restaurantDataCtx.restaurantInfos.minimumOrderAmount / 100
        : 0;
      const totalAmount = getTotalPrice();
      if (totalAmount < minimumOrderAmount) {
        present({
          header: i18n.t("order.Error"),
          message:
            constants.CURRENCY === "€"
              ? `${i18n.t(
                  "order.MinimumOrderDelivery"
                )} ${minimumOrderAmount} ${constants.CURRENCY}`
              : `${constants.CURRENCY}${i18n.t(
                  "order.MinimumOrderDelivery"
                )} ${minimumOrderAmount}`,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {},
        });
      } else {
        newOrderCtx.currentOrderStepChangeHandler("IS_PLANNED_ORDER");
      }
    } else {
      newOrderCtx.currentOrderStepChangeHandler("IS_PLANNED_ORDER");
    }
  };

  const getTotalPrice = () => {
    return (
      newOrderCtx.cart.reduce((prev, product) => {
        return prev + product.price;
      }, 0) / 100
    );
  };

  useEffect(() => {
    if (restaurantDataCtx.restaurantInfos.clickAndCollectClosed) {
      newOrderCtx.orderTypeSelectionHandler("DELIVERY");
    } else if (restaurantDataCtx.restaurantInfos.deliveryClosed) {
      newOrderCtx.orderTypeSelectionHandler("ON_PLACE");
    }
  }, []);

  return (
    <div
      className="orders-is-a-delivery-selector"
      data-testid="orders-is-a-delivery-selector"
    >
      <div className="orders-is-a-delivery-selector__selector">
        <RoleButton
          role="DELIVERY"
          selected={newOrderCtx.orderType == "DELIVERY"}
          onClick={() => newOrderCtx.orderTypeSelectionHandler("DELIVERY")}
          disabled={restaurantDataCtx.restaurantInfos.deliveryClosed}
        />

        {restaurantDataCtx.restaurantInfos.deliveryClosed ? null : (
          <div
            style={{
              textAlign: "center",
              color: "var(--ion-color-secondary)",
              fontStyle: "italic",
              fontWeight: "bold",
              fontSize: 30,
            }}
          >
            {i18n.t("order.or")}
          </div>
        )}

        <RoleButton
          role="ON_PLACE"
          selected={newOrderCtx.orderType == "ON_PLACE"}
          onClick={() => newOrderCtx.orderTypeSelectionHandler("ON_PLACE")}
          disabled={restaurantDataCtx.restaurantInfos.clickAndCollectClosed}
        />
      </div>
      <div className="orders-is-a-delivery-selector__button-container">
        <div className="">
          <BaseButton
            expand
            tight
            outline
            // disabled={isPreviousButtonDisabled()}
            onClick={() => {
              goToPreviousStep();
            }}
          >
            {i18n.t("order.Previous")}
          </BaseButton>
        </div>
        <div className="">
          <BaseButton
            onClick={() => {
              onConfirmHandler();
            }}
            tight
            expand
            isLoading={newOrderCtx.isOrderInProgress}
          >
            {newOrderCtx.isOrderInProgress
              ? i18n.t("order.Waiting")
              : i18n.t("order.Next")}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};

export default OrderIsADeliverySelector;
