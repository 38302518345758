import buildEnv from "../build.env.json";
import axios from "axios";
import Product from "../models/Product";
import ProductCategory from "../models/ProductCategory";
import ProductsOptions from "../models/ProductsOptions";
import RestaurantInfos from "../models/RestaurantInfos";
import WorkingDays from "../models/WorkingDays";
import { $firebaseAuth, $firestore } from "./firebase-service";
import { constants } from "../utils/constants";

const defaultRestaurantId =
  buildEnv.BUILD_ENV == "PROD"
    ? "9fce979a-02f0-4156-b33d-9f2c215bf8cf" //Happy
    : "f8edcd4e-e195-4285-96ae-b65e3ccb89d3";
const apiEndpoints = {
  restaurantData: {
    dev: `${constants.API_URL}/customerApi/restaurantData`,
    prod: `${constants.API_URL}/customerApi/restaurantData`,
  },
  createLocalUnpaidOrder: {
    dev: `${constants.API_URL}/customerApi/createLocalUnpaidOrder`,
    prod: `${constants.API_URL}/customerApi/createLocalUnpaidOrder`,
  },
};
export type RestaurantData = {
  restaurantInfos: RestaurantInfos;
  products: Product[];
  productCategories: ProductCategory[];
  productsOptions: ProductsOptions[];
  workingDays: WorkingDays;
};
export default class ShopService {
  async getRestaurantData(
    restaurantId: string,
    urlSlug: string
  ): Promise<RestaurantData> {
    return new Promise((res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.restaurantData.dev
          : apiEndpoints.restaurantData.prod;

      axios
        .post(apiEndpoint, {
          restaurantId: restaurantId ? restaurantId : defaultRestaurantId,
          urlSlug: urlSlug,
        })
        .then((response: Record<any, any>) => {
          const restaurantInfos = new RestaurantInfos(
            response.data.restaurantInfos?.id,
            response.data.restaurantInfos?.name,
            response.data.restaurantInfos?.phone,
            response.data.restaurantInfos?.email,
            response.data.restaurantInfos?.logo_url,
            response.data.restaurantInfos?.logo_thumbnail_url,
            response.data.restaurantInfos?.address_first_line,
            response.data.restaurantInfos?.address_second_line,
            response.data.restaurantInfos?.address_zipcode,
            response.data.restaurantInfos?.address_city,
            response.data.restaurantInfos?.address_latitude,
            response.data.restaurantInfos?.address_longitude,
            response.data.restaurantInfos?.address_place_id,
            response.data.restaurantInfos?.current_delivery_time,
            response.data.restaurantInfos?.delivery_fees,
            response.data.restaurantInfos?.free_delivery_fees_trigger,
            response.data.restaurantInfos?.available_cities_on_delivery,
            response.data.restaurantInfos?.current_click_and_collect_time,
            response.data.restaurantInfos?.pixel_id,
            response.data.restaurantInfos?.delivery_closed,
            response.data.restaurantInfos?.click_and_collect_closed,
            response.data.restaurantInfos?.door_payment_enabled,
            response.data.restaurantInfos?.current_delivery_time_by_km || 10,
            response.data.restaurantInfos?.delivery_fees_by_km,
            response.data.restaurantInfos?.max_delivery_range,
            response.data.restaurantInfos?.minimum_order_amount,
            response.data.restaurantInfos?.subscription_plan,
            response.data.restaurantInfos?.review_link
          );
          const products: Product[] = [];
          response.data.products?.map((prod: Record<any, any>) => {
            products.push(
              new Product(
                prod.id,
                prod.name,
                prod.price,
                prod.price,
                prod.category_id,
                prod.has_options,
                prod.picture_url,
                prod.thumbnail_url,
                1,
                prod.description,
                prod.products_options_ids,
                [],
                prod.unavailable,
                prod.long_desc
              )
            );
          });
          const categories: ProductCategory[] = [];
          response.data.productCategories?.map((cat: Record<any, any>) => {
            categories.push(
              new ProductCategory(cat.id, cat.name, cat.color, cat.position)
            );
          });
          const options: ProductsOptions[] = [];
          response.data.productsOptions?.map((opt: Record<any, any>) => {
            options.push(
              new ProductsOptions(
                opt.id,
                opt.name,
                opt.type,
                opt.option_items,
                opt.is_mandatory
              )
            );
          });

          const workingDays = response.data.restaurantInfos?.workingDays;
          res({
            restaurantInfos: restaurantInfos,
            products: products,
            productCategories: categories,
            productsOptions: options,
            workingDays: workingDays,
          });
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }
  async createNewLocalUnpaidOrder(
    cart: { id: string; optionsIds?: string[] }[],
    paymentType: "STRIPE" | "IRL",
    restaurantId: string,
    orderType: string,
    orderPlannedTime: Date,
    orderPlanningType: string,
    shouldStartOrder: boolean,
    customer?: Record<any, any>
  ): Promise<number> {
    return new Promise(async (res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.createLocalUnpaidOrder.dev
          : apiEndpoints.createLocalUnpaidOrder.prod;
      const idToken = await $firebaseAuth.currentUser?.getIdToken();
      if (idToken) {
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        axios
          .post(
            apiEndpoint,
            {
              restaurantId: restaurantId,
              paymentType,
              cart,
              orderType,
              orderPlannedTime,
              orderPlanningType,
              shouldStartOrder,
              customer,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.status == 500) {
              rej("Error on create local order");
            }
            res(response.data.order_number);
          })
          .catch((err) => {
            console.log(err);
            rej(err);
          });
      } else {
        rej("user not authenticated");
      }
    });
  }
}
