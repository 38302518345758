import { useContext, useEffect, useState } from "react";
import NewCustomerContext from "../../../contexts/new-customer.ctx";
import { IonImg, useIonAlert } from "@ionic/react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import { $firebaseAuth } from "../../../services/firebase-service";
import "./OrdersWebPayment.css";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import { customerPaymentMethod } from "../../../types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import buildEnv from "../../../build.env.json";
import axios from "axios";
import CheckoutForm from "../../Checkout/CheckoutForm/CheckoutForm";
import AppLoading from "../../@Shared/AppLoading/AppLoading";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import { IonIcon } from "@ionic/react";
import {
  locationOutline,
  timeOutline,
  eyeOutline,
  pricetagOutline,
  heart,
  heartOutline,
  colorFill,
  lockClosed,
} from "ionicons/icons";
import orderServices from "../../../services/order-service";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import { customToFixed } from "../../../utils/customTofixed";
import { useIonModal } from "@ionic/react";
import OrderDetailModal from "../../Modals/OrderDetailModal/OrderDetailModal";
import OrderTrackingContext from "../../../contexts/orders-tracking.ctx";
const stripePromise = loadStripe(constants.STRIPE_PUBLIC_KEY);

type OrderType = "DELIVERY" | "ON_PLACE";
type OrderPlanningType = "NOW" | "PLANNED";
interface newOrderParams {
  restaurantId: string;
  cart: Array<any>;
  orderType: string;
  orderPlanningType: string;
  shouldStartOrder: boolean;
  orderPlannedTime?: Date;
  customer?: Object;
  promoCode?: string;
  tip?: number;
  cardFutureUsage?: boolean;
  paymentIntentId?: string;
}

interface ContainerProps {}

const apiEndpoints = {
  paymentIntent: {
    dev: `${constants.API_URL}/customerApi/createPaymentIntentDev`,
    prod: `${constants.API_URL}/customerApi/createPaymentIntent`,
  },
};

const TipItem = ({
  orderAmount,
  index,
  selectedIndex,
  tipPercentage,
  setTip,
  setSelectedIndex,
  color,
}: {
  orderAmount: number;
  index: number;
  selectedIndex: number | undefined;
  tipPercentage?: number;
  setTip: (amount: number) => void;
  setSelectedIndex: (index?: number) => void;
  color?: string;
}) => {
  const tipAmount = tipPercentage ? (orderAmount * tipPercentage) / 100 : 0;
  const [hovered, setHovered] = useState(false);
  const onClick = () => {
    if (index !== selectedIndex) {
      if (tipAmount) {
        setTip(tipAmount);
      }
      setSelectedIndex(index);
    } else {
      setTip(0);
      setSelectedIndex(undefined);
    }
  };
  return (
    <div
      style={{
        width: "23%",
        height: 50,
        color: "#fff",
        padding: "0.5rem",
        backgroundColor:
          selectedIndex === index ? (color ? color : "#fe8f2e") : "#364051",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        cursor: "pointer",
        opacity: selectedIndex === index || !hovered ? 1 : 0.8,
      }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {tipPercentage ? (
        <div>
          <div style={{ fontSize: 14, fontWeight: "bold" }}>
            {customToFixed(tipPercentage)}%
          </div>
          <div style={{ fontSize: 12, fontStyle: "italic" }}>
            {constants.CURRENCY === "€"
              ? tipAmount.toFixed(2) + constants.CURRENCY
              : constants.CURRENCY + tipAmount.toFixed(2)}
          </div>
        </div>
      ) : (
        <div style={{ fontSize: 14, fontWeight: "bold" }}>
          {i18n.t("order.other")}
        </div>
      )}
    </div>
  );
};
const OrdersWebPayment: React.FC<ContainerProps> = (props) => {
  const newCustomerCtx = useContext(NewCustomerContext);
  const newOrderCtx = useContext(NewOrderContext);
  const orderTrackingCtx = useContext(OrderTrackingContext);
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [favoriteOrderId, setFavoriteOrderId] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [inputTip, setInputTip] = useState<number | undefined>();
  const [tipIndex, setTipIndex] = useState<number | undefined>();
  const [amount, setAmount] = useState(0);
  const [cardFutureUsage, setCardFutureUsage] = useState(true);
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [tip, setTip] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [promo, setPromo] = useState(0);
  const [promoFreeDelivery, setFreeDelivery] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [paymentMethods, setPaymentMethods] =
    useState<Array<customerPaymentMethod>>();
  const [shouldRenderStripeElements, setShouldRenderStripeElements] = useState<
    boolean | undefined
  >();
  const [paymentMethodIdSelected, setPaymentMethodIdSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [present, dismiss] = useIonAlert();

  const [presentOrderModal, dismissOrderModal] = useIonModal(OrderDetailModal, {
    orderContext: newOrderCtx,
    minimumOrderAmount: restaurantDataCtx.restaurantInfos.minimumOrderAmount,
    onDismiss: () => {
      requestPaymentIntent();
      dismissOrderModal();
    },
  });

  const getFormatedTimeFromDate = (date: Date) => {
    let tmpHour = date.getHours();
    let tmpMinutes = date.getMinutes();
    let formatedHour = tmpHour < 10 ? `0${tmpHour}` : `${tmpHour}`;
    let formatedMinutes = tmpMinutes < 10 ? `0${tmpMinutes}` : `${tmpMinutes}`;
    return `${formatedHour}:${formatedMinutes}`;
  };
  const addFavoriteOrder = () => {
    if (!isLoading) {
      setIsLoading(true);
      const cartToSubmit = newOrderCtx.cart.map((item) => {
        const optionsIds = item.optionsPreset?.map((optPres) => {
          return optPres.id;
        });
        return {
          id: item.id,
          quantity: item.quantity,
          optionsIds,
        };
      });
      orderServices
        .addFavoriteOrder(restaurantDataCtx.restaurantId, cartToSubmit)
        .then((newFavoriteOrderId) => {
          setFavoriteOrderId(newFavoriteOrderId);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  const removeFavoriteOrder = () => {
    if (!isLoading && favoriteOrderId) {
      setIsLoading(true);
      orderServices
        .removeFavoriteOrder(restaurantDataCtx.restaurantId, favoriteOrderId)
        .then(() => {
          setFavoriteOrderId("");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const isToday = (date: Date) => {
    // Create a new date representing today
    const today = new Date();

    // Compare the year, month, and day
    const isSameDay =
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate();

    return isSameDay;
  };

  const myDate = new Date();
  const result = isToday(myDate);

  const deletePaymentMethod = (paymentMethodId?: string) => {
    if (!isLoading && paymentMethodId) {
      setIsLoading(true);
      orderServices
        .deletePaymentMethod(paymentMethodId)
        .then((newPaymentsMethods) => {
          setPaymentMethods(newPaymentsMethods);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  function isDifferenceGreaterThanSixMinutes(
    date1: Date,
    date2: Date
  ): boolean {
    console.log(date1, date2);
    // Calcule la différence en millisecondes entre les deux dates
    const differenceInMilliseconds = Math.abs(
      date1.getTime() - date2.getTime()
    );

    // Convertit la différence en minutes
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    // Vérifie si la différence est supérieure à 6 minutes
    return differenceInMinutes > 6;
  }

  const requestPaymentIntent = (tipParam?: number) => {
    if (!isLoading) {
      setIsLoading(true);
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.paymentIntent.dev
          : apiEndpoints.paymentIntent.prod;
      const cartToSubmit = newOrderCtx.cart.map((item) => {
        const optionsIds = item.optionsPreset?.map((optPres) => {
          return optPres.id;
        });
        return {
          id: item.id,
          quantity: item.quantity,
          optionsIds,
        };
      });

      let requestParams: newOrderParams = {
        restaurantId: restaurantDataCtx.restaurantId,
        cardFutureUsage: cardFutureUsage,
        cart: cartToSubmit,
        orderType: newOrderCtx.orderType,
        orderPlanningType: newOrderCtx.orderPlanningType,
        shouldStartOrder: newOrderCtx.shouldStartOrder,
        customer:
          newOrderCtx.orderType == "DELIVERY"
            ? newOrderCtx.customer
            : {
                phone: authCtx.userData.phone_number_auth
                  ? authCtx.userData.phone_number_auth
                  : authCtx.userData.phone,
              },
      };
      if (promoCode) {
        requestParams.promoCode = promoCode;
      }
      if (inputTip || tipParam !== undefined) {
        requestParams.tip =
          tipParam !== undefined
            ? Math.round(tipParam * 100)
            : inputTip
            ? Math.round(inputTip * 100)
            : 0;
      }
      if (newOrderCtx.orderType == "DELIVERY") {
        requestParams.customer = newOrderCtx.customer;
      }
      if (newOrderCtx.orderPlanningType == "PLANNED") {
        requestParams.orderPlannedTime = newOrderCtx.orderPlannedTime;
      }
      if (paymentIntentId) {
        requestParams.paymentIntentId = paymentIntentId;
      }

      $firebaseAuth.currentUser
        ?.getIdToken()
        .then((idToken) => {
          const headers = {
            Authorization: `Bearer ${idToken}`,
          };
          axios
            .post(apiEndpoint, requestParams, {
              headers: headers,
            })
            .then((response) => {
              if (response.status == 500) {
                console.log("Error on create local order");
              }

              setPaymentMethods(
                response.data.customerPaymentMethods?.filter(
                  (card: customerPaymentMethod) => !card.is_expired
                )
              );
              if (shouldRenderStripeElements === undefined) {
                setShouldRenderStripeElements(
                  !response.data.customerPaymentMethods.length
                );
              }
              setPaymentIntentId(response.data.paymentIntentId);
              setFreeDelivery(response.data.freeDelivery);
              setClientSecret(response.data.clientSecret);
              setAmount(Math.round(response.data.paymentAmount * 100) / 10000);
              setTip(Math.round(response.data.tip * 100) / 10000);
              setTaxes(Math.round(response.data.taxes * 100) / 10000);
              setPromo(Math.round(response.data.promo * 100) / 10000);
              setDeliveryFees(
                Math.round(response.data.deliveryFees * 100) / 10000
              );
              setPromoError("");
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              if (err?.response?.data?.errPromoCode) {
                setPromoError(err?.response?.data?.errPromoCode);
              } else {
                present({
                  header: "Erreur",
                  message: err
                    ? err.response
                      ? err.response.data
                        ? `${i18n.t("order.ErrorOccured")}\nTech : ${
                            err.response.data.error
                          }`
                        : i18n.t("order.ErrorOccured")
                      : i18n.t("order.ErrorOccured")
                    : i18n.t("order.ErrorOccured"),
                  buttons: [{ text: "Ok", handler: (d) => {} }],
                  onDidDismiss: (e) => {},
                });
              }
            });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          present({
            header: i18n.t("order.Error"),
            message: i18n.t("order.ErrorAuth"),
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {},
          });
        });
    }
  };

  useEffect(() => {
    requestPaymentIntent();
    setIsInit(true);
  }, [cardFutureUsage]);

  const getTotalPriceFromProducts = () => {
    const reducedAmount = newOrderCtx.cart
      .map((product) => {
        return product.price | 0;
      })
      .reduce((prev, current) => {
        return current + prev;
      }, 0);

    return reducedAmount / 100;
  };
  const total = getTotalPriceFromProducts();

  const nowDate = new Date();
  var deliveryTimeDateObj = restaurantDataCtx.restaurantInfos
    .currentDeliveryTime
    ? new Date(
        nowDate.getTime() +
          restaurantDataCtx.restaurantInfos.currentDeliveryTime * 60000
      )
    : new Date(nowDate.getTime() + 30 * 60000);

  var clickAndCollectTimeDateObj = restaurantDataCtx.restaurantInfos
    .currentClickAndCollectTime
    ? new Date(
        nowDate.getTime() +
          restaurantDataCtx.restaurantInfos.currentClickAndCollectTime * 60000
      )
    : new Date(nowDate.getTime() + 20 * 60000);

  const estimatedDeliveryTime = deliveryTimeDateObj
    ? getFormatedTimeFromDate(deliveryTimeDateObj)
    : null;
  const estimatedClickAndCollectTime = clickAndCollectTimeDateObj
    ? getFormatedTimeFromDate(clickAndCollectTimeDateObj)
    : null;
  const orderEstimatedTime =
    newOrderCtx.orderPlanningType == "PLANNED"
      ? getFormatedTimeFromDate(newOrderCtx.orderPlannedTime)
      : newOrderCtx.orderType == "DELIVERY"
      ? estimatedDeliveryTime
      : estimatedClickAndCollectTime;
  const isTodayToShow =
    newOrderCtx.orderPlanningType == "PLANNED"
      ? isToday(newOrderCtx.orderPlannedTime)
      : newOrderCtx.orderType == "DELIVERY"
      ? isToday(deliveryTimeDateObj)
      : isToday(clickAndCollectTimeDateObj);
  if (authCtx.authState == AuthStates.NOT_AUTH) {
    return (
      <div className="orders-web-payment" data-testid="orders-web-payment">
        <div className="orders-web-payment__form">
          <div className="orders-web-payment__form__title">
            {i18n.t("order.ErrorAuthOrder")}
          </div>
        </div>
      </div>
    );
  }
  return clientSecret ? (
    <div className="orders-web-payment" data-testid="orders-web-payment">
      <div className="orders-web-payment__form">
        <>
          <div className="orders-web-payment__form__recap-title">
            {newOrderCtx.orderType === "DELIVERY"
              ? i18n.t("order.MyDelivery")
              : i18n.t("order.AddressRestaurant")}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "8px 0",
                  }}
                >
                  <IonIcon icon={locationOutline}></IonIcon>
                  <span
                    style={{
                      margin: 0,
                      paddingLeft: 4,
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >
                    {newOrderCtx.orderType === "DELIVERY"
                      ? `${newCustomerCtx.selectedSuggestion.structured_formatting.main_text} ${newCustomerCtx.addressComplement}`
                      : `${restaurantDataCtx.restaurantInfos.addressFirstLine} 
                          ${restaurantDataCtx.restaurantInfos.addressSecondLine}, ${restaurantDataCtx.restaurantInfos.addressCity}
                          ${restaurantDataCtx.restaurantInfos.addressZipcode}`}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IonIcon icon={timeOutline}></IonIcon>
                  <span
                    style={{
                      margin: 0,
                      paddingLeft: 4,
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >
                    {" "}
                    {isTodayToShow
                      ? i18n.t("order.todayAt")
                      : i18n.t("order.tomorrowAt")}{" "}
                    {orderEstimatedTime} ({i18n.t("order.estimatedTime")})
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "50%",
                  cursor: "pointer",
                }}
                onClick={() => presentOrderModal()}
              >
                <span
                  style={{
                    margin: 0,
                    paddingRight: 6,
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  {i18n.t("cart.seeOrder")}
                </span>
                <IonIcon
                  icon={eyeOutline}
                  style={{ width: 18, height: 18 }}
                ></IonIcon>
              </div>
            </div>
          </div>
          <div
            className="orders-web-payment__form__recap-title"
            style={{ marginBottom: 6 }}
          >
            {i18n.t("order.yourOrder")} :
            <div>
              <div className="orders-web-payment__order-line">
                <span className="orders-web-payment__order-line-text">
                  {" "}
                  {i18n.t("order.subtotal")}
                </span>
                <span className="orders-web-payment__order-line-text">
                  {" "}
                  {constants.CURRENCY === "€"
                    ? (amount - taxes - deliveryFees - tip + promo).toFixed(2) +
                      constants.CURRENCY
                    : constants.CURRENCY +
                      (amount - taxes - deliveryFees - tip + promo).toFixed(
                        2
                      )}{" "}
                </span>
              </div>
              {promo ? (
                <div className="orders-web-payment__order-line">
                  <span
                    className="orders-web-payment__order-line-text"
                    style={{ color: "#0AE83E" }}
                  >
                    {" "}
                    {i18n.t("order.promo")}
                  </span>
                  <span
                    className="orders-web-payment__order-line-text"
                    style={{ color: "#0AE83E" }}
                  >
                    {" "}
                    {constants.CURRENCY === "€"
                      ? promo.toFixed(2) + constants.CURRENCY
                      : constants.CURRENCY + promo.toFixed(2)}
                  </span>
                </div>
              ) : null}
              <div className="orders-web-payment__order-line">
                <span
                  className="orders-web-payment__order-line-text"
                  style={{ color: promoFreeDelivery ? "#0AE83E" : undefined }}
                >
                  {" "}
                  {i18n.t("order.deliveryFees")}
                </span>
                <span
                  className="orders-web-payment__order-line-text"
                  style={{ color: promoFreeDelivery ? "#0AE83E" : undefined }}
                >
                  {" "}
                  {promoFreeDelivery
                    ? i18n.t("order.free")
                    : constants.CURRENCY === "€"
                    ? `${deliveryFees} ${constants.CURRENCY}`
                    : `${constants.CURRENCY}${deliveryFees}`}
                </span>
              </div>
              <div className="orders-web-payment__order-line">
                <span className="orders-web-payment__order-line-text">
                  {" "}
                  {i18n.t("order.taxes")}
                </span>
                <span className="orders-web-payment__order-line-text">
                  {" "}
                  {constants.CURRENCY === "€"
                    ? taxes + constants.CURRENCY
                    : constants.CURRENCY + taxes}
                </span>
              </div>
              <div className="orders-web-payment__order-line">
                <span className="orders-web-payment__order-line-text">
                  {" "}
                  {i18n.t("order.tip")}
                </span>
                <span className="orders-web-payment__order-line-text">
                  {" "}
                  {constants.CURRENCY === "€"
                    ? tip + constants.CURRENCY
                    : constants.CURRENCY + tip}
                </span>
              </div>
              <div className="orders-web-payment__order-line">
                <span
                  className="orders-web-payment__order-line-text"
                  style={{ color: promo ? "#00FF3C" : "fff", fontWeight: 600 }}
                >
                  {" "}
                  {i18n.t("order.Total")}
                </span>
                <span
                  className="orders-web-payment__order-line-text"
                  style={{ color: promo ? "#00FF3C" : "fff", fontWeight: 600 }}
                >
                  {" "}
                  {constants.CURRENCY === "€"
                    ? amount.toFixed(2) + constants.CURRENCY
                    : constants.CURRENCY + amount.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div style={{ width: "66%", marginRight: "2%" }}>
              <BaseTextInput
                label={i18n.t("order.promoCode")}
                leftIcon={pricetagOutline}
                color={
                  promo > 0 || promoFreeDelivery
                    ? "#00E837"
                    : promoError
                    ? "#FF0000"
                    : undefined
                }
                controller={{
                  value: promoCode,
                  disabled: promo > 0,
                  onChange: (event: any) => {
                    setPromoCode(event.target.value);
                  },
                }}
                error={promoError}
              />
            </div>
            <div style={{ width: "33%" }}>
              <BaseButton
                expand
                isLoading={isLoading}
                onClick={() => requestPaymentIntent()}
                outline
                disabled={promo > 0}
                margins="0px"

                // tight={props.tightButton}
              >
                {i18n.t("order.apply")}
              </BaseButton>
            </div>
          </div>

          <div style={{ marginTop: 14 }}>
            <span
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "var(--ion-color-secondary)",
              }}
            >
              {i18n.t("order.AddATip")}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <TipItem
                orderAmount={amount - taxes - tip + promo}
                color={
                  tip !== 0 && tip === parseFloat(inputTip?.toFixed(2) || "")
                    ? "#234D26"
                    : undefined
                }
                index={0}
                selectedIndex={tipIndex}
                setSelectedIndex={(index?: number) =>
                  !isLoading ? setTipIndex(index) : null
                }
                setTip={(tipAmount: number) => {
                  if (!isLoading) {
                    setInputTip(tipAmount);
                    requestPaymentIntent(tipAmount);
                  }
                }}
                tipPercentage={15}
              />
              <TipItem
                orderAmount={amount - taxes - tip + promo}
                color={
                  tip !== 0 && tip === parseFloat(inputTip?.toFixed(2) || "")
                    ? "#234D26"
                    : undefined
                }
                index={1}
                selectedIndex={tipIndex}
                setSelectedIndex={(index?: number) =>
                  !isLoading ? setTipIndex(index) : null
                }
                setTip={(tipAmount: number) => {
                  if (!isLoading) {
                    setInputTip(tipAmount);
                    requestPaymentIntent(tipAmount);
                  }
                }}
                tipPercentage={20}
              />
              <TipItem
                orderAmount={amount - taxes - tip + promo}
                color={
                  tip !== 0 && tip === parseFloat(inputTip?.toFixed(2) || "")
                    ? "#234D26"
                    : undefined
                }
                index={2}
                selectedIndex={tipIndex}
                setSelectedIndex={(index?: number) =>
                  !isLoading ? setTipIndex(index) : null
                }
                setTip={(tipAmount: number) => {
                  if (!isLoading) {
                    setInputTip(tipAmount);
                    requestPaymentIntent(tipAmount);
                  }
                }}
                tipPercentage={25}
              />
              <TipItem
                color={
                  tip !== 0 && tip === parseFloat(inputTip?.toFixed(2) || "")
                    ? "#234D26"
                    : undefined
                }
                orderAmount={amount - taxes - tip + promo}
                index={3}
                selectedIndex={tipIndex}
                setSelectedIndex={(index?: number) => {
                  if (!isLoading) {
                    setInputTip(0);
                    setTipIndex(index);
                  }
                }}
                setTip={(tipAmount: number) =>
                  !isLoading ? setInputTip(tipAmount) : null
                }
              />
            </div>
            {tipIndex === 3 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ width: "66%", marginRight: "2%" }}>
                  <BaseTextInput
                    label={i18n.t("order.tip")}
                    rightText={constants.CURRENCY}
                    color={
                      tip !== 0 && tip === inputTip ? "#00E837" : undefined
                    }
                    controller={{
                      value: inputTip,
                      // pattern: "[0-9]*",
                      type: "number",
                      step: "0.01",
                      onChange: (event: any) => {
                        setInputTip(parseFloat(event.target.value));
                      },
                    }}
                  />
                </div>
                <div style={{ width: "33%" }}>
                  <BaseButton
                    expand
                    isLoading={isLoading}
                    onClick={() => requestPaymentIntent()}
                    outline
                    margins="0px"

                    // tight={props.tightButton}
                  >
                    {i18n.t("order.apply")}
                  </BaseButton>
                </div>
              </div>
            ) : null}
          </div>
        </>

        <div className="orders-web-payment__form__checkout">
          {clientSecret && (
            <>
              <Elements
                key={clientSecret}
                options={{
                  clientSecret,
                  appearance: {
                    theme: "stripe",
                  },
                }}
                stripe={stripePromise}
              >
                <CheckoutForm
                  paymentMethods={paymentMethods}
                  clientSecret={clientSecret}
                  deletePaymentMethod={deletePaymentMethod}
                  total={total}
                  isPaymentLoading={isLoading}
                  setCardFutureUsage={(active: boolean) =>
                    setCardFutureUsage(active)
                  }
                  cardFutureUsage={cardFutureUsage}
                  setShouldRenderStripeElements={(
                    shouldRenderStripeElements: boolean
                  ) =>
                    setShouldRenderStripeElements(shouldRenderStripeElements)
                  }
                  shouldRenderStripeElements={shouldRenderStripeElements}
                />
              </Elements>
            </>
          )}
        </div>
        <div className="orders-web-payment__form__recap-title">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={favoriteOrderId ? removeFavoriteOrder : addFavoriteOrder}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              <div>
                {favoriteOrderId
                  ? i18n.t("order.orderAddedToFavorite")
                  : i18n.t("order.defineFavoriteOrder")}
              </div>

              <div
                style={{
                  fontStyle: "italic",
                  fontSize: 14,
                  fontWeight: "normal",
                  marginTop: 8,
                }}
              >
                {i18n.t("order.order1ClickNextTime")}
              </div>
            </div>
            <div>
              {isLoading ? (
                <AppLoading />
              ) : (
                <IonIcon
                  icon={favoriteOrderId ? heart : heartOutline}
                  style={{ color: "#FF0000", width: 25, height: 25 }}
                ></IonIcon>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "1.5rem 0",
            padding: "1rem",
            borderRadius: 8,
            backgroundColor: "#E1E2E5",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IonIcon
              style={{ width: 20, height: 20, color: "#32364E" }}
              icon={lockClosed}
            ></IonIcon>
            <div style={{ fontSize: 12, margin: "0 0.3rem" }}>
              {i18n.t("order.guaranteed")}{" "}
              <strong>{i18n.t("order.safeAndSecure")}</strong>{" "}
              {i18n.t("order.checkout")}
            </div>
            <div
              style={{
                fontSize: 10,
                backgroundColor: "#32364E",
                padding: "0.5rem",
                borderRadius: 6,
                color: "#fff",
                fontWeight: "lighter",
              }}
            >
              powered by <strong style={{ fontSize: 12 }}>stripe</strong>
            </div>
          </div>
          <div
            style={{
              margin: "1rem 0.25rem",
              width: "100%",
              height: 1,
              backgroundColor: "#CED0D7",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IonImg className="payment_method_img" src={"/images/visa.png"} />
            <IonImg
              className="payment_method_img"
              src={"/images/mastercard.png"}
            />
            <IonImg
              className="payment_method_img"
              src={"/images/americanexpress.png"}
            />
            <IonImg
              className="payment_method_img"
              src={"/images/unionpay.png"}
            />
          </div>
        </div>
      </div>
    </div>
  ) : isLoading || !isInit ? (
    <div className="orders-web-payment-app-loading-container">
      <AppLoading />
    </div>
  ) : (
    <div className="orders-web-payment__failed-container">
      <div className="orders-web-payment__failed">
        {i18n.t("order.ErrorOccured")}
      </div>
      <div>
        <BaseButton
          outline
          onClick={() => {
            newOrderCtx.setLocalOrderAsCompleted();
          }}
        >
          {i18n.t("order.Home")}
        </BaseButton>
      </div>
    </div>
  );
};

export default OrdersWebPayment;
