import "./BaseTextInput.css";
import { IonIcon, IonRouterLink } from "@ionic/react";
import { useState } from "react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";

import { HintLink, InputTypes } from "../../../types";

interface ContainerProps {
  label?: string;
  type?: InputTypes;
  hintLink?: HintLink;
  error?: string;
  controller?: any;
  leftIcon?: any;
  rightText?: any;
  color?: string;
  errorText?: string;
}

const BaseTextInput: React.FC<ContainerProps> = (props) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <div className="base-text-input" data-testid="base-text-input">
      <label
        className="base-text-input__label"
        data-testid="base-text-input__label"
        style={{
          color: props.color ? props.color : undefined,
          zIndex: 10,
        }}
      >
        {props.label}
      </label>
      <input
        className="base-text-input__input"
        style={{
          paddingLeft: props.leftIcon ? "2.25rem" : "0.5rem",
          borderColor: props.color ? props.color : undefined,
          color: props.color ? props.color : undefined,
        }}
        type={passwordVisibility ? "text" : props.type || "text"}
        {...props.controller}
        data-testid="base-text-input__input"
      />
      {props.leftIcon && (
        <IonIcon
          className="base-text-input__left-ion-icon"
          icon={props.leftIcon}
          style={{
            color: props.color ? props.color : undefined,
          }}
        />
      )}
      {props.rightText && (
        <div
          style={{
            position: "absolute",
            fontSize: "1.5rem",
            color: "var(--ion-color-primary)",
            right: "1rem",
            top: "1.75rem",
          }}
        >
          {props.rightText}
        </div>
      )}
      {props.type == "password" && (
        <IonIcon
          className="base-text-input__ion-icon"
          data-testid="base-text-input__ion-icon"
          onClick={() => setPasswordVisibility(!passwordVisibility)}
          icon={passwordVisibility ? eyeOffOutline : eyeOutline}
        />
      )}

      <span className="base-text-input__error">{props.error}</span>
      {props.hintLink && (
        <IonRouterLink
          className="base-text-input__hint-link"
          routerLink={props.hintLink.href}
        >
          {props.hintLink.value}
        </IonRouterLink>
      )}
    </div>
  );
};

export default BaseTextInput;
