import { I18n } from "i18n-js";
import en from "./en.json";
import fr from "./fr.json";
import buildEnv from "../build.env.json";
const i18n = new I18n({
  en,
  fr,
});
i18n.enableFallback = true;

i18n.locale = buildEnv.REGION == "EU" ? "fr-FR" : "en-US";
i18n.defaultLocale = buildEnv.REGION == "EU" ? "fr-FR" : "en-US";

export default i18n;
