import { IonImg } from "@ionic/react";
import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import Product from "../../../models/Product";
import OrdersCartItem from "../OrdersCartItem/OrdersCartItem";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import OrderTrackingContext from "../../../contexts/orders-tracking.ctx";
import "./OrdersFullCart.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import AuthContext from "../../../contexts/auth.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import { IonIcon } from "@ionic/react";
import { heart } from "ionicons/icons";
interface ContainerProps {
  externalContext?: {
    cart: Product[];
    removeProductFromCart: Function;
    currentOrderStep: string;
    currentOrderStepChangeHandler: Function;
  };
  onItemSelect?: Function;
  onConfirm?: Function;
  onDismiss?: Function;
}

const OrdersFullCart: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const authCtx = useContext(AuthContext);
  const orderTrackingCtx = useContext(OrderTrackingContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const orderContext = props.externalContext
    ? props.externalContext
    : newOrderCtx;

  const getTotalPrice = () => {
    return (
      orderContext.cart.reduce((prev, product) => {
        return prev + product.price;
      }, 0) / 100
    );
  };
  const selectGridItemHandler = (product: Product, index: number) => {
    if (props.onItemSelect) {
      props.onItemSelect(product, index);
    }
  };
  const handleOrderNextStep = () => {
    // TODO implement remote stripe order
    if (props.onConfirm) {
      props.onConfirm();
    } else {
      return;
    }
  };

  return (
    <div className="orders-full-cart" data-testid="orders-full-cart">
      <div className="orders-full-cart__cart">
        <div className="orders-full-cart__products">
          {orderContext?.cart?.length ? (
            orderContext.cart.map((product, index) => {
              return (
                <OrdersCartItem
                  key={`full-cart-item-${index}-${product.id}`}
                  product={product}
                  actionName={i18n.t("cart.removeProduct")}
                  action={() => orderContext.removeProductFromCart(index)}
                  onClick={() => selectGridItemHandler(product, index)}
                  index={index}
                />
              );
            })
          ) : authCtx.restaurantFavoriteOrder &&
            authCtx.restaurantFavoriteOrder?.products ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              <BaseButton
                onClick={() => {
                  newOrderCtx.setCartHandler(
                    authCtx.restaurantFavoriteOrder!.products
                  );
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {i18n.t("cart.addFavoriteOrder")}
                  <IonIcon
                    icon={heart}
                    style={{ marginLeft: 4, height: 20, width: 20 }}
                  />
                </div>
              </BaseButton>
            </div>
          ) : null}
          <div className="orders-full-cart__products__spacer" />
        </div>
        <div className="orders-full-cart__total">
          <span>{i18n.t("cart.total")}</span>
          <span>
            {constants.CURRENCY === "€"
              ? getTotalPrice().toFixed(2) + ` ${constants.CURRENCY}`
              : `${constants.CURRENCY}` + getTotalPrice().toFixed(2)}
          </span>
        </div>
        {props.onItemSelect ? (
          <div
            onClick={() => handleOrderNextStep()}
            className="orders-full-cart__validation"
          >
            {i18n.t("cart.ConfirmOrder")}
          </div>
        ) : (
          <>
            <div
              onClick={() => {
                orderContext.currentOrderStepChangeHandler(
                  "PRODUCTS_SELECTION"
                );
                props.onDismiss?.();
              }}
              className="orders-full-cart__return"
            >
              {i18n.t("order.Modify")}
            </div>
            <div
              onClick={() => props.onDismiss?.()}
              className="orders-full-cart__validation"
            >
              {i18n.t("pages.Return")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersFullCart;
