export default class {
  public id;
  public name;
  public color;
  public position;

  constructor(id: string, name: string, color: string, position: number) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.position = position;
  }
}
