import { IonImg, IonTitle, IonIcon } from "@ionic/react";
import "./CardSelectionComponent.css";
import { card } from "ionicons/icons";
import courierImage from "../../../assets/images/courier.svg";
import restaurantImage from "../../../assets/images/restaurant.svg";
import { customerPaymentMethod } from "../../../../types";
import { add } from "ionicons/icons";
import i18n from "../../../../translations/i18n";
type ButtonRoles = "COURIER" | "RESTAURANT" | "DELIVERY" | "ON_PLACE";

interface ContainerProps {
  paymentMethod?: customerPaymentMethod;
  selected?: boolean;
  onClick: Function;
  onDelete?: (paymentMethodId?: string) => void;
  addCard?: boolean;
}

export const getClassNames = (selected?: boolean) => {
  const classes = ["card-selection-button"];
  if (!selected) {
    classes.push("card-selection-button--not-selected");
  } else if (selected) {
    classes.push("card-selection-button--selected");
  }
  return classes.join(" ");
};

const CardSelectionComponent: React.FC<ContainerProps> = (props) => {
  return (
    <div className="card-selection-container">
      <div
        className={getClassNames(props.selected)}
        style={props.addCard ? { backgroundColor: "#fff" } : undefined}
        onClick={() => props.onClick()}
      >
        <div className="card-selection-button__ion-img-container">
          <IonIcon
            className="card-selection-button__ion-img-image"
            icon={props.addCard ? add : card}
          />
          {props.addCard ? null : (
            <div className="card-selection-button__ion-img-card-type">
              {props.paymentMethod?.card_brand.toUpperCase()}
            </div>
          )}
        </div>

        {props.addCard ? (
          <div
            style={{
              width: "100%",
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}
          >
            {i18n.t("checkout.AddCard")}
          </div>
        ) : null}

        {props.addCard ? null : (
          <div className="card-selection-button__ion-img-card-last4">
            *****{props.paymentMethod?.last4}
          </div>
        )}

        {props.addCard ? null : (
          <div className="card-selection-button__ion-img-card-expiry">
            {props.paymentMethod?.exp_month} / {props.paymentMethod?.exp_year}
          </div>
        )}
      </div>
      {props.addCard ? null : (
        <div
          onClick={() =>
            props.onDelete ? props.onDelete(props.paymentMethod?.id) : null
          }
          style={{
            position: "absolute",
            cursor: "pointer",
            top: -5,
            right: -5,
            width: 12,
            height: 12,
            backgroundColor: "red",
            padding: "0.5rem",
            borderRadius: "50%",
            fontSize: 11,
            fontWeight: "lighter",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            filter: "unset",
            opacity: 1,
          }}
        >
          X
        </div>
      )}
    </div>
  );
};

export default CardSelectionComponent;
