import { IonSpinner } from "@ionic/react";
import Logo from "../Logo/Logo";
import "./AppLoading.css";

interface ContainerProps {}

const AppLoading: React.FC<ContainerProps> = (props) => {
  return (
    <div className="app-loading" data-testid="app-loading">
      <IonSpinner color="primary" />
    </div>
  );
};

export default AppLoading;
