import { IonContent, IonImg, IonPage, IonIcon, IonModal } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import { bicycle, fastFood, receipt, settings } from "ionicons/icons";
import NewOrderContext from "../../../contexts/new-order.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import OrderTrackingContext from "../../../contexts/orders-tracking.ctx";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import "./OrderTracking.css";
import OrderTrackingItem from "./OrderTrackingItem/OrderTrackingItem";
import { Order } from "../../../types";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  orderContext?: any;
  isOpen: boolean;
  toggleModal: Function;
  orderTrackingContext: any;
  // onConfirmAndDismiss: Function;
}

const OrderTracking: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const orderTrackingCtx = useContext(OrderTrackingContext);
  const newOrderCtx = useContext(NewOrderContext);

  return (
    <IonModal
      isOpen={props.isOpen}
      backdropDismiss={false}
      cssClass="fullscreen"
      //   onDidPresent={() => feedOptionsPresets()}
    >
      <IonContent>
        <div className="order-tracking__modal-container">
          <BaseModalHeader onDismiss={props.toggleModal}>
            {i18n.t("order.Tracking")}
          </BaseModalHeader>
          <div className="order-tracking__modal-order-container">
            {props.orderTrackingContext.mergedOrders?.map(
              (order: Order, index: number) => {
                if (
                  ((order.payment_status !== "WAITING" &&
                    order.payment_status !== "FAILED") ||
                    index === 0) &&
                  !order.client_done
                ) {
                  return (
                    <OrderTrackingItem
                      order={order}
                      restaurantDataCtx={restaurantDataCtx}
                      key={order.id}
                      dismiss={props.toggleModal}
                    />
                  );
                }
              }
            )}
            {/* {props.orderTrackingContext.pendingOrders.map((order: Order) => {
              return (
                <OrderTrackingItem
                  order={order}
                  restaurantDataCtx={restaurantDataCtx}
                  key={order.id}
                />
              );
            })} */}
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default OrderTracking;
