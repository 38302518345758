// TODO ON PROD
// FIXME ! NEED TO RESTRICT API KEY USAGE
// NOTE document about waypoints : https://developers.google.com/maps/documentation/directions/get-directions#specify-locations-in-the-waypoints-parameter.
import { useEffect, useState } from "react";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import { Geolocation } from "@ionic-native/geolocation";

import { GCS } from "../../../services";
import "./AddressFetcher.css";
import AppLoading from "../AppLoading/AppLoading";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  selectedPlace?: google.maps.places.AutocompletePrediction;
  onSelect: Function;
  value: any;
  isLoading: boolean;
  isLoadingSetter: Function;
  deviceGeoCoordinates: {
    lat: number;
    lng: number;
  };
}

const AddressFetcher: React.FC<ContainerProps> = (props) => {
  const [addressInput, setAddressInput] = useState(
    props.selectedPlace?.description || ""
  );
  const [firstUpdate, setFirstUpdate] = useState(true);
  const [placesList, setPlacesList] = useState([props.value]);
  const [showResultFrame, setShowResultFrame] = useState(false);

  useEffect(() => {
    if (
      placesList.find((placeList) => placeList.description == addressInput) ===
      undefined
    ) {
      props.isLoadingSetter(true);
      if (addressInput && addressInput.length) {
        setShowResultFrame(true);
      }

      if (!firstUpdate) {
        props.onSelect({} as google.maps.places.AutocompletePrediction);
      }

      setFirstUpdate(false);

      const debouncer = setTimeout(() => {
        if (addressInput.length) {
          GCS.getPlacesSuggestions(
            addressInput,
            props.deviceGeoCoordinates
          ).then((list: any) => {
            setPlacesList(list);
            props.isLoadingSetter(false);
          });
        } else {
          setPlacesList([props.value]);
          props.isLoadingSetter(false);
        }
      }, 3000);
      return () => {
        clearTimeout(debouncer); // Debounce amount
      };
    }
  }, [addressInput]);

  const getResultItemClasses = (placeId: string) => {
    const classes = ["address-fetcher__results-frame__result"];
    if (placeId == props.value.place_id) {
      classes.push("address-fetcher__results-frame__result--is-active");
    }
    return classes.join(" ");
  };

  return (
    <div className={"address-fetcher"} data-testid="address-fetcher">
      <BaseTextInput
        label={i18n.t("adressfetcher.SearchAdress")}
        controller={{
          value: addressInput,
          onChange: (event: any) => {
            setAddressInput(event.target.value);
          },
        }}
      />
      {showResultFrame ? (
        <div
          className={
            Object.keys(props.value).length !== 0
              ? "address-fetcher__results-frame"
              : "address-fetcher__results-frame address-fetcher--no-selection"
          }
        >
          {!placesList.length && !props.isLoading ? (
            <div className="address-fetcher__results-frame__no-results">
              {i18n.t("adressfetcher.Geolocation")}
            </div>
          ) : props.isLoading ? (
            <div className="address-fetcher__results-frame__loading">
              <AppLoading />
            </div>
          ) : (
            placesList.map(
              (place: google.maps.places.AutocompletePrediction) => {
                return (
                  <div
                    key={place.place_id}
                    onClick={() => {
                      props.onSelect(place);
                      setAddressInput(place.description);
                    }}
                    className={getResultItemClasses(place.place_id)}
                  >
                    {place.description}
                  </div>
                );
              }
            )
          )}
        </div>
      ) : null}

      {Object.keys(props.value).length !== 0 ? null : (
        <div className={"address-fetcher--no-selection__warning"}>
          {i18n.t("adressfetcher.ClickResult")}
        </div>
      )}
    </div>
  );
};

export default AddressFetcher;
