import React, { useEffect, useState, useContext } from "react";
import API from "../services";
import CustomerInfos from "../models/CustomerInfos";
import Product from "../models/Product";
import { Storage } from "@ionic/storage";
import RestaurantDataContext from "./restaurant-data.ctx";
import buildEnv from "../build.env.json";
import AuthContext from "./auth.ctx";
import orderServices from "../services/order-service";
import Order from "../models/Order";
import firebase from "firebase/app";

type OrderType = "DELIVERY" | "ON_PLACE";
type OrderPlanningType = "NOW" | "PLANNED";

const defaultRestaurantId =
  buildEnv.BUILD_ENV == "PROD"
    ? "9fce979a-02f0-4156-b33d-9f2c215bf8cf" //HappyB
    : "f8edcd4e-e195-4285-96ae-b65e3ccb89d3";

const OrderTrackingContext = React.createContext({
  isLoading: false,
  orders: [] as Order[],
  pendingOrders: [] as Order[],
  userFavoriteOrders: [] as Product[],
  mergedOrders: [] as Order[],
  isModalOpen: false,
  setOrdersHandler: (newOrders: Order[]) => {},
  setFavoriteOrdersHandler: (products: Product[]) => {},
  addFavoriteOrderHandler: (products: Product[]) => {},
  removeFavoriteOrderHandler: (id: string) => {},
  toggleModalOpenHandler: () => {},
  resetContext: () => {},
  setOrderClientAsDone: async (orderId: string) => {},
});

export const OrderTrackingContextProvider: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orders, setOrders] = useState([] as Order[]);
  const [pendingOrders, setPendingOrders] = useState([] as Order[]);
  const [mergedOrders, setMergedOrders] = useState([] as Order[]);
  const [userFavoriteOrders, setUserFavoriteOrders] = useState([] as Product[]);
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);

  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  const toggleModalOpenHandler = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setOrderClientAsDone = async (orderId: string) => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        await orderServices.setOrderClientAsDone(
          restaurantDataCtx.restaurantId,
          orderId
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    let mergedOrders = [...orders, ...pendingOrders] || [];

    // Tri du tableau fusionné par la clé 'date'
    mergedOrders.sort(
      (order, nextOrder) =>
        nextOrder.createdAt.getTime() - order.createdAt.getTime()
    );
    setMergedOrders(mergedOrders);
  }, [orders, pendingOrders]);
  useEffect(() => {
    // dismissListeners();

    const ordersListener: Function = orderServices.listenToOrders(
      restaurantDataCtx.restaurantId,
      authCtx.userId,
      (newOrders: Order[]) => {
        setOrders(newOrders);
      }
    );
    const pendingOrdersListener: Function = orderServices.listenToPendingOrders(
      restaurantDataCtx.restaurantId,
      authCtx.userId,
      (pendingOrders: Order[]) => {
        setPendingOrders(pendingOrders);
      }
    );
    const userFavoriteOrdersListener: Function =
      orderServices.listenToFavoriteOrders(
        restaurantDataCtx.restaurantId,
        authCtx.userId,
        (products: Product[]) => {
          setUserFavoriteOrders(products);
        }
      );
    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([
      ordersListener,
      userFavoriteOrdersListener,
      pendingOrdersListener,
    ]);
  }, [restaurantDataCtx.restaurantId, authCtx.userId]);

  const resetContext = () => {
    setIsLoading(false);
    setOrders([]);
    setUserFavoriteOrders([]);
  };

  const addFavoriteOrderHandler = (products: Product[]) => {
    if (!isLoading) {
      setIsLoading(true);
      orderServices
        .addFavoriteOrder(restaurantDataCtx.restaurantId, products)
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const removeFavoriteOrderHandler = (id: string) => {
    if (!isLoading) {
      setIsLoading(true);
      orderServices
        .removeFavoriteOrder(restaurantDataCtx.restaurantId, id)
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const setOrdersHandler = (newOrders: Order[]) => {
    setOrders(newOrders);
  };

  const setFavoriteOrdersHandler = (products: Product[]) => {
    setUserFavoriteOrders(products);
  };

  const dismissListeners = () => {
    rtListenersList.map((listener: Function) => listener());
    return true;
  };

  return (
    <OrderTrackingContext.Provider
      value={{
        isLoading,
        orders,
        pendingOrders,
        userFavoriteOrders,
        mergedOrders,
        isModalOpen,
        setOrdersHandler,
        setFavoriteOrdersHandler,
        addFavoriteOrderHandler,
        removeFavoriteOrderHandler,
        toggleModalOpenHandler,
        resetContext,
        setOrderClientAsDone,
      }}
    >
      {props.children}
    </OrderTrackingContext.Provider>
  );
};

export default OrderTrackingContext;
