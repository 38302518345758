export default class {
  public id;
  public phone;
  public firstname;
  public lastname;
  public addressFirstLine;
  public addressSecondLine;
  public addressZipcode;
  public addressCity;
  public addressLatitude;
  public addressLongitude;
  public addressPlaceId;

  constructor(
    id: string,
    phone: string,
    firstname: string,
    lastname: string,
    addressFirstLine: string,
    addressSecondLine: string,
    addressZipcode: string,
    addressCity: string,
    addressLatitude: number,
    addressLongitude: number,
    addressPlaceId: string
  ) {
    this.id = id;
    this.phone = phone;
    this.firstname = firstname;
    this.lastname = lastname;
    this.addressFirstLine = addressFirstLine;
    this.addressSecondLine = addressSecondLine;
    this.addressZipcode = addressZipcode;
    this.addressCity = addressCity;
    this.addressLatitude = addressLatitude;
    this.addressLongitude = addressLongitude;
    this.addressPlaceId = addressPlaceId;
  }
}
