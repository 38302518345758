import { IonIcon, IonImg } from "@ionic/react";
import { card, cash } from "ionicons/icons";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import buildEnv from "../../../build.env.json";
import NewOrderContext from "../../../contexts/new-order.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./OrdersPaymentTypeSelector.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrdersPaymentTypeSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const history = useHistory();
  const getSelectorClasses = (paymentType: "STRIPE" | "IRL") => {
    const base = ["orders-payment-type-selector__selector"];
    if (newOrderCtx.paymentType == paymentType) {
      base.push("orders-payment-type-selector__selector--active");
    }
    return base.join(" ");
  };

  useEffect(() => {
    newOrderCtx.paymentTypeSelectionHandler("STRIPE");
  }, []);

  const onConfirmHandler = () => {
    if (newOrderCtx.paymentType == "STRIPE") {
      newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
    } else {
      newOrderCtx.submitNewOrder();
      // history.push(`/${restaurantDataCtx.restaurantId}/confirm?redirect_status=succeeded&payment_intent=paymentintent`);
    }
  };
  const goToPreviousStep = () => {
    if (authCtx.authState == AuthStates.NOT_AUTH) {
      newOrderCtx.currentOrderStepChangeHandler("SIGN_IN");
    } else {
      if (newOrderCtx.orderType == "ON_PLACE") {
        if (newOrderCtx.orderPlanningType == "NOW") {
          newOrderCtx.currentOrderStepChangeHandler("IS_PLANNED_ORDER");
        } else {
          newOrderCtx.currentOrderStepChangeHandler("ORDER_TIME_SELECTION");
        }
      } else if (newOrderCtx.orderType == "DELIVERY") {
        newOrderCtx.currentOrderStepChangeHandler("CUSTOMER_ADDRESS_SELECTION");
      }
    }
  };

  return (
    <div
      className="orders-payment-type-selector"
      data-testid="orders-payment-type-selector"
    >
      <div className={"orders-payment-type-selector__selector-container"}>
        <div
          className={getSelectorClasses("STRIPE")}
          onClick={() => newOrderCtx.paymentTypeSelectionHandler("STRIPE")}
          data-testid="orders-payment-type-selector__selector"
        >
          <div className="orders-payment-type-selector__selector__icon">
            <IonIcon icon={card} />
          </div>
          <span className="orders-payment-type-selector__selector__label">
            {i18n.t("order.PaymentCB")}
          </span>
        </div>
        {restaurantDataCtx.restaurantInfos.enableDoorPayment ? (
          <>
            <div
              style={{
                textAlign: "center",
                color: "var(--ion-color-secondary)",
                fontStyle: "italic",
                fontWeight: "bold",
                fontSize: 30,
              }}
            >
              {i18n.t("order.or")}
            </div>
            <div
              className={getSelectorClasses("IRL")}
              onClick={() => newOrderCtx.paymentTypeSelectionHandler("IRL")}
              data-testid="orders-payment-type-selector__selector"
            >
              <div className="orders-payment-type-selector__selector__icon">
                <IonIcon icon={cash} />
              </div>
              <span className="orders-payment-type-selector__selector__label">
                {newOrderCtx.orderType == "DELIVERY"
                  ? i18n.t("order.CashDelivery")
                  : i18n.t("order.onSitePayment")}
              </span>
            </div>
          </>
        ) : null}
      </div>

      <div className="orders-payment-type-selector__button-container">
        <div className="">
          <BaseButton
            expand
            tight
            outline
            // disabled={isPreviousButtonDisabled()}
            onClick={() => {
              goToPreviousStep();
            }}
          >
            {i18n.t("order.Previous")}
          </BaseButton>
        </div>
        <div className="">
          <BaseButton
            onClick={() => {
              onConfirmHandler();
            }}
            tight
            expand
            isLoading={newOrderCtx.isOrderInProgress}
          >
            {newOrderCtx.isOrderInProgress
              ? i18n.t("order.Waiting")
              : newOrderCtx.paymentType == "STRIPE"
              ? i18n.t("order.Payment")
              : i18n.t("order.Validate")}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};

export default OrdersPaymentTypeSelector;
