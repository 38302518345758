import React from 'react'
import { IonContent, IonImg, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";


interface HomeProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const Home: React.FC<HomeProps> = (props) => {
  return (
    <div>Home</div>
  )
}

export default Home;