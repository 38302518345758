import buildEnvConfig from "../build.env.json";
import { constants } from "../utils/constants";
const emulators = {
  auth: {
    host: "localhost",
    port: 9099,
  },
  functions: {
    host: "localhost",
    port: 5001,
  },
  firestore: {
    host: "localhost",
    port: 8080,
  },
  storage: {
    host: "localhost",
    port: 9199,
  },
};

const devEuropeConfig = {
  envName: "DEV",
  functionsEntryPoint: `${constants.API_URL}/`,
  sdkCredentials: {
    apiKey: "AIzaSyCALH-sYxgMNOGCxYifVmNEz85O1-ycxtU",
    authDomain: "delizi-dev.firebaseapp.com",
    projectId: "delizi-dev",
    storageBucket: "delizi-dev.appspot.com",
    messagingSenderId: "801403912957",
    appId: "1:801403912957:web:97f4e26fff04db59f07494",
    measurementId: "G-M2QV2T7N9Y",
  },
  emulators: { ...emulators },
};
const prodEuropeConfig = {
  envName: "PROD",
  functionsEntryPoint: `${constants.API_URL}/`,
  sdkCredentials: {
    apiKey: "AIzaSyDDs9REV6gPkuXvmH9miAwYdOwk4ErU3nI",
    authDomain: "delizi-master.firebaseapp.com",
    projectId: "delizi-master",
    storageBucket: "delizi-master.appspot.com",
    messagingSenderId: "222028603092",
    appId: "1:222028603092:web:b4dbe37ecf6388a3356541",
    measurementId: "G-LTH4HDFZE5",
  },
  emulators: { ...emulators },
};
const devUSConfig = {
  envName: "DEV",
  functionsEntryPoint: `${constants.API_URL}/`,
  sdkCredentials: {
    apiKey: "AIzaSyAQPe5M-9uUK2LJj-_gAvwfwn6Dh4jpKI0",
    authDomain: "deliours-dev.firebaseapp.com",
    projectId: "deliours-dev",
    storageBucket: "deliours-dev.appspot.com",
    messagingSenderId: "703238000970",
    appId: "1:703238000970:web:937dbc258ad0fa390b31b3",
    measurementId: "G-S2JRR8R2GZ",
  },
  emulators: { ...emulators },
};
const prodUSConfig = {
  envName: "PROD",
  functionsEntryPoint: `${constants.API_URL}/`,
  sdkCredentials: {
    apiKey: "AIzaSyDVxNR17UfPiPbDqlpG3BuTbO_O185YGjY",
    authDomain: "deliours-master.firebaseapp.com",
    projectId: "deliours-master",
    storageBucket: "deliours-master.appspot.com",
    messagingSenderId: "298665711206",
    appId: "1:298665711206:web:6587066d68493d4db93fa7",
    measurementId: "G-Q48YX5ZPJ5",
  },
  emulators: { ...emulators },
};
const emuConfig = {
  envName: "EMU",
  functionsEntryPoint: "http://localhost:5001/delizi-dev/europe-west1/",
  sdkCredentials: devEuropeConfig.sdkCredentials,
  emulators: { ...emulators },
};

export default () => {
  if (buildEnvConfig.BUILD_ENV == "PROD") {
    {
      if (buildEnvConfig.REGION == "US") {
        return prodUSConfig;
      } else {
        return prodEuropeConfig;
      }
    }
  } else if (buildEnvConfig.BUILD_ENV == "DEV") {
    {
      if (buildEnvConfig.REGION == "US") {
        return devUSConfig;
      } else {
        return devEuropeConfig;
      }
    }
  } else {
    return emuConfig;
  }
};
