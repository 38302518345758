import { IonImg, IonTitle } from "@ionic/react";
import "./RoleButton.css";

import courierImage from "../../../assets/images/courier.svg";
import restaurantImage from "../../../assets/images/restaurant.svg";
import i18n from "../../../translations/i18n";

type ButtonRoles = "COURIER" | "RESTAURANT" | "DELIVERY" | "ON_PLACE";

interface ContainerProps {
  role: "COURIER" | "RESTAURANT" | "DELIVERY" | "ON_PLACE";
  selected: boolean;
  onClick: Function;
  disabled?: boolean;
}

export const getCorrespondingRoleImage = (role: ButtonRoles) => {
  return role == "COURIER" || role == "DELIVERY"
    ? courierImage
    : restaurantImage;
};

export const getCorrespondingRoleName = (role: ButtonRoles) => {
  switch (role) {
    case "COURIER":
      return i18n.t("order.DeliveryDriver");
    case "RESTAURANT":
      return i18n.t("order.Restaurant");
    case "DELIVERY":
      return i18n.t("order.Delivery");
    case "ON_PLACE":
      return i18n.t("order.Collect");
  }
};

export const getClassNames = (selected: boolean, role: ButtonRoles) => {
  const classes = ["role-button"];
  if (!selected) {
    classes.push("role-button--not-selected");
  }
  if (role == "DELIVERY" || role == "ON_PLACE") {
    classes.push("role-button--as-new-order-choice");
  }
  return classes.join(" ");
};

const RoleButton: React.FC<ContainerProps> = (props) => {
  return props.disabled ? null : (
    <div
      className={getClassNames(props.selected, props.role)}
      style={{
        opacity: props.disabled ? 0.5 : 1,
        cursor: props.disabled ? "not-allowed" : "pointer",
      }}
      onClick={props.disabled ? () => null : () => props.onClick(props.role)}
      data-testid="role-button"
    >
      <div className="role-button__ion-img">
        <IonImg
          data-testid="role-button__ion-img"
          src={getCorrespondingRoleImage(props.role)}
        />
      </div>
      <span className="role-button__label">
        {getCorrespondingRoleName(props.role)}
      </span>
    </div>
  );
};

export default RoleButton;
