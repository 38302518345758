import { IonIcon, IonImg } from "@ionic/react";
import { time, hourglass } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
// import TimePicker from "../TimeScrollerSelector/TimeScrollerSelector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./OrderPlannedTimeSelector.css";
import i18n from "../../../translations/i18n";

registerLocale("fr", fr);

interface ContainerProps {}

export const getIncludedHours = (todayWorkingHours: Array<any> | null) => {
  let includedHour: Array<Date> = [];
  if (todayWorkingHours) {
    todayWorkingHours.forEach((hourRange) => {
      if (hourRange.start > hourRange.end) {
        for (let i = hourRange.start * 3; i <= 144; i++) {
          const hour = Math.floor(i / 6);
          const tenth = i % 6;
          const nowDate = new Date();
          nowDate.setHours(hour, tenth * 10);
          const currentDate = new Date();
          currentDate.setMinutes(new Date().getMinutes() + 15);
          if (nowDate > currentDate) {
            includedHour.push(nowDate);
          }
        }
        for (let j = 0; j <= hourRange.end * 3; j++) {
          const hour = Math.floor(j / 6);
          const tenth = j % 6;
          const nowDate = new Date();
          nowDate.setHours(hour, tenth * 10);
          const currentDate = new Date();
          currentDate.setMinutes(new Date().getMinutes() + 15);
          if (nowDate > currentDate) {
            includedHour.push(nowDate);
          }
        }
      } else {
        for (let i = hourRange.start * 3; i <= hourRange.end * 3; i++) {
          const hour = Math.floor(i / 6);
          const tenth = i % 6;
          const nowDate = new Date();
          nowDate.setHours(hour, tenth * 10);
          const currentDate = new Date();
          currentDate.setMinutes(new Date().getMinutes() + 30);
          if (nowDate > currentDate) {
            includedHour.push(nowDate);
          }
        }
      }
    });
  }
  return includedHour;
};

export const isRestaurantOpen = (
  restaurantDataCtx: any,
  todayWorkingHours: Array<any> | null
) => {
  const nowDate = new Date();
  const nowHour = nowDate.getHours();
  const nowMinutes = nowDate.getMinutes();

  let restaurantOpen = false;
  if (todayWorkingHours) {
    for (const hourRange of todayWorkingHours) {
      if (hourRange.start > hourRange.end) {
        if (
          (hourRange.start <= nowHour * 2 + nowMinutes / 30 &&
            nowHour * 2 + nowMinutes / 30 <= 48) ||
          (nowHour * 2 + nowMinutes / 30 <= hourRange.end &&
            nowHour * 2 + nowMinutes / 30 >= 0)
        ) {
          restaurantOpen = true;
          break;
        }
      } else {
        if (
          hourRange.start <= nowHour * 2 + nowMinutes / 30 &&
          nowHour * 2 + nowMinutes / 30 <= hourRange.end
        ) {
          restaurantOpen = true;
          break;
        }
      }
      console.log(hourRange.start, hourRange.end, nowMinutes);
    }
  }

  if (restaurantOpen) {
    return true;
  }
  return false;
};

export const getTodayWorkingHours = (
  restaurantDataCtx: any,
  todayIndex: number
) => {
  const workingDays = restaurantDataCtx.workingDays;
  if (workingDays) {
    let todayWorkingHours = null;
    switch (todayIndex) {
      case 0:
        todayWorkingHours = workingDays.sun;
        break;
      case 1:
        todayWorkingHours = workingDays.mon;
        break;
      case 2:
        todayWorkingHours = workingDays.tue;
        break;
      case 3:
        todayWorkingHours = workingDays.wed;
        break;
      case 4:
        todayWorkingHours = workingDays.thu;
        break;
      case 5:
        todayWorkingHours = workingDays.fri;
        break;
      case 6:
        todayWorkingHours = workingDays.sat;
        break;
    }
    return todayWorkingHours;
  } else {
    return null;
  }
};

const OrderPlannedTimeSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);

  const [includedHour, setIncludedHour] = useState<Array<Date>>([]);
  const [todayWorkingHours, setTodayWorkingHours] = useState({});
  const [minDate, setMinDate] = useState<Date>(new Date());
  const tmpMaxDate = new Date();
  tmpMaxDate.setHours(23, 59, 59);
  const [maxDate, setMaxDate] = useState<Date>(tmpMaxDate);

  const goToPreviousStep = () => {
    newOrderCtx.currentOrderStepChangeHandler("IS_PLANNED_ORDER");
  };

  const onConfirmHandler = () => {
    // newOrderCtx.submitNewOrder();
    if (newOrderCtx.orderType == "ON_PLACE") {
      if (authCtx.authState === AuthStates.NOT_AUTH) {
        newOrderCtx.currentOrderStepChangeHandler("SIGN_IN");
      } else if (authCtx.authState === AuthStates.AUTH) {
        if (restaurantDataCtx.restaurantInfos.enableDoorPayment) {
          newOrderCtx.currentOrderStepChangeHandler("PAYMENT_TYPE_SELECTION");
        } else {
          newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
        }
      }
    } else {
      if (authCtx.authState === AuthStates.NOT_AUTH) {
        newOrderCtx.currentOrderStepChangeHandler("SIGN_IN");
      } else if (authCtx.authState === AuthStates.AUTH) {
        newOrderCtx.currentOrderStepChangeHandler("CUSTOMER_ADDRESS_SELECTION");
      }
    }
  };

  useEffect(() => {
    const todayIndex: number = minDate.getDay();
    setTodayWorkingHours(getTodayWorkingHours(restaurantDataCtx, todayIndex));

    //Only include opening hours
    setIncludedHour(
      getIncludedHours(getTodayWorkingHours(restaurantDataCtx, todayIndex))
    );
    if (
      getIncludedHours(getTodayWorkingHours(restaurantDataCtx, todayIndex))
        .length
    ) {
      newOrderCtx.orderPlanningTimeSelectionHandler(
        getIncludedHours(getTodayWorkingHours(restaurantDataCtx, todayIndex))[0]
      );
    }
  }, []);

  if (includedHour.length == 0 && todayWorkingHours) {
    return (
      <div className="orders-planned-order-time-selector--not-available">
        <span className="orders-planned-order-time-selector-text">
          {i18n.t("order.SorryNoTimeSlot")}
        </span>
        <BaseButton
          expand
          tight
          outline
          // disabled={isPreviousButtonDisabled()}
          onClick={() => {
            goToPreviousStep();
          }}
        >
          {i18n.t("order.Previous")}
        </BaseButton>
      </div>
    );
  }

  return (
    <div className="orders-planned-order-time-selector__container">
      <div className="orders-planned-order-time-selector">
        <span
          className="orders-planned-order-time-selector-text"
          data-testid="orders-planned-order-time-selector-text"
        >
          {i18n.t("order.SelectHour")} :{" "}
        </span>
        {/* <TimePicker
      /> */}
        <DatePicker
          locale="fr"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={10}
          timeCaption="Heure"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          selected={newOrderCtx.orderPlannedTime}
          onChange={(date: Date) =>
            newOrderCtx.orderPlanningTimeSelectionHandler(date)
          }
          minDate={minDate}
          maxDate={maxDate}
          minTime={minDate}
          maxTime={maxDate}
          includeTimes={includedHour.length == 0 ? [] : includedHour}
          dropdownMode={"scroll"}
          calendarClassName="orders-planned-order-time-datepicker-calendar"
          className="orders-planned-order-time-datepicker"
          timeClassName={() => "orders-planned-order-time-datepicker-time"}
          wrapperClassName="orders-planned-order-time-datepicker-wrapper"
          popperPlacement="bottom"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="orders-planned-order-time-selector__button-container">
        <div className="">
          <BaseButton
            expand
            tight
            outline
            // disabled={isPreviousButtonDisabled()}
            onClick={() => {
              goToPreviousStep();
            }}
          >
            {i18n.t("order.Previous")}
          </BaseButton>
        </div>
        <div className="">
          <BaseButton
            onClick={() => {
              onConfirmHandler();
            }}
            tight
            expand
            isLoading={newOrderCtx.isOrderInProgress}
          >
            {newOrderCtx.isOrderInProgress
              ? i18n.t("order.Waiting")
              : i18n.t("order.Next")}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};

export default OrderPlannedTimeSelector;
