import { useContext, useEffect, useState } from "react";
import ProductsPageContext from "../../../contexts/products-page.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import Product from "../../../models/Product";
import ProductCategory from "../../../models/ProductCategory";
import ProductsGridItem from "../ProductsGridItem/ProductsGridItem";
import ProductsGridNoStateItem from "../ProductsGridNoStateItem/ProductsGridNoStateItem";
import "./ProductsGrid.css";

interface ContainerProps {
  onItemSelect: Function;
  isInOrderMode?: boolean;
}

const ProductsGrid: React.FC<ContainerProps> = (props) => {
  const productsPageCtx = useContext(ProductsPageContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);

  const selectGridItemHandler = (product: Product) => {
    props.onItemSelect(product);
  };

  const getClasses = () => {
    const base = ["products-grid"];
    if (props.isInOrderMode) {
      base.push("products-grid--order-mode");
    }
    return base.join(" ");
  };

  const getFlexClasses = () => {
    const base = ["products-category"];
    if (props.isInOrderMode) {
      base.push("products-category--order-mode");
    }
    return base.join(" ");
  };

  const getGridClasses = () => {
    const base = ["products-grid__grid"];
    if (props.isInOrderMode) {
      base.push("products-grid__grid--order-mode");
    }
    return base.join(" ");
  };

  return (
    <div className={getClasses()} data-testid="products-grid">
      {/* <span>Produits</span> */}
      <div className={getFlexClasses()}>
        {productsPageCtx.currentProductsCategoryId == "" ? (
          restaurantDataCtx.productCategoriesList.length ? (
            restaurantDataCtx.productCategoriesList.map(
              (productCategory: ProductCategory) => {
                if (productCategory.name != "...") {
                  return (
                    <div key={productCategory.id}>
                      <div>
                        <span>{productCategory.name}</span>
                      </div>
                      <div className={getGridClasses()}>
                        {productsPageCtx.currentProductsList.filter(
                          (product: Product) =>
                            product.categoryId == productCategory.id
                        ).length ? (
                          productsPageCtx.currentProductsList
                            .filter(
                              (product: Product) =>
                                product.categoryId == productCategory.id
                            )
                            .map((product: Product, index) => {
                              return (
                                <ProductsGridItem
                                  key={product.id}
                                  name={product.name}
                                  price={product.price}
                                  basePrice={product.basePrice}
                                  hasOptions={product.hasOptions}
                                  pictureUrl={product.thumbnailUrl}
                                  description={product.description}
                                  unavailable={product.unavailable}
                                  onClick={() => selectGridItemHandler(product)}
                                  longDesc={product.longDesc}
                                />
                              );
                            })
                        ) : (
                          <ProductsGridNoStateItem />
                        )}
                      </div>
                    </div>
                  );
                }
              }
            )
          ) : (
            <ProductsGridNoStateItem />
          )
        ) : productsPageCtx.currentProductsName != "..." ? (
          <div className={""}>
            <div className={""}>
              <span>{productsPageCtx.currentProductsName}</span>
            </div>
            <div className={getGridClasses()}>
              {productsPageCtx.currentProductsList.filter(
                (product: Product) =>
                  product.categoryId ==
                  productsPageCtx.currentProductsCategoryId
              ).length ? (
                productsPageCtx.currentProductsList
                  .filter(
                    (product: Product) =>
                      product.categoryId ==
                      productsPageCtx.currentProductsCategoryId
                  )
                  .map((product: Product, index) => {
                    return (
                      <ProductsGridItem
                        key={product.id}
                        name={product.name}
                        price={product.price}
                        basePrice={product.basePrice}
                        hasOptions={product.hasOptions}
                        pictureUrl={product.thumbnailUrl}
                        description={product.description}
                        unavailable={product.unavailable}
                        onClick={() => selectGridItemHandler(product)}
                        longDesc={product.longDesc}
                      />
                    );
                  })
              ) : (
                <ProductsGridNoStateItem />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductsGrid;
