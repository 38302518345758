import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import buildEnv from "../build.env.json";
import axios from "axios";
import Product from "../models/Product";
import ProductCategory from "../models/ProductCategory";
import ProductsOptions from "../models/ProductsOptions";
import RestaurantInfos from "../models/RestaurantInfos";
import WorkingDays from "../models/WorkingDays";
import { $firebaseAuth, $firestore } from "./firebase-service";
import Order from "../models/Order";
import CustomerInfos from "../models/CustomerInfos";
import Courier from "../models/Courier";
import { constants } from "../utils/constants";
import { customerPaymentMethod } from "../types";

export const happyBurgerRestaurantId = "9fce979a-02f0-4156-b33d-9f2c215bf8cf";

const apiEndpoints = {
  addFavorite: {
    dev: `${constants.API_URL}/customerApi/addFavorite`,
    prod: `${constants.API_URL}/customerApi/addFavorite`,
  },
  removeFavorite: {
    dev: `${constants.API_URL}/customerApi/removeFavorite`,
    prod: `${constants.API_URL}/customerApi/removeFavorite`,
  },
  deletePaymentMethod: {
    dev: `${constants.API_URL}/customerApi/deletePaymentMethodDev`,
    prod: `${constants.API_URL}/customerApi/deletePaymentMethod`,
  },
  setOrderClientAsDone: {
    dev: `${constants.API_URL}/customerApi/setOrderClientAsDone`,
    prod: `${constants.API_URL}/customerApi/setOrderClientAsDone`,
  },
};

const defaultRestaurantId =
  buildEnv.BUILD_ENV == "PROD"
    ? "9fce979a-02f0-4156-b33d-9f2c215bf8cf" //Happy
    : "f8edcd4e-e195-4285-96ae-b65e3ccb89d3";

const orderServices = {
  async addFavoriteOrder(
    restaurantId: string,
    products: any[]
  ): Promise<string> {
    return new Promise((res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.addFavorite.dev
          : apiEndpoints.addFavorite.prod;

      $firebaseAuth.currentUser
        ?.getIdToken()
        .then((idToken) => {
          const headers = {
            Authorization: `Bearer ${idToken}`,
          };
          return axios.post(
            apiEndpoint,
            {
              restaurantId: restaurantId ? restaurantId : defaultRestaurantId,
              cart: products,
            },
            {
              headers: headers,
            }
          );
        })
        .then((response: Record<any, any>) => {
          res(response.data.favoriteOrderId);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  async removeFavoriteOrder(
    restaurantId: string,
    favortieOrderId: string
  ): Promise<void> {
    return new Promise((res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.removeFavorite.dev
          : apiEndpoints.removeFavorite.prod;

      $firebaseAuth.currentUser
        ?.getIdToken()
        .then((idToken) => {
          const headers = {
            Authorization: `Bearer ${idToken}`,
          };
          return axios.post(
            apiEndpoint,
            {
              restaurantId: restaurantId ? restaurantId : defaultRestaurantId,
              favortieOrderId: favortieOrderId,
            },
            {
              headers: headers,
            }
          );
        })
        .then((response: Record<any, any>) => {
          res();
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  async deletePaymentMethod(
    paymentMethodId: string
  ): Promise<customerPaymentMethod[]> {
    return new Promise((res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV === "DEV"
          ? apiEndpoints.deletePaymentMethod.dev
          : apiEndpoints.deletePaymentMethod.prod;

      $firebaseAuth.currentUser
        ?.getIdToken()
        .then((idToken) => {
          const headers = {
            Authorization: `Bearer ${idToken}`,
          };
          return axios.post(
            apiEndpoint,
            {
              paymentMethodId,
            },
            {
              headers: headers,
            }
          );
        })
        .then((response: Record<any, any>) => {
          res(response.data.customerPaymentMethods);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  async setOrderClientAsDone(
    restaurantId: string,
    orderId: string
  ): Promise<void> {
    return new Promise((res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.setOrderClientAsDone.dev
          : apiEndpoints.setOrderClientAsDone.prod;

      $firebaseAuth.currentUser
        ?.getIdToken()
        .then((idToken) => {
          const headers = {
            Authorization: `Bearer ${idToken}`,
          };
          return axios.post(
            apiEndpoint,
            {
              restaurantId: restaurantId ? restaurantId : defaultRestaurantId,
              orderId: orderId,
            },
            {
              headers: headers,
            }
          );
        })
        .then((response: Record<any, any>) => {
          res();
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  listenToOrders(
    restaurantId: string,
    userId: string,
    listenerCallback: Function
  ) {
    const ordersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("orders")
      .where("userId", "==", userId)
      .orderBy("created_at", "desc")
      .onSnapshot((querySnasphot) => {
        let orders: Order[] = [];

        querySnasphot.forEach((doc) => {
          if (
            doc.data().status == "RECORDED" ||
            doc.data().status == "READY" ||
            doc.data().status == "OUT" ||
            doc.data().status == "DELIVERED" ||
            doc.data().status == "NOT_IN_PRODUCTION"
          ) {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().payment_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().estimated_time?.toDate(),
                doc.data().client_done
              )
            );
          }
        });
        listenerCallback(orders);
      });
    return ordersListener;
  },
  listenToPendingOrders(
    restaurantId: string,
    userId: string,
    listenerCallback: Function
  ) {
    const ordersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("pending_web_orders")
      .where("userId", "==", userId)
      .where("payment_status", "in", ["FAILED", "WAITING"])
      .orderBy("created_at", "desc")
      .onSnapshot((querySnasphot) => {
        let orders: Order[] = [];

        querySnasphot.forEach((doc) => {
          if (
            doc.data().status == "RECORDED" ||
            doc.data().status == "READY" ||
            doc.data().status == "OUT" ||
            doc.data().status == "DELIVERED" ||
            doc.data().status == "NOT_IN_PRODUCTION"
          ) {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().payment_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().estimated_time?.toDate(),
                doc.data().client_done
              )
            );
          }
        });
        listenerCallback(orders);
      });
    return ordersListener;
  },
  listenToFavoriteOrders(
    restaurantId: string,
    userId: string,
    listenerCallback: Function
  ) {
    const favoriteOrdersListener = $firestore
      .collection("favorite_orders")
      .where("userId", "==", userId)
      .where("restaurantId", "==", restaurantId)
      .orderBy("created_at", "desc")
      .onSnapshot((querySnasphot) => {
        let products;

        querySnasphot.forEach((doc) => {
          products = doc.data().products.map((product: Record<any, any>) => {
            return new Product(
              product.id,
              product.name,
              product.price,
              product.basePrice || product.price,
              product.category_id,
              product.has_options,
              product.picture_url,
              product.thumbnail_url,
              product.quantity,
              product.description,
              product.products_options_ids,
              product.options,
              product.unavailable,
              product.long_desc
            );
          }) as Product[];
        });
        listenerCallback(products);
      });
    return favoriteOrdersListener;
  },
};
export default orderServices;
