import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import OrderTrackingContext from "../../../contexts/orders-tracking.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./OrdersConfirmation.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrdersConfirmation: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const orderTrackingCtx = useContext(OrderTrackingContext);
  return (
    <div className="orders-confirmation" data-testid="orders-confirmation">
      <div className="orders-confirmation__icon">
        <IonIcon icon={checkmarkCircleOutline} />
        <span className="orders-confirmation__icon__text">
          {i18n.t("order.OrderSaved")}
        </span>
      </div>
      <span className="orders-confirmation__number">
        {i18n.t("order.Phone")} {newOrderCtx.orderNumber}
      </span>
      <div className="orders-confirmation__button-container">
        <BaseButton
          onClick={() => {
            orderTrackingCtx.toggleModalOpenHandler();
          }}
        >
          {i18n.t("order.Tracking")}
        </BaseButton>
        <BaseButton
          outline
          onClick={() => {
            newOrderCtx.setLocalOrderAsCompleted();
          }}
        >
          {i18n.t("order.Home")}
        </BaseButton>
      </div>
    </div>
  );
};

export default OrdersConfirmation;
