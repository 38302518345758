import "./ProductsCategoryChip.css";

interface ContainerProps {
  name?: string;
  color?: string;
  isMuted?: boolean;
  action: Function;
  margin?: string;
  alignStart?: boolean;
}

const ProductsCategoryChip: React.FC<ContainerProps> = (props) => {
  const classes = () => {
    const base = ["category-chip"];
    if (props.isMuted) {
      base.push("category-chip--muted");
    }
    return base.join(" ");
  };

  return (
    <div
      onClick={() => props.action()}
      className={classes()}
      style={{
        justifyContent: props.alignStart ? "flex-start" : "center",
        // margin: props.margin,
      }}
      data-testid="category-chip"
    >
      {props.name}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: 2,
          backgroundColor: "#fff",
          display: props.isMuted ? "none" : "block",
          marginRight: 0,
        }}
      ></div>
    </div>
  );
};

export default ProductsCategoryChip;
