import { useContext, useState } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import Product from "../../../models/Product";
import ProductsOptions, { OptionItem } from "../../../models/ProductsOptions";
import OrderProductOptionsModal from "../../Modals/OrderProductOptionsModal/OrderProductOptionsModal";
import ProductsCategorySelector from "../../Products/ProductsCategorySelector/ProductsCategorySelector";
import ProductsGrid from "../../Products/ProductsGrid/ProductsGrid";
import OrdersFullCart from "../OrdersFullCart/OrdersFullCart";
import OrdersMiniCart from "../OrdersMiniCart/OrdersMiniCart";
import "./OrdersProductsSelector.css";

interface ContainerProps {}

const OrdersProductsSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const [optionsModalIsOn, setOptionsModalIsOn] = useState(false);
  const [optionsChangeModalIsOn, setOptionsChangeModalIsOn] = useState(false);
  const [pendingProduct, setPendingProduct] = useState({} as Product);
  const [modifPendingProduct, setModifPendingProduct] = useState({} as Product);
  const [modifCurrentIndex, setModifCurrentIndex] = useState(-1);

  const productSelectionHandler = (
    product: Product,
    quantity: number,
    options?: OptionItem[]
  ) => {
    if (options) {
      const priceWithOptions = options
        .map((option) => option.price)
        .reduce((prev, curr) => {
          return prev + curr;
        }, product.basePrice);
      const productWithOptions = new Product(
        product.id,
        product.name,
        priceWithOptions * quantity,
        product.basePrice,
        product.categoryId,
        product.hasOptions,
        product.pictureUrl,
        product.thumbnailUrl,
        quantity,
        product.description,
        product.productsOptionsIds,
        options
      );
      if (quantity > 0) {
        newOrderCtx.addProductToCart(productWithOptions);
      }
      setPendingProduct({} as Product);
      setOptionsModalIsOn(false);
    } else {
      if (!product.hasOptions) {
        const productWithOptions = new Product(
          product.id,
          product.name,
          product.basePrice * quantity,
          product.basePrice,
          product.categoryId,
          product.hasOptions,
          product.pictureUrl,
          product.thumbnailUrl,
          quantity,
          product.description,
          product.productsOptionsIds
        );
        if (quantity > 0) {
          newOrderCtx.addProductToCart(productWithOptions);
        }
      } else if (product.hasOptions) {
        setPendingProduct(product);
        setOptionsModalIsOn(true);
      }
    }
  };

  const productModifHandler = (
    product: Product,
    index: number,
    quantity: number,
    options?: OptionItem[]
  ) => {
    if (modifCurrentIndex !== index) {
      setModifCurrentIndex(index);
    }
    if (product.hasOptions && options) {
      const priceWithOptions = options
        .map((option) => option.price)
        .reduce((prev, curr) => {
          return prev + curr;
        }, product.basePrice);
      const productWithOptions = new Product(
        product.id,
        product.name,
        priceWithOptions * quantity,
        product.basePrice,
        product.categoryId,
        product.hasOptions,
        product.pictureUrl,
        product.thumbnailUrl,
        quantity,
        product.description,
        product.productsOptionsIds,
        options
      );
      if (quantity > 0) {
        newOrderCtx.modifProductCart(productWithOptions, modifCurrentIndex);
      } else {
        newOrderCtx.removeProductFromCart(index);
      }

      setModifPendingProduct({} as Product);
      setOptionsChangeModalIsOn(false);
    } else {
      if (product.hasOptions) {
        setModifPendingProduct(product);
        setOptionsChangeModalIsOn(true);
      }
    }
  };

  const onConfirmHandler = () => {
    newOrderCtx.currentOrderStepChangeHandler("IS_A_DELIVERY_SELECTION");
  };

  // const onConfirmHandler = () => {
  //   if (newOrderCtx.orderType === "DELIVERY") {
  //     newOrderCtx.currentOrderStepChangeHandler("CUSTOMER_ADDRESS_SELECTION");
  //   } else if (newOrderCtx.orderType === "ON_PLACE") {
  //     newOrderCtx.currentOrderStepChangeHandler("PAYMENT_TYPE_SELECTION");
  //   }
  // };

  return (
    <>
      <OrderProductOptionsModal
        isOpen={optionsModalIsOn}
        onDismiss={() => setOptionsModalIsOn(false)}
        product={pendingProduct}
        onOptionSubmission={(
          product: Product,
          quantity: number,
          options: OptionItem[]
        ) => productSelectionHandler(product, quantity, options)}
      />
      <OrderProductOptionsModal
        isOpen={optionsChangeModalIsOn}
        onDismiss={() => setOptionsChangeModalIsOn(false)}
        product={modifPendingProduct}
        onOptionSubmission={(
          product: Product,
          quantity: number,
          options: OptionItem[]
        ) => productModifHandler(product, modifCurrentIndex, quantity, options)}
        isModif={true}
      />
      <div
        className="orders-products-selector"
        data-testid="orders-products-selector"
      >
        <div className="orders-products-selector__products">
          {/* <ProductsCategoryNavBar isInOrderMode /> */}
          <ProductsCategorySelector isInOrderMode />
          <ProductsGrid
            onItemSelect={(product: Product) =>
              productSelectionHandler(product, 1)
            }
            isInOrderMode
          />
        </div>

        <div className="orders-products-selector__full-cart-container">
          <OrdersFullCart
            onConfirm={() => onConfirmHandler()}
            onItemSelect={(product: Product, index: number, quantity: number) =>
              productModifHandler(product, index, product.quantity, undefined)
            }
          />
        </div>
        <div className="orders-products-selector__mini-cart-container">
          <OrdersMiniCart
            onItemSelect={(product: Product, index: number, quantity: number) =>
              productModifHandler(product, index, product.quantity, undefined)
            }
          />
        </div>
      </div>
    </>
  );
};

export default OrdersProductsSelector;
