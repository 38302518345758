import { RestaurantDataContextProvider } from "../restaurant-data.ctx";
import { ProductsPageContextProvider } from "../products-page.ctx";
import { NewOrderContextProvider } from "../new-order.ctx";
import { NewCustomerContextProvider } from "../new-customer.ctx";
import { AuthContextProvider } from "../auth.ctx";
import { OrderTrackingContextProvider } from "../orders-tracking.ctx";

const RootProvider: React.FC = (props) => {
  return (
    <AuthContextProvider>
      <RestaurantDataContextProvider>
        <ProductsPageContextProvider>
          <NewCustomerContextProvider>
            <NewOrderContextProvider>
              <OrderTrackingContextProvider>{props.children}</OrderTrackingContextProvider>
            </NewOrderContextProvider>
          </NewCustomerContextProvider>
        </ProductsPageContextProvider>
      </RestaurantDataContextProvider>
    </AuthContextProvider>
  );
};

export default RootProvider;
