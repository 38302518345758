import { OrdersStatus, PaymentType } from "../types";
import Courier from "./Courier";
import CustomerInfos from "./CustomerInfos";
import Product from "./Product";

export default class {
  public id;
  public number;
  public paymentType;
  public type;
  public planningType;
  public plannedTime;
  public shouldStartOrder;
  public status;
  public specialStatus;
  public createdAt;
  public products;
  public customer;
  public courier;
  public optimizedPosition;
  public payment_status;
  public delivery_fees;
  public total_price;
  public estimated_time;
  public client_done;
  constructor(
    id: string,
    number: string,
    paymentType: PaymentType,
    type: "DELIVERY" | "ON_PLACE",
    planningType: "NOW" | "PLANNED",
    plannedTime: Date,
    shouldStartOrder: boolean,
    status: OrdersStatus,
    createdAt: Date,
    products: Product[],
    customer?: CustomerInfos,
    courier?: Courier,
    optimizedPosition?: number,
    specialStatus?: "LOCAL_UNPAID" | "WEB" | "WEB_PAID",
    payment_status?: "WAITING" | "PAID" | "FAILED",
    delivery_fees?: number | null,
    total_price?: number | null,
    estimated_time?: Date,
    client_done?: boolean
  ) {
    this.id = id;
    this.number = number;
    this.paymentType = paymentType;
    this.type = type;
    this.planningType = planningType;
    this.plannedTime = plannedTime;
    this.shouldStartOrder = shouldStartOrder;
    this.status = status;
    this.createdAt = createdAt;
    this.products = products;
    this.customer = customer;
    this.courier = courier;
    this.optimizedPosition = optimizedPosition;
    this.specialStatus = specialStatus;
    this.payment_status = payment_status;
    this.delivery_fees = delivery_fees;
    this.total_price = total_price;
    this.estimated_time = estimated_time;
    this.client_done = client_done;
  }
}
