export default class Courier {
  public id;
  public firstname;
  public lastname;
  public isPending;
  public profilePictureUrl;
  public profileThumbnailUrl;
  public phone;

  constructor(
    id: string,
    firstname: string,
    lastname: string,
    isPending: boolean,
    profilePictureUrl: string,
    profileThumbnailUrl: string,
    phone: string
  ) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.isPending = isPending;
    this.profilePictureUrl = profilePictureUrl;
    this.profileThumbnailUrl = profileThumbnailUrl;
    this.phone = phone;
  }
}
