import { useContext } from "react";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import OrderTrackingContext from "../../../contexts/orders-tracking.ctx";
import { constants } from "../../../utils/constants";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import "./RestaurantInfoModal.css";
import i18n from "../../../translations/i18n";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import { IonIcon } from "@ionic/react";
import { bicycleOutline, locationOutline } from "ionicons/icons";
import AddressFetcher from "../../@Shared/AddressFetcher/AddressFetcher";
import { useState, useEffect } from "react";
import { useIonAlert } from "@ionic/react";
import { Geolocation } from "@ionic-native/geolocation";
import { normalize } from "path";
import { customToFixed } from "../../../utils/customTofixed";
interface ContainerProps {
  onDismiss: Function;
  restaurantDataCtx: any;
  newCustomerCtx: any;
}

const RestaurantInfoModal: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = props.restaurantDataCtx;
  const newCustomerCtx = props.newCustomerCtx;
  const round2Decimal = (number?: number) => {
    return number ? Math.round(number * 100) / 10000 : 0;
  };
  const deliveryFees = round2Decimal(
    restaurantDataCtx.restaurantInfos?.deliveryFees
  );

  const freeDeliveryFeesTrigger = round2Decimal(
    restaurantDataCtx.restaurantInfos?.freeDeliveryFeesTrigger
  );

  const deliveryFeesByKm = round2Decimal(
    restaurantDataCtx.restaurantInfos?.deliveryFeesByKm
  );
  const minimumOrderAmount = round2Decimal(
    restaurantDataCtx.restaurantInfos.minimumOrderAmount
  );

  const [deviceGeoCoordinates, setDeviceGeoCoordinates] = useState({
    lat: 46, // Center of france
    lng: 2, // Center of france
  });

  const [isLoading, setIsLoading] = useState(false);
  const [finalDeliveryFees, setFinalDeliveryFees] = useState(
    restaurantDataCtx.restaurantInfos?.deliveryFees || 0
  );
  const [deviceGeoCoordinatesIsInit, setDeviceGeoCoordinatesIsInit] =
    useState(false);

  const [present, dismiss] = useIonAlert();
  useEffect(() => {
    if (!deviceGeoCoordinatesIsInit) {
      Geolocation.getCurrentPosition()
        .then((resp) => {
          setDeviceGeoCoordinates({
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          });
          setDeviceGeoCoordinatesIsInit(true);
        })
        .catch((e) => {
          console.log(e);
          setDeviceGeoCoordinatesIsInit(true);
        });
    }
  }, []);

  const checkDeliveryFees = async () => {
    let deliveryFees = 0;
    const checkCustomerAddressData =
      await newCustomerCtx.checkAndSetCustomerAddress();
    if (
      checkCustomerAddressData &&
      checkCustomerAddressData.customer &&
      checkCustomerAddressData.distanceToRestaurant !== undefined
    ) {
      if (restaurantDataCtx.restaurantInfos?.deliveryFees) {
        deliveryFees =
          deliveryFees + restaurantDataCtx.restaurantInfos?.deliveryFees;
      }
      if (restaurantDataCtx.restaurantInfos?.deliveryFeesByKm) {
        deliveryFees =
          checkCustomerAddressData.distanceToRestaurant *
            restaurantDataCtx.restaurantInfos?.deliveryFeesByKm +
          deliveryFees;
      }
      setFinalDeliveryFees(deliveryFees);
      present({
        header: i18n.t("header.deliveryAvailable"),
        message:
          constants.CURRENCY === "€"
            ? `${i18n.t("header.DeliveryCharge")} ${customToFixed(
                round2Decimal(deliveryFees)
              )} ${constants.CURRENCY}`
            : `${i18n.t("header.DeliveryCharge")}  ${
                constants.CURRENCY
              }${customToFixed(round2Decimal(deliveryFees))}`,
        buttons: [
          {
            text: i18n.t("header.continue"),
            handler: (d) => {
              props.onDismiss();
            },
          },
        ],
        onDidDismiss: (e) => {},
      });
    }
  };

  return (
    <div className="restaurant-info____modal-container">
      <div>
        <BaseModalHeader onDismiss={props.onDismiss}>
          {i18n.t("header.restaurantInformations")}
        </BaseModalHeader>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "var(--ion-color-secondary)",
            margin: "1rem",
          }}
        >
          {i18n.t("header.deliveryServiceTime")} :
        </div>
        <div
          style={{
            margin: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0.5rem 0",
            }}
          >
            <IonIcon icon={bicycleOutline}></IonIcon>
            <div style={{ marginLeft: "0.5rem" }}>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "var(--ion-color-secondary)",
                }}
              >
                {" "}
                {i18n.t("header.delivery")} :{" "}
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  {restaurantDataCtx.restaurantInfos.currentDeliveryTime} min
                </span>
              </span>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "lighter",
                  fontStyle: "italic",
                }}
              >
                {" "}
                ({i18n.t("header.directlyAtYourDoor")})
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0.5rem 0",
            }}
          >
            <IonIcon icon={locationOutline}></IonIcon>
            <div style={{ marginLeft: "0.5rem" }}>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "var(--ion-color-secondary)",
                }}
              >
                {" "}
                {i18n.t("header.clickAndCollect")} :{" "}
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "normal",
                  }}
                >
                  {restaurantDataCtx.restaurantInfos.currentDeliveryTime} min
                </span>
              </span>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "lighter",
                  fontStyle: "italic",
                }}
              >
                {" "}
                ({i18n.t("header.getOrderAt")}{" "}
                {restaurantDataCtx.restaurantInfos.addressFirstLine}{" "}
                {restaurantDataCtx.restaurantInfos.addressZipcode},{" "}
                {restaurantDataCtx.restaurantInfos.addressCity}){" "}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "var(--ion-color-secondary)",
                margin: "0.5rem 0",
              }}
            >
              {i18n.t("header.deliveryFeesAndZone")} :
            </div>
            <div
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: "var(--ion-color-secondary)",
                fontStyle: "italic",
                marginTop: "0.5rem",
              }}
            >
              {i18n.t("header.inputYourAddressAndClick")}
            </div>
            <AddressFetcher
              selectedPlace={newCustomerCtx.selectedSuggestion}
              onSelect={newCustomerCtx.suggestionSelectionHandler}
              value={newCustomerCtx.selectedSuggestion}
              isLoading={newCustomerCtx.isSearching}
              isLoadingSetter={newCustomerCtx.isSearchingHandler}
              deviceGeoCoordinates={deviceGeoCoordinates}
            />
          </div>
        </div>
      </div>
      <div style={{ margin: "1rem" }}>
        <BaseButton
          expand
          margins={"12px 0 0 0"}
          onClick={checkDeliveryFees}
          outline
          isLoading={newCustomerCtx.isLoading}
        >
          {i18n.t("header.confirmAdress")}
        </BaseButton>
        <BaseButton expand margins={"12px 0 0 0"} onClick={props.onDismiss}>
          {i18n.t("header.back")}
        </BaseButton>
      </div>
    </div>
  );
};
export default RestaurantInfoModal;
