import { IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
import CustomerAddressSelector from "../../components/Customer/CustomerAddressSelector/CustomerAddressSelector";
import OrdersConfirmation from "../../components/Orders/OrdersConfirmation/OrdersConfirmation";
import OrdersPaymentTypeSelector from "../../components/Orders/OrdersPaymentTypeSelector/OrdersPaymentTypeSelector";
import OrdersProductsSelector from "../../components/Orders/OrdersProductsSelector/OrdersProductsSelector";
import OrdersWebPayment from "../../components/Orders/OrdersWebPayment/OrdersWebPayment";
import OrderIsADeliverySelector from "../../components/Orders/OrderIsADeliverySelector/OrderIsADeliverySelector";
import OrderIsPlannedOrderSelector from "../../components/Orders/OrderIsPlannedOrderSelector/OrderIsPlannedOrderSelector";
import OrderPlannedTimeSelector from "../../components/Orders/OrderPlannedTimeSelector/OrderPlannedTimeSelector";
import SignInForm from "../../components/Auth/SignInForm";
import RestaurantHeaderInfo from "../../components/RestaurantHeaderInfo/RestaurantHeaderInfo";
import BaseButton from "../../components/@Base/BaseButton/BaseButton";
import NewOrderContext from "../../contexts/new-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import { useIonToast } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { call } from "ionicons/icons";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import "./Shop.css";
import AuthContext from "../../contexts/auth.ctx";
import i18n from "../../translations/i18n";

interface ShopProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const Shop: React.FC<ShopProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const newOrderCtx = useContext(NewOrderContext);
  const authCtx = useContext(AuthContext);
  const [deliveryTimeNotified, setDeliveryTimeNotified] = useState(false);
  const [present, dismiss] = useIonToast();

  let { restaurantUrlSlug } = useParams<{ restaurantUrlSlug: string }>();

  useEffect(() => {
    // restaurantDataCtx.setRestaurantIdHandler(restaurantUrlSlug);
    restaurantDataCtx.initRestaurantData(restaurantUrlSlug);
  }, []);

  // useEffect(() => {
  //   if(retryLastOrder){
  //     newOrderCtx.setCartHandler()
  //     newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
  //   }
  // }, [newOrderCtx, retryLastOrder]);

  useEffect(() => {
    if (restaurantDataCtx.restaurantInfos.pixelId) {
      const script = document.createElement("script");
      script.innerHTML = `
      !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${restaurantDataCtx.restaurantInfos.pixelId}');
fbq('track', 'PageView');`;
      document.head.appendChild(script);
      const advancedMatching = {
        em: authCtx.userEmail?.toLowerCase(),
      } as AdvancedMatching;
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init(
        restaurantDataCtx.restaurantInfos.pixelId,
        advancedMatching,
        options
      );
      ReactPixel.pageView();
    }
  }, [restaurantDataCtx.restaurantInfos]);

  useEffect(() => {
    if (
      !deliveryTimeNotified &&
      restaurantDataCtx.restaurantInfos.currentDeliveryTime
    ) {
      present(
        i18n.t("pages.Actually") +
          restaurantDataCtx.restaurantInfos.currentDeliveryTime +
          i18n.t("pages.DeliveryTime"),
        3000
      );
    }
  }, [restaurantDataCtx.restaurantInfos.currentDeliveryTime]);

  return (
    <IonPage>
      <IonContent fullscreen>
        {!restaurantDataCtx.isInit ? (
          <AppLoading />
        ) : restaurantDataCtx.isInit && !restaurantDataCtx.restaurantFound ? (
          <div className="shop-page--closed">
            {i18n.t("pages.NoRestaurant")}
          </div>
        ) : restaurantDataCtx.restaurantInfos.clickAndCollectClosed &&
          restaurantDataCtx.restaurantInfos.deliveryClosed ? (
          <div className="shop-page--closed">
            {i18n.t("pages.OnlineOrder")}
            {restaurantDataCtx.restaurantInfos?.phone ? (
              <a
                href={`tel:${restaurantDataCtx.restaurantInfos.phone}`}
                style={{ marginBottom: 18, marginTop: 18 }}
              >
                <BaseButton expand type="button">
                  <div>
                    <IonIcon
                      style={{ marginRight: 8 }}
                      icon={call}
                      color={"light"}
                    />
                    {i18n.t("pages.Call")}
                  </div>
                </BaseButton>
              </a>
            ) : null}
          </div>
        ) : (
          <div className="shop-page">
            <RestaurantHeaderInfo />

            {newOrderCtx.currentOrderStep == "PRODUCTS_SELECTION" ? (
              <OrdersProductsSelector />
            ) : newOrderCtx.currentOrderStep == "IS_A_DELIVERY_SELECTION" ? (
              <OrderIsADeliverySelector />
            ) : newOrderCtx.currentOrderStep == "IS_PLANNED_ORDER" ? (
              <OrderIsPlannedOrderSelector />
            ) : newOrderCtx.currentOrderStep == "ORDER_TIME_SELECTION" ? (
              <OrderPlannedTimeSelector />
            ) : newOrderCtx.currentOrderStep == "ORDER_CONFIRMED" ? (
              <OrdersConfirmation />
            ) : newOrderCtx.currentOrderStep == "SIGN_IN" ? (
              <div style={{ marginBottom: "16px" }}>
                <SignInForm />
              </div>
            ) : newOrderCtx.currentOrderStep == "CUSTOMER_ADDRESS_SELECTION" ? (
              <div style={{ marginBottom: "16px" }}>
                <CustomerAddressSelector />
              </div>
            ) : newOrderCtx.currentOrderStep == "PAYMENT_TYPE_SELECTION" ? (
              <OrdersPaymentTypeSelector />
            ) : newOrderCtx.currentOrderStep == "WEB_PAYMENT" ? (
              <div style={{ marginBottom: "16px" }}>
                <OrdersWebPayment />
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Shop;
