import React, { useContext, useEffect, useState } from "react";
import Product from "../models/Product";
import RestaurantDataContext from "./restaurant-data.ctx";

const ProductsPageContext = React.createContext({
  currentProductsCategoryId: "",
  currentProductsName: "",
  currentProductsList: [] as Product[],
  // scrollRef : [{
  //   catID: "",
  //   ref : ref
  // }},
  productsCategorySelectionHandler: (categoryId: string, name: string) => {},
});

export const ProductsPageContextProvider: React.FC = (props) => {
  

  const restaurantDataCtx = useContext(RestaurantDataContext);

  const [currentProductsCategoryId, setCurrentProductsCategoryId] =
    useState("");
    const [currentProductsName, setCurrentProductsName] =
    useState("");
  const [currentProductsList, setCurrentProductsList] = useState(
    [] as Product[]
  );
  const [productsLists, setProductsLists] = useState(
    [] as Product[]
  );

  useEffect(() => {
    if (!currentProductsCategoryId) {
      setCurrentProductsList(restaurantDataCtx.productsList);
    } else {
      const products = restaurantDataCtx.productsList.filter(
        (product: Product) => {
          return currentProductsCategoryId == product.categoryId;
        }
      );
      // const products = restaurantDataCtx.productsList;
      setCurrentProductsList(products);
    }
  }, [restaurantDataCtx.productsList, currentProductsCategoryId]);

  const productsCategorySelectionHandler = (categoryId: string, name : string) => {
    setCurrentProductsCategoryId(categoryId);
    setCurrentProductsName(name);
  };

  return (
    <ProductsPageContext.Provider
      value={{
        currentProductsCategoryId,
        currentProductsList,
        currentProductsName,
        productsCategorySelectionHandler,
      }}
    >
      {props.children}
    </ProductsPageContext.Provider>
  );
};

export default ProductsPageContext;
