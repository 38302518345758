import { IonIcon, IonImg } from "@ionic/react";
import { useIonModal } from "@ionic/react";
import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import Product from "../../../models/Product";
import OrderDetailModal from "../../Modals/OrderDetailModal/OrderDetailModal";
import "./OrdersMiniCart.css";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import AuthContext from "../../../contexts/auth.ctx";
import { heart } from "ionicons/icons";

interface ContainerProps {
  onItemSelect: Function;
}

const OrdersMiniCart: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const selectGridItemHandler = (product: Product, index: number) => {
    if (props.onItemSelect) {
      props.onItemSelect(product, index);
    }
  };
  const [present, dismiss] = useIonModal(OrderDetailModal, {
    onItemSelect: selectGridItemHandler,
    orderContext: newOrderCtx,
    minimumOrderAmount: restaurantDataCtx.restaurantInfos.minimumOrderAmount,
    onDismiss: () => {
      dismiss();
    },
  });

  const getTotalPrice = () => {
    return (
      newOrderCtx.cart.reduce((prev, product) => {
        return prev + product.price;
      }, 0) / 100
    );
  };

  return (
    <div
      className="orders-mini-cart"
      data-testid="orders-mini-cart"
      onClick={() => present()}
    >
      <div className="orders-mini-cart__products">
        {newOrderCtx.cart.length ? (
          newOrderCtx.cart.map((product, index) => {
            return (
              <div
                key={`mini-cart-item-${index}-${product.id}`}
                className="orders-mini-cart__products__product-item"
              >
                <IonImg src={product.thumbnailUrl} />
                <div className="orders-mini-cart__products__product-item__amount">
                  x{product.quantity}
                </div>
              </div>
            );
          })
        ) : authCtx.restaurantFavoriteOrder &&
          authCtx.restaurantFavoriteOrder?.products ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <BaseButton
              onClick={() => {
                newOrderCtx.setCartHandler(
                  authCtx.restaurantFavoriteOrder!.products
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {i18n.t("cart.addFavoriteOrder")}
                <IonIcon
                  icon={heart}
                  style={{ marginLeft: 4, height: 20, width: 20 }}
                />
              </div>
            </BaseButton>
          </div>
        ) : null}
      </div>
      <div className="orders-mini-cart__cart">
        <div className="orders-mini-cart__total">
          <span>{i18n.t("cart.total")}</span>
          <span>
            {constants.CURRENCY === "€"
              ? getTotalPrice().toFixed(2) + ` ${constants.CURRENCY}`
              : `${constants.CURRENCY}` + getTotalPrice().toFixed(2)}
          </span>
        </div>
        <div className="orders-full-cart__validation">
          {i18n.t("cart.seeOrder")}
        </div>
      </div>
    </div>
  );
};

export default OrdersMiniCart;
