import React, { useEffect, useState, useContext } from "react";
import API from "../services";
import CustomerInfos from "../models/CustomerInfos";
import Product from "../models/Product";
import { Storage } from "@ionic/storage";
import RestaurantDataContext from "./restaurant-data.ctx";
import buildEnv from "../build.env.json";
import AuthContext from "./auth.ctx";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import firebase from "firebase/app";
import Order from "../models/Order";
import i18n from "../translations/i18n";

type OrderType = "DELIVERY" | "ON_PLACE";
type OrderPlanningType = "NOW" | "PLANNED";

const defaultRestaurantId =
  buildEnv.BUILD_ENV == "PROD"
    ? "9fce979a-02f0-4156-b33d-9f2c215bf8cf" //HappyB
    : "f8edcd4e-e195-4285-96ae-b65e3ccb89d3";

const equalsArrayIgnoreOrder = (
  a: Array<any> | undefined,
  b: Array<any> | undefined
) => {
  if (a && b) {
    let result =
      a.length === b.length &&
      a.every(function (element) {
        return b.includes(element);
      });
    return result;
  } else if (a == b) {
    return true;
  } else {
    return false;
  }
};

const NewOrderContext = React.createContext({
  isLoading: false,
  orderType: "DELIVERY",
  orderPlanningType: "PLANNED",
  orderPlannedTime: new Date(),
  shouldStartOrder: true,
  orderPlanningTypeSelectionHandler: (type: OrderPlanningType) => {},
  orderPlanningTimeSelectionHandler: (type: Date) => {},
  setRestaurantIdHandler: (restaurantId: string) => {},
  orderTypeSelectionHandler: (type: OrderType) => {},
  distanceToRestaurant: 0,
  setDistanceToRestaurantHandler: (distance: number) => {},
  customer: {},
  customerSelectionHandler: (customer: Record<any, any>) => {},
  cart: [] as Product[],
  addProductToCart: (product: Product) => {},
  modifProductCart: (product: Product, index: number) => {},
  removeProductFromCart: (index: number) => {},
  paymentType: "STRIPE",
  paymentTypeSelectionHandler: (paymentType: "STRIPE" | "IRL") => {},
  resetContext: () => {},
  redirectToOrdersTrigger: false,
  submitNewOrder: () => {},
  currentOrderStep: "PRODUCTS_SELECTION",
  currentOrderStepChangeHandler: (orderStep: string) => {},
  isOrderInProgress: false,
  orderNumber: "",
  setLocalOrderAsCompleted: () => {},
  setCartHandler: (cart: Product[]) => {},
});

export const NewOrderContextProvider: React.FC = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderType, setOrderType] = useState("DELIVERY");
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);
  // Order planning data states
  const [orderPlanningType, setOrderPlanningType] = useState("NOW");
  const [orderPlannedTime, setOrderPlannedTime] = useState<Date>(new Date());
  const [shouldStartOrder, setShouldStartOrder] = useState(true);

  const [distanceToRestaurant, setDistanceToRestaurant] = useState(0);

  const [customer, setCustomer] = useState<any>({});
  const [cart, setCart] = useState([] as Product[]);
  const [paymentType, setPaymentType] = useState("STRIPE");
  const [currentOrderStep, setCurrentOrderStep] =
    useState("PRODUCTS_SELECTION");

  const [restaurantId, setRestaurantId] = useState(defaultRestaurantId);

  const [redirectToOrdersTrigger, setRedirectToProductsTrigger] =
    useState(false);

  const [isOrderInProgress, setIsOrderInProgress] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");

  const setCartHandler = (newCart: Product[]) => {
    setCart(newCart);
  };
  const resetContext = () => {
    setOrderType("DELIVERY");
    setOrderPlanningType("NOW");
    setOrderPlannedTime(new Date());
    setShouldStartOrder(true);
    setCustomer({});
    setCart([]);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!customer || !customer.phone) {
      setCustomer((prevState: any) => ({
        ...prevState,
        phone: authCtx.userData.phone_number_auth
          ? authCtx.userData.phone_number_auth
          : authCtx.userData.phone,
      }));
    }
  }, [authCtx.userData.phone_number_auth, authCtx.userData.phone]);
  const setDistanceToRestaurantHandler = (distance: number) => {
    setDistanceToRestaurant(distance);
  };
  const setRestaurantIdHandler = (restaurantId: string) => {
    setRestaurantId(restaurantId);
  };

  const orderTypeSelectionHandler = (type: OrderType) => {
    setOrderType(type);
  };

  const orderPlanningTypeSelectionHandler = (type: OrderPlanningType) => {
    setOrderPlanningType(type);
    if (type == "NOW") {
      setShouldStartOrder(true);
    } else if (type == "PLANNED") {
      setShouldStartOrder(false);
    }
  };

  const orderPlanningTimeSelectionHandler = (date: Date) => {
    setOrderPlannedTime(date);
  };

  const currentOrderStepChangeHandler = (orderStep: string) => {
    setCurrentOrderStep(orderStep);
  };

  const returnToOrdersPage = () => {
    setRedirectToProductsTrigger(true);
    setRedirectToProductsTrigger(false);
  };

  const customerSelectionHandler = (customer: Record<any, any>) => {
    setCustomer(customer);
  };

  const addProductToCart = (product: Product) => {
    setCart([...cart, product]);
  };

  const modifProductCart = (product: Product, index: number) => {
    const tmpCart = [...cart];

    tmpCart[index] = product;
    setCart(tmpCart);
  };

  const removeProductFromCart = (index: number) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };

  const paymentTypeSelectionHandler = (paymentType: "STRIPE" | "IRL") => {
    setPaymentType(paymentType);
  };

  const getTotalPrice = () => {
    return (
      cart.reduce((prev, product) => {
        return prev + product.price;
      }, 0) / 100
    );
  };

  const submitNewOrder = () => {
    const total = getTotalPrice();

    ReactPixel.track("Purchase", { value: total, currency: "EUR" });

    setIsSubmitting(true);
  };

  useEffect(() => {
    const checkOrderNumber = async () => {
      const storage = new Storage();
      await storage.create();
      const recoveredOrderNumber = await storage.get("orderNumber");

      if (recoveredOrderNumber) {
        setOrderNumber(recoveredOrderNumber);
        setCurrentOrderStep("ORDER_CONFIRMED");
      }
    };
    checkOrderNumber();
  }, []);

  useEffect(() => {
    if (isSubmitting && restaurantDataCtx.restaurantId) {
      setIsOrderInProgress(true);
      const cartToSubmit = cart.map((item) => {
        const optionsIds = item.optionsPreset?.map((optPres) => {
          return optPres.id;
        });
        return {
          id: item.id,
          quantity: item.quantity,
          optionsIds,
        };
      });

      if (cartToSubmit) {
        API.createNewLocalUnpaidOrder(
          cartToSubmit,
          paymentType as "STRIPE" | "IRL",
          restaurantDataCtx.restaurantId,
          orderType,
          orderPlannedTime,
          orderPlanningType,
          shouldStartOrder,
          orderType == "DELIVERY"
            ? customer
            : {
                phone: authCtx.userData.phone_number_auth
                  ? authCtx.userData.phone_number_auth
                  : authCtx.userData.phone,
              }
        )
          .then(async (response) => {
            setOrderNumber(response.toString());
            setIsOrderInProgress(false);
            setCurrentOrderStep("ORDER_CONFIRMED");
            const storage = new Storage();
            await storage.create();
            await storage.set("orderNumber", response.toString());
          })
          .catch((err) => {
            alert(
              err.response
                ? err.response.data
                  ? err.response.data.error
                    ? i18n.t("context.Error") + err.response.data.error
                    : i18n.t("context.ErrorOccured")
                  : i18n.t("context.ErrorOccured")
                : i18n.t("context.ErrorOccured")
            );

            console.log(err);
            console.log(err.response);
            setIsOrderInProgress(false);
          });
      }
    }
  }, [isSubmitting]);

  const setLocalOrderAsCompleted = async () => {
    const storage = new Storage();
    await storage.create();
    await storage.remove("orderNumber");
    setCustomer({});
    setCart([]);
    setIsSubmitting(false);
    setOrderNumber("");
    setCurrentOrderStep("PRODUCTS_SELECTION");
  };

  return (
    <NewOrderContext.Provider
      value={{
        isLoading: isSubmitting,
        orderType,
        orderPlanningType,
        orderPlannedTime,
        shouldStartOrder,
        orderPlanningTypeSelectionHandler,
        orderPlanningTimeSelectionHandler,
        setRestaurantIdHandler,
        orderTypeSelectionHandler,
        distanceToRestaurant,
        setDistanceToRestaurantHandler,
        customer,
        customerSelectionHandler,
        cart,
        addProductToCart,
        modifProductCart,
        removeProductFromCart,
        paymentType,
        paymentTypeSelectionHandler,
        resetContext,
        redirectToOrdersTrigger,
        submitNewOrder,
        currentOrderStep,
        currentOrderStepChangeHandler,
        isOrderInProgress,
        orderNumber,
        setLocalOrderAsCompleted,
        setCartHandler,
      }}
    >
      {props.children}
    </NewOrderContext.Provider>
  );
};

export default NewOrderContext;
