export type DeliveryFeesByCity = {
  name: string;
  zipCode: string;
  deliveryFees?: number;
  freeDeliveryFeesTrigger?: number;
};

export default class {
  public id;
  public name;
  public phone;
  public email;
  public logoUrl;
  public logoThumbnailUrl;
  public addressFirstLine;
  public addressSecondLine;
  public addressZipcode;
  public addressCity;
  public addressLatitude;
  public addressLongitude;
  public addressPlaceId;
  public currentDeliveryTime;
  public deliveryFees;
  public freeDeliveryFeesTrigger;
  public availableCitiesOnDelivery;

  public currentClickAndCollectTime;
  public pixelId;
  public deliveryClosed;
  public clickAndCollectClosed;
  public enableDoorPayment;
  public currentDeliveryTimeByKm;
  public deliveryFeesByKm;
  public maxDeliveryRange;
  public minimumOrderAmount;
  public subscriptionPlan;
  public reviewLink;
  constructor(
    id: string,
    name: string,
    phone: string,
    email: string,
    logoUrl: string,
    logoThumbnailUrl: string,
    addressFirstLine: string,
    addressSecondLine: string,
    addressZipcode: string,
    addressCity: string,
    addressLatitude: number,
    addressLongitude: number,
    addressPlaceId: string,
    currentDeliveryTime: number,
    deliveryFees: number,
    freeDeliveryFeesTrigger: number,
    availableCitiesOnDelivery?: DeliveryFeesByCity[],
    currentClickAndCollectTime?: number,
    pixelId?: string,
    deliveryClosed?: boolean,
    clickAndCollectClosed?: boolean,
    enableDoorPayment?: boolean,
    currentDeliveryTimeByKm?: number,
    deliveryFeesByKm?: number,
    maxDeliveryRange?: number,
    minimumOrderAmount?: number,
    subscriptionPlan?: string,
    reviewLink?: string
  ) {
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.logoUrl = logoUrl;
    this.logoThumbnailUrl = logoThumbnailUrl;
    this.addressFirstLine = addressFirstLine;
    this.addressSecondLine = addressSecondLine;
    this.addressZipcode = addressZipcode;
    this.addressCity = addressCity;
    this.addressLatitude = addressLatitude;
    this.addressLongitude = addressLongitude;
    this.addressPlaceId = addressPlaceId;
    this.currentDeliveryTime = currentDeliveryTime;
    this.deliveryFees = deliveryFees;
    this.freeDeliveryFeesTrigger = freeDeliveryFeesTrigger;
    this.availableCitiesOnDelivery = availableCitiesOnDelivery;
    this.currentClickAndCollectTime = currentClickAndCollectTime;
    this.pixelId = pixelId;
    this.deliveryClosed = deliveryClosed;
    this.clickAndCollectClosed = clickAndCollectClosed;
    this.enableDoorPayment = enableDoorPayment;
    this.currentDeliveryTimeByKm = currentDeliveryTimeByKm;
    this.deliveryFeesByKm = deliveryFeesByKm;
    this.maxDeliveryRange = maxDeliveryRange;
    this.minimumOrderAmount = minimumOrderAmount;
    this.subscriptionPlan = subscriptionPlan;
    this.reviewLink = reviewLink;
  }
}
