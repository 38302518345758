import { useContext, useEffect } from "react";
import ProductsPageContext from "../../../contexts/products-page.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import ProductCategory from "../../../models/ProductCategory";
import ProductsCategoryChip from "../ProductsCategoryChip/ProductsCategoryChip";
import i18n from "../../../translations/i18n";
import "./ProductsCategorySelector.css";

interface ContainerProps {
  isInOrderMode?: boolean;
}

const ProductsCategorySelector: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const productsPageCtx = useContext(ProductsPageContext);

  useEffect(() => {
    productsPageCtx.productsCategorySelectionHandler("", "");
    return () => productsPageCtx.productsCategorySelectionHandler("", "");
  }, []);

  const getClasses = () => {
    const base = ["products-category-selector"];
    if (props.isInOrderMode) {
      base.push("products-category-selector--order-mode");
    }
    return base.join(" ");
  };

  return (
    <div className={getClasses()} data-testid="products-category-selector">
      {/* <span>Catégories</span> */}
      <div className="products-category-selector__category-list">
        <ProductsCategoryChip
          key={"all-products"}
          name={i18n.t("cart.all")}
          color={"#000"}
          isMuted={productsPageCtx.currentProductsCategoryId != ""}
          action={() =>
            productsPageCtx.productsCategorySelectionHandler("", "")
          }
        />
        {restaurantDataCtx.productCategoriesList
          .sort((a, b) => a.position - b.position)
          .map((category: ProductCategory) => {
            if (category.name != "...") {
              return (
                <ProductsCategoryChip
                  key={category.id}
                  name={category.name}
                  color={category.color}
                  isMuted={
                    productsPageCtx.currentProductsCategoryId != category.id
                  }
                  action={() =>
                    productsPageCtx.productsCategorySelectionHandler(
                      category.id,
                      category.name
                    )
                  }
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default ProductsCategorySelector;
