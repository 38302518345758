import BaseForm from "../@Base/BaseForm/BaseForm";
import "./SignInForm.css";
import { SignInContextProvider } from "../../contexts/sign-in.ctx";
import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { useIonAlert, IonToggle } from "@ionic/react";
import BaseButton from "../@Base/BaseButton/BaseButton";
import BaseTextInput from "../@Base/BaseTextInput/BaseTextInput";
import AddressFetcher from "../@Shared/AddressFetcher/AddressFetcher";
import authService from "../../services/auth-service";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import NewOrderContext from "../../contexts/new-order.ctx";
import "./SignInForm.css";
import {
  $firebaseAuth,
  $firebaseFunctions,
} from "../../services/firebase-service";
import axios from "axios";
import buildEnv from "../../build.env.json";
import { constants } from "../../utils/constants";
import i18n from "../../translations/i18n";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";

interface ContainerProps {}

type signInSteps = "PHONE_NUMBER" | "OTP_CODE";

const apiEndpoints = {
  createUserIfNotExists: {
    dev: `${constants.API_URL}/customerApi/createUserIfDoNotExists`,
    prod: `${constants.API_URL}/customerApi/createUserIfDoNotExists`,
  },
};

export const LOGO_ICON_MODE = true;

const SignInForm: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const newOrderCtx = useContext(NewOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [isBusy, setIsBusy] = useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult>();
  const [phoneNumber, setPhoneNumber] = useState(
    buildEnv.REGION === "US" ? "+1" : "+33"
  );
  const [OTP, setOTP] = useState("");
  const [currentSignInStep, setCurrentSignInStep] = useState("PHONE_NUMBER");
  const [allowSMS, setAllowSMS] = useState(false);

  const [present] = useIonAlert();

  const submitPhoneNumber = () => {
    if (!isBusy) {
      setIsBusy(true);
      let finalPhoneNumber: string;

      if (phoneNumber.startsWith("0")) {
        finalPhoneNumber = phoneNumber.replace(
          "0",
          buildEnv.REGION === "US" ? "+1" : "+33"
        );
      } else {
        finalPhoneNumber = phoneNumber;
      }

      // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      //   "recaptcha-container",
      //   { size: "invisible"}

      // );
      $firebaseAuth
        .signInWithPhoneNumber(finalPhoneNumber, window.recaptchaVerifier)
        .then((confirmResult) => {
          setConfirmationResult(confirmResult);
          setCurrentSignInStep("OTP_CODE");
          setIsBusy(false);
        })
        .catch((error) => {
          setIsBusy(false);
          console.log(error);
          if (error.code == "auth/invalid-phone-number") {
            present({
              header: i18n.t("auth.Error"),
              message: i18n.t("auth.InvalidPhone"),
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => {},
            });
          } else {
            present({
              header: i18n.t("auth.Error"),
              message: i18n.t("auth.ErrorOccured"),
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => {},
            });
          }
        });
    }
  };

  useEffect(() => {
    var elementExists = document.getElementById("recaptcha-container");
    if (elementExists && !window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" }
      );
    }
  });

  const handleSetAllowSMS = (allow: boolean) => {
    setAllowSMS(allow);
  };
  const submitOTPCode = () => {
    if (!isBusy) {
      if (OTP === null || confirmationResult === null) {
        return;
      } else {
        setIsBusy(true);
        confirmationResult
          ?.confirm(OTP)
          .then(async (result) => {
            const user = result.user;

            const idToken = await user?.getIdToken();

            const headers = {
              Authorization: `Bearer ${idToken}`,
            };
            if (idToken) {
              const apiEndpoint =
                buildEnv.BUILD_ENV == "DEV"
                  ? apiEndpoints.createUserIfNotExists.dev
                  : apiEndpoints.createUserIfNotExists.prod;
              await axios.post(
                apiEndpoint,
                { phoneNumber: user?.phoneNumber, allowSMS: allowSMS },
                {
                  headers: headers,
                }
              );
              if (newOrderCtx.orderType == "DELIVERY") {
                newOrderCtx.currentOrderStepChangeHandler(
                  "CUSTOMER_ADDRESS_SELECTION"
                );
              } else {
                if (restaurantDataCtx.restaurantInfos.enableDoorPayment) {
                  newOrderCtx.currentOrderStepChangeHandler(
                    "PAYMENT_TYPE_SELECTION"
                  );
                } else {
                  newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
                }
              }
            }
            setIsBusy(false);
          })
          .catch((err) => {
            console.log(err);
            setIsBusy(false);
            if (err.code == "auth/invalid-verification-code") {
              present({
                header: i18n.t("auth.Error"),
                message: i18n.t("auth.NoOtpCode"),
                buttons: [{ text: "Ok", handler: (d) => {} }],
                onDidDismiss: (e) => {},
              });
            }
          });
      }
    }
  };
  return (
    <div className="sign-in" data-testid="sign">
      <div className="sign-in__form">
        <span className="sign-in__form__title">{i18n.t("order.Identify")}</span>
        <BaseTextInput
          label={i18n.t("auth.Phone")}
          controller={{
            onChange: (data: any) => setPhoneNumber(data.target.value),
            value: phoneNumber,
          }}
        />
        <div
          style={{
            position: "relative",
            top: "0.25rem",
            fontSize: "0.75rem",
          }}
        >
          {i18n.t("auth.numberFormat")}
        </div>
        <div id="recaptcha-container"></div>
        {currentSignInStep == "OTP_CODE" ? (
          <>
            <span className="sign-in__form__small" onClick={submitPhoneNumber}>
              {i18n.t("auth.NoCode")}
            </span>
            <BaseTextInput
              label={i18n.t("auth.OtpCode")}
              controller={{
                onChange: (data: any) => setOTP(data.target.value),
                value: OTP,
              }}
            />
            <div className="sign-in__form-toogle">
              <IonToggle
                mode={"ios"}
                checked={allowSMS}
                onIonChange={(event) => handleSetAllowSMS(event.detail.checked)}
              />
              <span className="sign-in__form__small-toggle">
                {" "}
                {i18n.t("auth.PromotionalOffers")}
              </span>
            </div>
          </>
        ) : null}

        <BaseButton
          expand
          margins={"32px 0 0 0"}
          isLoading={isBusy}
          disabled={currentSignInStep == "PHONE_NUMBER" ? !phoneNumber : !OTP}
          onClick={() => {
            if (currentSignInStep == "PHONE_NUMBER") {
              submitPhoneNumber();
            } else if (currentSignInStep == "OTP_CODE") {
              submitOTPCode();
            }
          }}
        >
          {currentSignInStep == "PHONE_NUMBER"
            ? i18n.t("auth.SendOTP")
            : i18n.t("auth.ValidateOTP")}
        </BaseButton>
      </div>
    </div>
  );
};

export default SignInForm;
