import React, { useEffect, useState } from "react";
import API from "../services";
import { $firebaseAuth } from "../services/firebase-service";
import authServices from "../services/auth-service";
import { customerData, FavoriteOrder } from "../types";
import axios from "axios";
import buildEnv from "../build.env.json";
import Product from "../models/Product";

export enum AuthStates {
  "AUTH",
  "NOT_AUTH",
  "NOT_INIT",
}

const AuthContext = React.createContext({
  authState: AuthStates.NOT_INIT,
  role: "",
  userRole: "",
  restaurantId: "",
  userId: "",
  userEmail: "",
  userData: {} as customerData,
  favoriteOrders: [] as FavoriteOrder[],
  restaurantFavoriteOrder: undefined as FavoriteOrder | undefined,
  signOut: () => {},
  updateUserData: async (data: customerData) => {},
  setRestaurantFavoriteOrder: (order: FavoriteOrder) => {},
});

export const AuthContextProvider: React.FC = (props) => {
  const [authState, setAuthState] = useState(AuthStates.NOT_INIT);
  const [role, setRole] = useState("");
  const [userRole, setUserRole] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({} as customerData);
  const [userEmail, setUserEmail] = useState("");
  const [favoriteOrders, setFavoriteOrders] = useState([] as FavoriteOrder[]);
  const [restaurantFavoriteOrder, setRestaurantFavoriteOrders] =
    useState<FavoriteOrder>();
  // TODO : Add a props for premium user status
  const updateUserData = async (data: customerData) => {
    try {
      const newUserData = await authServices.updateUserData(data);

      setUserData(newUserData);
    } catch (err) {
      console.log(err);
    }
  };
  const setRestaurantFavoriteOrder = (order: FavoriteOrder) => {
    setRestaurantFavoriteOrders(order);
  };

  useEffect(() => {
    const authStateChangedUnsubscribe = $firebaseAuth.onAuthStateChanged(
      (user) => {
        if (!user) {
          setAuthState(AuthStates.NOT_AUTH);
          setRole("");
          setUserRole("");
          setRestaurantId("");
          setUserId("");
          setFavoriteOrders([]);
        } else {
          user.getIdTokenResult().then((token) => {
            setAuthState(AuthStates.AUTH);
            setRole(token.claims.role);
            setUserRole(token.claims.user_role);
            setRestaurantId(token.claims.restaurant_id);
            setUserId(user.uid);
            setUserEmail(user.email || "");
            authServices
              .getAuthenticatedUserData()
              .then((userSnap: any) => {
                setUserData(userSnap.userData);
                const newFavoriteOrders = userSnap.userFavoriteOrders.map(
                  (order: FavoriteOrder) => {
                    return {
                      ...order,
                      products: order.products.map((product: any) => {
                        return new Product(
                          product.id,
                          product.name,
                          product.price,
                          product.basePrice || product.price,
                          product.category_id,
                          product.has_options,
                          product.picture_url,
                          product.thumbnail_url,
                          product.quantity,
                          product.description,
                          product.products_options_ids,
                          product.options,
                          product.unavailable,
                          product.long_desc
                        );
                      }),
                    };
                  }
                );
                setFavoriteOrders(newFavoriteOrders);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      }
    );
    return () => authStateChangedUnsubscribe();
  }, []);

  const signOutHandler = () => {
    $firebaseAuth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        role,
        userRole,
        restaurantId,
        userId,
        userEmail,
        userData,
        favoriteOrders,
        restaurantFavoriteOrder,
        signOut: signOutHandler,
        updateUserData: updateUserData,
        setRestaurantFavoriteOrder,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
