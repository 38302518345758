import Order from "../../../models/Order";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import OrdersFullCart from "../../Orders/OrdersFullCart/OrdersFullCart";
import OrderSummary from "../../Orders/OrderSummary/OrderSummary";
import Product from "../../../models/Product";
import "./OrderDetailModal.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  orderContext?: any;
  order?: Order;
  asRestaurantOrder?: boolean;
  onDelete?: Function;
  onPrint?: Function;
  onDismiss: Function;
  onItemSelect?: Function;
  minimumOrderAmount?: number;
  // onConfirmAndDismiss: Function;
}

const OrderDetailModal: React.FC<ContainerProps> = (props) => {
  const onConfirmHandler = () => {
    setTimeout(() => {
      props.orderContext.currentOrderStepChangeHandler(
        "IS_A_DELIVERY_SELECTION"
      );
    }, 300);
    props.onDismiss();
  };

  // const onConfirmHandler = () => {
  //   if (props.orderContext.orderType === "DELIVERY") {
  //     setTimeout(() => {
  //       props.orderContext.currentOrderStepChangeHandler(
  //         "CUSTOMER_ADDRESS_SELECTION"
  //       );
  //     }, 300);
  //     props.onDismiss();
  //   } else if (props.orderContext.orderType === "ON_PLACE") {
  //     setTimeout(() => {
  //       props.orderContext.currentOrderStepChangeHandler(
  //         "PAYMENT_TYPE_SELECTION"
  //       );
  //     }, 300);
  //     props.onDismiss();
  //   }
  // };

  return (
    <div className="order-detail-modal" data-testid="order-detail-modal">
      <BaseModalHeader onDismiss={props.onDismiss}>
        {i18n.t("modals.OrderDetails")}
      </BaseModalHeader>
      {props.orderContext && (
        <div className="order-detail-modal__cart">
          <OrdersFullCart
            externalContext={props.orderContext}
            onConfirm={() => onConfirmHandler()}
            onItemSelect={
              props.onItemSelect
                ? (product: Product, index: number) =>
                    props.onItemSelect?.(product, index, undefined)
                : undefined
            }
            onDismiss={() => props.onDismiss()}
          />
        </div>
      )}
      {props.order && (
        <div className="order-detail-modal__cart">
          <OrderSummary
            order={props.order}
            onDelete={() => {
              if (props.onDelete) {
                props.onDelete(props.order);
              }
            }}
            onPrint={() => {
              if (props.onPrint) {
                props.onPrint(props.order);
              }
            }}
            asRestaurantDisplay={props.asRestaurantOrder}
          />
        </div>
      )}
    </div>
  );
};

export default OrderDetailModal;
