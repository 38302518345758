import React, { useState, useContext, useEffect } from "react";
import { useIonAlert } from "@ionic/react";
import AuthContext from "./auth.ctx";
import { GCS } from "../services";
import RestaurantDataContext from "./restaurant-data.ctx";
import i18n from "../translations/i18n";
import { customerData } from "../types";
import { distanceConversion } from "geolib";
const NewCustomerContext = React.createContext({
  isLoading: false,
  isSearching: false,
  phone: "",
  phoneInputHandler: (phone: string) => {},
  addressComplement: "",
  addressComplementInputHandler: (complement: string) => {},
  firstname: "",
  firstnameInputHandler: (firstname: string) => {},
  lastname: "",
  lastnameInputHandler: (lastname: string) => {},
  selectedSuggestion: {} as google.maps.places.AutocompletePrediction,
  suggestionSelectionHandler: (suggestion: any) => {},
  addressCity: "",
  addressCityHandler: (city: any) => {},
  addressZipCode: "",
  addressZipCodeHandler: (zipCode: any) => {},
  submitCreationHandler: () => {},
  resetContext: () => {},
  modalClosingTrigger: false,
  isSearchingHandler: (value: boolean) => {},
  placeId: "",
  setPlaceIdHandler: (placeId: string) => {},
  getCustomer: async (): Promise<any> => {},
  checkAndSetCustomerAddress: async (): Promise<any> => {},
});

export const NewCustomerContextProvider: React.FC = (props) => {
  const [present] = useIonAlert();
  const authCtx = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [phone, setPhone] = useState(authCtx.userData.phone_number_auth || "");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [selectedSuggestion, setSelectedSelection] = useState(
    {} as google.maps.places.AutocompletePrediction
  );
  const [modalClosingTrigger, setModalClosingTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const setPlaceIdHandler = (placeId: string) => {
    setPlaceId(placeId);
  };
  useEffect(() => {
    if (!firstname && authCtx.userData.firstname) {
      setFirstname(authCtx.userData.firstname);
    }
    if (!lastname && authCtx.userData.lastname) {
      setLastname(authCtx.userData.lastname);
    }
    if (
      (!phone && authCtx.userData.phone) ||
      authCtx.userData.phone_number_auth
    ) {
      setPhone(
        authCtx.userData.phone
          ? authCtx.userData.phone
          : authCtx.userData.phone_number_auth
          ? authCtx.userData.phone_number_auth
          : ""
      );
    }
    if (!addressComplement && authCtx.userData.address_second_line) {
      setAddressComplement(authCtx.userData.address_second_line);
    }
    if (!addressCity && authCtx.userData.city) {
      setAddressComplement(authCtx.userData.city);
    }
    if (!addressZipCode && authCtx.userData.zipcode) {
      setAddressComplement(authCtx.userData.zipcode);
    }
    if (
      Object.keys(selectedSuggestion).length === 0 &&
      authCtx.userData.selected_maps_suggestion
    ) {
      setSelectedSelection(authCtx.userData.selected_maps_suggestion);
    }
  }, [authCtx.userData, authCtx.userId]);

  const resetContext = () => {
    setPhone("");
    setFirstname("");
    setLastname("");
    setAddressCity("");
    setAddressZipCode("");
    setAddressComplement("");
    setSelectedSelection({} as google.maps.places.AutocompletePrediction);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (selectedSuggestion?.place_id) {
      GCS.getPlaceGeocoding(selectedSuggestion.place_id).then((data) => {
        let postalCode = data.results[0].address_components.find(function (
          component
        ) {
          return component.types[0] == "postal_code";
        });
        let city = data.results[0].address_components.find(function (
          component
        ) {
          return component.types[0] == "locality";
        });

        setAddressCity(city?.short_name || "");
        setAddressZipCode(postalCode?.short_name || "");
      });
    }
  }, [selectedSuggestion]);
  const phoneInputHandler = (phone: string) => {
    setPhone(phone);
  };

  const firstnameInputHandler = (firstname: string) => {
    setFirstname(firstname);
  };

  const addressCityHandler = (city: string) => {
    setAddressCity(city);
  };
  const addressZipCodeHandler = (zipCode: string) => {
    setAddressZipCode(zipCode);
  };
  const lastnameInputHandler = (lastname: string) => {
    setLastname(lastname);
  };

  const addressComplementInputHandler = (addressComplement: string) => {
    setAddressComplement(addressComplement);
  };

  const suggestionSelectionHandler = (suggestion: any) => {
    setSelectedSelection(suggestion);
  };

  const submitCreationHandler = () => {
    setIsSubmitting(true);
  };

  const isSearchingHandler = (value: boolean) => {
    setIsSearching(value);
  };

  const returnToCustomerSelectionPage = () => {
    setModalClosingTrigger(true);
    setModalClosingTrigger(false);
  };

  const getCustomer = async () => {
    const customer = await GCS.getPlaceGeocoding(
      selectedSuggestion.place_id
    ).then((data) => {
      let postalCode = data.results[0].address_components.find(function (
        component
      ) {
        return component.types[0] == "postal_code";
      });
      let city = data.results[0].address_components.find(function (component) {
        return component.types[0] == "locality";
      });

      return {
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        addressPlaceId: selectedSuggestion.place_id,
        addressFirstLine: selectedSuggestion.structured_formatting.main_text,
        addressSecondLine: addressComplement,
        addressZipcode: addressZipCode || postalCode?.short_name,
        addressCity: addressCity || city?.short_name,
        addressLatitude: data.results[0].geometry.location.lat(),
        addressLongitude: data.results[0].geometry.location.lng(),
      };
    });
    return customer as any;
  };

  const checkAndSetCustomerAddress = async () => {
    try {
      setIsLoading(true);
      const customer = await getCustomer();

      const distanceToRestaurant = await GCS.calculateRealDistance(
        restaurantDataCtx.restaurantInfos.addressPlaceId,
        customer.addressPlaceId
      );

      const maxDeliveryRange =
        restaurantDataCtx.restaurantInfos.maxDeliveryRange || 5;

      if (distanceToRestaurant > maxDeliveryRange) {
        setIsLoading(false);
        present({
          header: i18n.t("customer.Error"),
          message: i18n.t("customer.NotDeliver"),
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {},
        });
        return undefined as any;
      }

      if (
        !customer.addressFirstLine ||
        !customer.addressZipcode ||
        !customer.addressPlaceId ||
        customer.addressFirstLine === customer.addressCity
      ) {
        setIsLoading(false);
        present({
          header: i18n.t("customer.Error"),
          message: i18n.t("customer.AdressNotComplete"),
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {},
        });
        return undefined as any;
      } else {
        await authCtx.updateUserData({
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          address_second_line: addressComplement,
          selected_maps_suggestion: selectedSuggestion,
          city: addressCity,
          zipcode: addressZipCode,
        } as customerData);
        setIsLoading(false);
        return { distanceToRestaurant, customer } as any;
      }
    } catch (err) {
      setIsLoading(false);
      present({
        header: i18n.t("header.deliveryUnavailable"),
        message: i18n.t("customer.NotDeliver"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => {},
      });
      return undefined as any;
    }
  };

  return (
    <NewCustomerContext.Provider
      value={{
        isLoading: isSubmitting || isLoading,
        isSearching,
        phone,
        phoneInputHandler,
        addressComplement,
        addressComplementInputHandler,
        firstname,
        firstnameInputHandler,
        lastname,
        lastnameInputHandler,
        selectedSuggestion,
        suggestionSelectionHandler,
        addressCity,
        addressCityHandler,
        addressZipCode,
        addressZipCodeHandler,
        submitCreationHandler,
        resetContext,
        modalClosingTrigger,
        isSearchingHandler,
        placeId,
        setPlaceIdHandler,
        getCustomer,
        checkAndSetCustomerAddress,
      }}
    >
      {props.children}
    </NewCustomerContext.Provider>
  );
};

export default NewCustomerContext;
