import { IonIcon } from "@ionic/react";
import dayjs from "dayjs";
import { card, navigate, time } from "ionicons/icons";
import Order from "../../../models/Order";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import OrderSummaryItem from "../OrderSummaryItem/OrderSummaryItem";
import "./OrderSummary.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  order: Order;
  onDelete: Function;
  onPrint: Function;
  asRestaurantDisplay?: boolean;
}

const OrderSummary: React.FC<ContainerProps> = (props) => {
  const getTotalPrice = () => {
    return (
      props.order.products.reduce((prev, product) => {
        return prev + product.price;
      }, 0) / 100
    );
  };

  return (
    <div className="order-summary" data-testid="order-summary">
      <div
        className={
          props.asRestaurantDisplay
            ? "order-summary__cart"
            : "order-summary__cart order-summary__cart--as-delivery"
        }
      >
        <div className="order-summary__products">
          {props.order.products.map((product, index) => {
            return (
              <OrderSummaryItem
                key={`summary-item-${index}-${product.id}`}
                product={product}
              />
            );
          })}
          <div className="order-summary__products__spacer" />
        </div>
        <div className="order-summary__total">
          <span>{i18n.t("order.Total")}</span>
          <span>
            {constants.CURRENCY === "€"
              ? getTotalPrice().toFixed(2) + ` ${constants.CURRENCY}`
              : `${constants.CURRENCY}` + getTotalPrice().toFixed(2)}
          </span>
        </div>
      </div>
      <div className="order-summary__mini-infos">
        <div className="order-summary__mini-infos__distance">
          <IonIcon icon={navigate} />
          <span>
            {
              "-"
              // TODO implement real calculation
            }
          </span>
        </div>
        <div className="order-summary__mini-infos__hour">
          <IonIcon icon={time} />
          <span>{dayjs(props.order.createdAt).format("HH:mm")}</span>
        </div>
      </div>
      {props.asRestaurantDisplay && (
        <>
          <BaseButton
            tight
            expand
            margins={"0px 0 0 0"}
            outline
            onClick={props.onDelete}
          >
            {i18n.t("order.CancelOrder")}
          </BaseButton>
          <BaseButton
            tight
            expand
            margins={"16px 0 0 0"}
            onClick={props.onPrint}
          >
            {i18n.t("order.PrintTicket")}
          </BaseButton>
        </>
      )}
    </div>
  );
};

export default OrderSummary;
