import { IonImg } from "@ionic/react";
import Product from "../../../models/Product";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./OrderSummaryItem.css";
import { constants } from "../../../utils/constants";

interface ContainerProps {
  product: Product;
}

const OrderSummaryItem: React.FC<ContainerProps> = (props) => {
  return (
    <div className="order-summary-item" data-testid="order-summary-item">
      <div className="order-summary-item__base-product-infos">
        <div className="order-summary-item__base-product-infos__product-picture">
          <IonImg src={props.product.thumbnailUrl} />
        </div>
        <div className="order-summary-item__base-product-infos__name">
          {props.product.name}
        </div>
        <div className="order-summary-item__base-product-infos__price">
          {constants.CURRENCY === "€"
            ? (props.product.price / 100).toFixed(2) + `${constants.CURRENCY}`
            : `${constants.CURRENCY}` + (props.product.price / 100).toFixed(2)}
        </div>
      </div>
      {props.product.optionsPreset &&
        props.product.optionsPreset?.length != 0 && (
          <div className="order-summary-item__options">
            {props.product.optionsPreset?.map((option) => {
              return (
                <div
                  className="order-summary-item__options__item"
                  key={option.id}
                >
                  <span className="order-summary-item__options__item__name">
                    <div className="order-summary-item__options__item__dot" />{" "}
                    {option.name}
                  </span>
                  <span>
                    {constants.CURRENCY === "€"
                      ? "(+ " +
                        (option.price / 100).toFixed(2) +
                        ` ${constants.CURRENCY})`
                      : "(+ " +
                        `${constants.CURRENCY}` +
                        (option.price / 100).toFixed(2) +
                        ")"}
                  </span>
                </div>
              );
            })}
          </div>
        )}
    </div>
  );
};

export default OrderSummaryItem;
