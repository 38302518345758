import { IonIcon, IonImg } from "@ionic/react";
import {
  fastFood,
  camera,
  informationCircleOutline,
  close,
} from "ionicons/icons";
import "./ProductsGridItem.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import { useState } from "react";

interface ContainerProps {
  name: string;
  price: number;
  basePrice: number;
  hasOptions: boolean;
  pictureUrl: string;
  description?: string;
  editMode?: boolean;
  unavailable?: boolean;
  onClick?: Function;
  longDesc?: string;
}

const ProductsGridItem: React.FC<ContainerProps> = (props) => {
  const [showLongDesc, setShowLongDesc] = useState(false);
  return (
    <div
      className={"products-grid-item"}
      data-testid="products-grid-item"
      onClick={() => {
        return props.onClick
          ? props.unavailable
            ? () => {}
            : props.onClick()
          : () => {};
      }}
    >
      <div
        className={
          props.unavailable
            ? "products-grid-item__picture-and-infos products-grid-item--unavailable"
            : "products-grid-item__picture-and-infos"
        }
      >
        {props.pictureUrl ? (
          <IonImg
            className="products-grid-item__picture-and-infos__picture"
            src={props.pictureUrl}
          />
        ) : props.editMode && !props.pictureUrl ? (
          <div className="products-grid-item__picture-and-infos__placeholder">
            <IonIcon icon={camera} />
          </div>
        ) : (
          <div className="products-grid-item__picture-and-infos__placeholder">
            <IonIcon icon={fastFood} />
          </div>
        )}
        {props.hasOptions && (
          <div className="products-grid-item__picture-and-infos__composition-indicator" />
        )}
        <div className="products-grid-item__picture-and-infos__product-price">
          {constants.CURRENCY === "€"
            ? (props.basePrice / 100).toFixed(2).toLocaleString() +
              `${constants.CURRENCY}`
            : `${constants.CURRENCY}` +
              (props.basePrice / 100).toFixed(2).toLocaleString()}
        </div>
      </div>
      <div
        className={
          props.unavailable
            ? "products-grid-item__product-name products-grid-item--unavailable"
            : "products-grid-item__product-name"
        }
      >
        {props.name}
      </div>
      <div
        className={
          props.unavailable
            ? "products-grid-item__product-description products-grid-item--unavailable"
            : "products-grid-item__product-description"
        }
      >
        {props.description}
      </div>
      {props.longDesc ? (
        <div
          className={
            props.unavailable
              ? "products-grid-item__product-show-long-desc products-grid-item--unavailable"
              : "products-grid-item__product-show-long-desc"
          }
          onClick={(event) => {
            event.stopPropagation();
            setShowLongDesc(true);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.7rem",
            }}
          >
            <IonIcon
              icon={informationCircleOutline}
              style={{ marginRight: "0.15rem", width: 16, height: 16 }}
            ></IonIcon>
            {i18n.t("cart.moreInfos")}
          </div>
        </div>
      ) : null}
      <div
        className={
          showLongDesc
            ? "products-grid-item__product-long-desc slide-up"
            : "products-grid-item__product-long-desc slide-down"
        }
        style={{ backgroundColor: "#fff", padding: "0.5rem" }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {showLongDesc ? (
          <div
            style={{ height: "100%" }}
            onClick={(event) => {
              event.stopPropagation();
              setShowLongDesc(false);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontWeight: "normal",
                cursor: "pointer",
                fontSize: "1.15rem",
                width: "100%",
              }}
              onClick={(event) => {
                event.stopPropagation();
                setShowLongDesc(false);
              }}
            >
              <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                {props.name}
              </div>
              <IonIcon icon={close} />
            </div>

            <div
              style={{
                display: "flex",
                height: " 100%",
                width: "100%",
                fontWeight: "lighter",
                fontSize: "0.75rem",
                marginTop: "0.5rem",
              }}
            >
              {props.longDesc}
            </div>
          </div>
        ) : null}
      </div>
      {props.unavailable ? (
        <div className="products-grid-item__product-unavailable">
          {i18n.t("order.Unavailable")}
        </div>
      ) : null}
    </div>
  );
};

export default ProductsGridItem;
