import {
  IonContent,
  IonImg,
  IonPage,
  IonIcon,
  useIonModal,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useParams, useHistory } from "react-router";
import { bicycle, fastFood, receipt, settings } from "ionicons/icons";
import NewOrderContext from "../../contexts/new-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import OrderTrackingContext from "../../contexts/orders-tracking.ctx";
import OrderTracking from "../Orders/OrderTracking/OrderTracking";
import "./RestaurantHeaderInfo.css";
import { GCS } from "../../services";
import NewCustomerContext from "../../contexts/new-customer.ctx";
import { constants } from "../../utils/constants";
import i18n from "../../translations/i18n";
import RestaurantInfoModal from "./RestaurantInfoModal/RestaurantInfoModal";
import { helpCircleOutline } from "ionicons/icons";
import BaseButton from "../@Base/BaseButton/BaseButton";
interface ContainerProps {}

const RestaurantHeaderInfo: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const orderTrackingCtx = useContext(OrderTrackingContext);
  const newCustomerCtx = useContext(NewCustomerContext);
  const newOrderCtx = useContext(NewOrderContext);

  const [isRestaurantInfoOpen, setIsRestaurantInfoOpen] = useState(false);
  const history = useHistory();
  const [present, dismiss] = useIonModal(RestaurantInfoModal, {
    restaurantDataCtx: restaurantDataCtx,
    newCustomerCtx: newCustomerCtx,
    onDismiss: () => {
      dismiss();
    },
  });

  const toggleModal = () => {
    present();
  };

  return (
    <>
      <OrderTracking
        orderContext={newOrderCtx}
        orderTrackingContext={orderTrackingCtx}
        isOpen={orderTrackingCtx.isModalOpen}
        toggleModal={orderTrackingCtx.toggleModalOpenHandler}
      />
      <div className="shop-page__header__container">
        <div className="shop-page__header">
          <div className="shop-page__header_restaurant-logo-container">
            <IonImg src={restaurantDataCtx.restaurantInfos.logoThumbnailUrl} />
          </div>
          <div style={{ width: "calc(100% - 70px)" }}>
            <div className="shop-page__header__title">
              {restaurantDataCtx.restaurantInfos.name}
            </div>
            {newOrderCtx.currentOrderStep == "WEB_PAYMENT" ? null : (
              <BaseButton margins={"6px 0 6px 0"} onClick={toggleModal} tight>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {i18n.t("header.deliveryInformations")}
                  <IonIcon
                    icon={helpCircleOutline}
                    className="shop-page__header_restaurant-info-logo"
                    color="light"
                  ></IonIcon>
                </div>
              </BaseButton>
            )}
          </div>
        </div>
        {orderTrackingCtx.orders.find(
          (order) =>
            order.status == "DELIVERED" ||
            order.status == "OUT" ||
            order.status == "RECORDED" ||
            order.status == "READY"
        ) && newOrderCtx.currentOrderStep !== "WEB_PAYMENT" ? (
          <div className="shop-page__header_order-tracking-container">
            <div
              className="shop-page__header_order-logo-container"
              onClick={() => orderTrackingCtx.toggleModalOpenHandler()}
            >
              <IonIcon
                className="shop-page__header_order-tracking-logo"
                icon={receipt}
                color={"light"}
              />
              <div className="shop-page__header_order-logo-red-dot"></div>
            </div>
            <div className="shop-page__header_order-order-tracking-text">
              {i18n.t("header.Orders")}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default RestaurantHeaderInfo;
