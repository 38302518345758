import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import "./BaseModalHeader.css";

interface ContainerProps {
  onDismiss: Function;
}

const BaseModalHeader: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-modal-header" data-testid="base-modal-header">
      <span className="base-modal-header__title">{props.children}</span>
      <div
        className="base-modal-header__close-icon"
        onClick={() => props.onDismiss()}
      >
        <IonIcon icon={close} />
      </div>
    </div>
  );
};

export default BaseModalHeader;
