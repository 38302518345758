import buildEnv from "../build.env.json";

export const constants = {
  REGION: buildEnv.REGION == "US" ? "us-central1" : "europe-west1",
  CURRENCY: buildEnv.REGION == "US" ? "$" : "€",
  API_URL:
    buildEnv.BUILD_ENV == "DEV"
      ? buildEnv.REGION == "US"
        ? "https://us-central1-deliours-dev.cloudfunctions.net"
        : "https://europe-west1-delizi-dev.cloudfunctions.net"
      : buildEnv.REGION == "US"
      ? "https://us-central1-deliours-master.cloudfunctions.net"
      : "https://europe-west1-delizi-master.cloudfunctions.net",
  WEBSITE_URL:
    buildEnv.BUILD_ENV == "DEV"
      ? buildEnv.REGION == "US"
        ? "https://deliours-us-site-dev.web.app"
        : "https://delizi-site-dev.web.app"
      : buildEnv.REGION == "US"
      ? "https://deliours.com"
      : "https://iziup.fr",
  SHOP_URL:
    buildEnv.BUILD_ENV == "DEV"
      ? buildEnv.REGION == "US"
        ? "https://deliours-us-shop-dev.web.app"
        : "https://delizi-shop-dev.web.app"
      : buildEnv.REGION == "US"
      ? "https://shop.deliours.com"
      : "https://shop.iziup.fr",
  STRIPE_PUBLIC_KEY:
    buildEnv.BUILD_ENV == "DEV"
      ? buildEnv.REGION == "US"
        ? "pk_test_51Oxu8oAwqUUHaNXZNRnPw10oVrOFndwpDets7yKyLsAjTjZjs9CMnxaaiqxib4zHjzmS4dEfnMA8Z5mSZHIKSYDu00KEVsB1SX"
        : "pk_test_51LhcJaDSq2b5kou3AWLRqtUyFwm34sD54870SrLCtMXJPMX83HvZ2WxFC2Vq8H9ce5uQevU7DSYuvB8MIJ7N9jCf00Il6CbTqg"
      : buildEnv.REGION == "US"
      ? "pk_live_51Oxu8oAwqUUHaNXZ6BwdW8ywX0263iS5w7EHh5R68zmt6ILBU0zVTVuvaSxfRhKUC6CqMNPb4alGB4NMkxRV9pUw00JEEXJKRx"
      : "pk_live_51LhcJaDSq2b5kou3Z86mUZBMkM3IYcrnoySnExT7rvaImJ4qjBz6BDjZigiErUh9ICDaUenOPOBQAKzc1eHuN4FA00eiDXPww9",
};
