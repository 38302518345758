import React, { useEffect, useState, useContext, useRef } from "react";
import {
  PaymentElement,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import { IonIcon, IonImg, useIonAlert } from "@ionic/react";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import buildEnv from "../../../build.env.json";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { customerPaymentMethod } from "../../../types";
import CardSelectionComponent from "./CardSelectionComponent/CardSelectionComponent";
import { useHistory } from "react-router-dom";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import {
  colorFill,
  lockClosed,
  chevronDownCircle,
  chevronDown,
} from "ionicons/icons";
import { IonToggle } from "@ionic/react";
interface CheckoutFormProps {
  paymentMethods?: customerPaymentMethod[];
  deletePaymentMethod: (paymentMethodId?: string) => void;
  clientSecret?: string;
  total?: number;
  isPaymentLoading?: boolean;
  setCardFutureUsage?: (active: boolean) => void;
  cardFutureUsage?: boolean;
  setShouldRenderStripeElements: (shouldRenderStripeElements: boolean) => void;
  shouldRenderStripeElements?: boolean;
}

const StripePaymentComponent = ({
  clientSecret,
}: {
  clientSecret?: string;
}) => {
  const [keyForRerender, setKeyForRerender] = useState(0);

  useEffect(() => {
    // Mettez à jour la clé pour forcer la mise à jour du composant
    setKeyForRerender((prevKey) => prevKey + 1);
  }, [clientSecret]);

  return (
    <div key={keyForRerender}>
      {/* Utilisez la nouvelle clé pour forcer la mise à jour */}
      <PaymentElement key={keyForRerender} />
    </div>
  );
};

const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showGoToPayment, setShowGoToPayment] = useState(true);
  const stripeContainerRef = useRef<HTMLDivElement>(null);
  const paymentContainerRef = useRef<HTMLFormElement>(null);
  const [paymentMethodIdSelected, setPaymentMethodIdSelected] = useState(
    props.paymentMethods?.length ? props.paymentMethods[0].id : ""
  );
  const restaurantDataCtx = useContext(RestaurantDataContext);

  let history = useHistory();
  const [present, dismiss] = useIonAlert();

  useEffect(() => {
    if (props.shouldRenderStripeElements && stripeContainerRef) {
      stripeContainerRef.current?.scrollIntoView({
        behavior: "smooth", // Pour un défilement fluide
        block: "start", // Alignement vertical, 'start' signifie que le haut de l'élément sera aligné avec le haut de la fenêtre de visualisation
        inline: "nearest", // Alignement horizontal, 'nearest' signifie que l'élément sera aligné le plus proche possible de la fenêtre de visualisation
      });
    }
  }, [props.shouldRenderStripeElements]);

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          setShowGoToPayment(false);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: [1], // L'élément doit être complètement visible
      rootMargin: "0px",
    });

    if (paymentContainerRef.current) {
      observer.observe(paymentContainerRef.current);
    }

    // Nettoyez l'observation lorsque le composant est démonté
    return () => {
      if (paymentContainerRef.current) {
        observer.unobserve(paymentContainerRef.current);
      }
    };
  }, []);

  const handlePaymentWithPaymentMethod = async () => {
    if (!stripe || !props.clientSecret || !paymentMethodIdSelected) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      return;
    }

    setIsLoading(true);
    try {
      const confirmPaymentReturn = await stripe.confirmCardPayment(
        props.clientSecret,

        {
          payment_method: paymentMethodIdSelected,

          setup_future_usage: "on_session",
          // Make sure to change this to your payment completion page
          return_url:
            process.env.NODE_ENV === "development"
              ? `http://localhost:8100/${restaurantDataCtx.restaurantId}/confirm`
              : buildEnv.BUILD_ENV === "DEV"
              ? `${constants.SHOP_URL}/${restaurantDataCtx.restaurantId}/confirm` // Change on real dev mode
              : `${constants.SHOP_URL}/${restaurantDataCtx.restaurantId}/confirm`,
        },
        {
          handleActions: true,
        }
      );
      ReactPixel.track("Purchase", {
        value: props.total,
        currency: constants.CURRENCY == "$" ? "USD" : "EUR",
      });
      history.push(
        `/${restaurantDataCtx.restaurantId}/confirm?redirect_status=succeeded&payment_intent=paymentintent`
      );
    } catch (err) {
      console.log(err);
      present({
        header: i18n.t("checkout.Error"),
        message: i18n.t("checkout.SorryError"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => {},
      });
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    ReactPixel.track("Purchase", {
      value: props.total,
      currency: constants.CURRENCY == "$" ? "USD" : "EUR",
    });
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          process.env.NODE_ENV === "development"
            ? `http://localhost:8100/${restaurantDataCtx.restaurantId}/confirm`
            : buildEnv.BUILD_ENV === "DEV"
            ? `${constants.SHOP_URL}/${restaurantDataCtx.restaurantId}/confirm` // Change on real dev mode
            : `${constants.SHOP_URL}/${restaurantDataCtx.restaurantId}/confirm`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message || "");
      present({
        header: "Erreur",
        message: error.message || "",
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => {},
      });
    } else {
      present({
        header: i18n.t("checkout.Error"),
        message: i18n.t("checkout.UnexpectedError"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => {},
      });
    }

    setIsLoading(false);
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={{
        marginTop: "1.25rem",
      }}
      ref={paymentContainerRef}
    >
      {showGoToPayment ? (
        <div
          style={{
            position: "fixed",
            bottom: 24,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem 1rem",
              backgroundColor: "var(--ion-color-primary)",
              borderRadius: 8,
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
              cursor: "pointer",
            }}
            onClick={() =>
              paymentContainerRef.current?.scrollIntoView({
                behavior: "smooth", // Pour un défilement fluide
                block: "start", // Alignement vertical, 'start' signifie que le haut de l'élément sera aligné avec le haut de la fenêtre de visualisation
                inline: "nearest", // Alignement horizontal, 'nearest' signifie que l'élément sera aligné le plus proche possible de la fenêtre de visualisation
              })
            }
          >
            <div style={{ marginRight: "0.3rem" }}>
              {i18n.t("order.goToPayment")}
            </div>
            <IonIcon
              style={{ width: 25, height: 25, color: "" }}
              icon={chevronDown}
            ></IonIcon>
          </div>
        </div>
      ) : null}

      <div>
        <div
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "var(--ion-color-secondary)",
          }}
        >
          {i18n.t("order.orderPayment")}
        </div>
        <div style={{ fontSize: 12, fontStyle: "italic" }}>
          {i18n.t("order.securedTransactions")}
        </div>
      </div>
      {props.paymentMethods && props.paymentMethods?.length ? (
        <div className="payment-method-container">
          <div className="payment-method-container-options">
            {props.paymentMethods && props.paymentMethods?.length
              ? props.paymentMethods?.map((paymentMethod) => {
                  if (paymentMethod.is_expired == false) {
                    return (
                      <CardSelectionComponent
                        key={paymentMethod.id}
                        paymentMethod={paymentMethod}
                        selected={paymentMethod.id == paymentMethodIdSelected}
                        onClick={() => {
                          setPaymentMethodIdSelected(paymentMethod.id);
                        }}
                        onDelete={props.deletePaymentMethod}
                      />
                    );
                  }
                })
              : null}

            <CardSelectionComponent
              key={"add-card-payment"}
              selected={true}
              onClick={() => {
                props.setShouldRenderStripeElements(true);
              }}
              addCard
            />
          </div>
          {paymentMethodIdSelected ? (
            <BaseButton
              expand
              disabled={isLoading || !stripe || !elements}
              isLoading={isLoading || props.isPaymentLoading}
              type="button"
              onClick={() => {
                handlePaymentWithPaymentMethod();
              }}
            >
              {i18n.t("checkout.ConfirmPayment")}
            </BaseButton>
          ) : null}
        </div>
      ) : null}

      {props.paymentMethods &&
      props.paymentMethods?.length &&
      !props.shouldRenderStripeElements ? null : (
        <div
          style={{ marginTop: "0.5rem" }}
          id="stripe-element-container"
          ref={stripeContainerRef}
        >
          <StripePaymentComponent key={props.clientSecret} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "0.35rem",
            }}
          >
            <IonToggle
              mode={"ios"}
              checked={props.cardFutureUsage}
              onIonChange={(event) =>
                props.setCardFutureUsage?.(event.detail.checked)
              }
            />
            <span
              style={{
                fontSize: 14,
                color: "var(--ion-color-secondary)",
                fontStyle: "italic",
                marginLeft: 6,
                width: "80%",
                margin: "0.5rem 0",
                paddingLeft: 8,
              }}
            >
              {" "}
              {i18n.t("order.cardFutureUsageAgreement")}
            </span>
          </div>
          <BaseButton
            expand
            disabled={isLoading || !stripe || !elements}
            type="submit"
            isLoading={isLoading || props.isPaymentLoading}
          >
            {i18n.t("checkout.ConfirmPayment")}
          </BaseButton>
          {message && (
            <div style={{ color: "red" }} id="payment-message">
              {message}
            </div>
          )}
        </div>
      )}
    </form>
  );
};
export default CheckoutForm;
