import { IonIcon, IonImg } from "@ionic/react";
import { time, hourglass } from "ionicons/icons";
import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import {
  getTodayWorkingHours,
  isRestaurantOpen,
  getIncludedHours,
} from "../OrderPlannedTimeSelector/OrderPlannedTimeSelector";
import "./OrderIsPlannedOrderSelector.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrderIsPlannedOrderSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setHours(23, 59, 59);

  // Get opening hours accodring to today's day
  const todayIndex: number = minDate.getDay();
  const todayWorkingHours = getTodayWorkingHours(restaurantDataCtx, todayIndex);

  const isOpen = isRestaurantOpen(restaurantDataCtx, todayWorkingHours);

  const getSelectorClasses = (
    orderPlanningType: "NOW" | "PLANNED",
    isOpen: boolean
  ) => {
    const base = ["orders-planned-order-type-selector__selector"];
    if (newOrderCtx.orderPlanningType == orderPlanningType && isOpen) {
      base.push("orders-planned-order-type-selector__selector--active");
    }
    if (!isOpen) {
      base.push("orders-planned-order-type-selector__selector--disabled");
    }
    return base.join(" ");
  };

  const goToPreviousStep = () => {
    newOrderCtx.currentOrderStepChangeHandler("IS_A_DELIVERY_SELECTION");
  };

  const onConfirmHandler = () => {
    if (newOrderCtx.orderPlanningType === "NOW") {
      if (newOrderCtx.orderType == "ON_PLACE") {
        if (authCtx.authState === AuthStates.NOT_AUTH) {
          newOrderCtx.currentOrderStepChangeHandler("SIGN_IN");
        } else if (authCtx.authState === AuthStates.AUTH) {
          if (restaurantDataCtx.restaurantInfos.enableDoorPayment) {
            newOrderCtx.currentOrderStepChangeHandler("PAYMENT_TYPE_SELECTION");
          } else {
            newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
          }
        }
      } else {
        if (authCtx.authState === AuthStates.NOT_AUTH) {
          newOrderCtx.currentOrderStepChangeHandler("SIGN_IN");
        } else if (authCtx.authState === AuthStates.AUTH) {
          newOrderCtx.currentOrderStepChangeHandler(
            "CUSTOMER_ADDRESS_SELECTION"
          );
        }
      }
    } else if (newOrderCtx.orderPlanningType === "PLANNED") {
      newOrderCtx.currentOrderStepChangeHandler("ORDER_TIME_SELECTION");
    }
  };

  const todayWorkingHoursRemaining = getIncludedHours(
    getTodayWorkingHours(restaurantDataCtx, todayIndex)
  );
  return (
    <div
      className="orders-planned-order-type-selector"
      data-testid="orders-planned-order-type-selector"
    >
      <div className="orders-planned-order-type-selector__selector-container">
        <div className="orders-planned-order-type-selector__selector-single-container">
          <div
            className={getSelectorClasses("NOW", isOpen)}
            onClick={
              !isOpen
                ? () => null
                : () => newOrderCtx.orderPlanningTypeSelectionHandler("NOW")
            }
            data-testid="orders-planned-order-type-selector__selector"
          >
            <div className="orders-planned-order-type-selector__selector__icon">
              <IonIcon icon={hourglass} />
            </div>
            <span className="orders-planned-order-type-selector__selector__label">
              {i18n.t("order.OrderSoon")}
            </span>
          </div>
          {!isOpen ? (
            <div className="orders-planned-order-type-selector__disabled">
              {i18n.t("order.BusinessHours")}
            </div>
          ) : null}
        </div>
        <div
          style={{
            textAlign: "center",
            color: "var(--ion-color-secondary)",
            fontStyle: "italic",
            fontWeight: "bold",
            fontSize: 30,
          }}
        >
          {i18n.t("order.or")}
        </div>
        <div className="orders-planned-order-type-selector__selector-single-container">
          <div
            className={getSelectorClasses(
              "PLANNED",
              todayWorkingHoursRemaining.length !== 0
            )}
            onClick={
              todayWorkingHoursRemaining.length === 0
                ? () => null
                : () => newOrderCtx.orderPlanningTypeSelectionHandler("PLANNED")
            }
            data-testid="orders-planned-order-type-selector__selector"
          >
            <div className="orders-planned-order-type-selector__selector__icon">
              <IonIcon icon={time} />
            </div>
            <span className="orders-planned-order-type-selector__selector__label">
              {i18n.t("order.OrderLater")}
            </span>
          </div>
          {todayWorkingHoursRemaining.length === 0 ? (
            <div className="orders-planned-order-type-selector__disabled">
              {i18n.t("order.NoTime")}
            </div>
          ) : null}
        </div>
      </div>
      <div className="orders-planned-order-type-selector__button-container">
        <div className="">
          <BaseButton
            expand
            tight
            outline
            // disabled={isPreviousButtonDisabled()}
            onClick={() => {
              goToPreviousStep();
            }}
          >
            {i18n.t("order.Previous")}
          </BaseButton>
        </div>
        {!isOpen && todayWorkingHoursRemaining.length === 0 ? null : (
          <div className="">
            <BaseButton
              onClick={() => {
                onConfirmHandler();
              }}
              tight
              expand
              isLoading={newOrderCtx.isOrderInProgress}
            >
              {newOrderCtx.isOrderInProgress
                ? i18n.t("order.Waiting")
                : i18n.t("order.Next")}
            </BaseButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderIsPlannedOrderSelector;
