import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import queryString from "query-string";
import { Storage } from "@ionic/storage";
import "./WebConfirm.css";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
import RestaurantHeaderInfo from "../../components/RestaurantHeaderInfo/RestaurantHeaderInfo";
import {
  alertCircleOutline,
  checkboxOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";
import { RouteComponentProps, useParams } from "react-router";
import OrderTrackingContext from "../../contexts/orders-tracking.ctx";
import BaseButton from "../../components/@Base/BaseButton/BaseButton";
import NewOrderContext from "../../contexts/new-order.ctx";
import i18n from "../../translations/i18n";

const WebConfirm: React.FC = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const newOrderCtx = useContext(NewOrderContext);
  const [paymentIntent, setPaymentIntent] = useState("");
  const [shouldRetryPayment, setshouldRetryPayment] = useState(false);
  const [redirectStatus, setRedirectStatus] = useState("");
  const history = useHistory();
  let { restaurantUrlSlug } = useParams<{ restaurantUrlSlug: string }>();
  const orderTrackingCtx = useContext(OrderTrackingContext);

  useEffect(() => {
    restaurantDataCtx.initRestaurantData(restaurantUrlSlug);
  }, []);

  useEffect(() => {
    if (orderTrackingCtx.mergedOrders[0]?.payment_status == "FAILED") {
      newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
      newOrderCtx.setCartHandler(orderTrackingCtx.mergedOrders[0].products);
      newOrderCtx.orderPlanningTimeSelectionHandler(
        orderTrackingCtx.mergedOrders[0].plannedTime
      );
      newOrderCtx.orderTypeSelectionHandler(
        orderTrackingCtx.mergedOrders[0].type
      );
      newOrderCtx.customerSelectionHandler(
        orderTrackingCtx.mergedOrders[0].customer || {}
      );
      newOrderCtx.orderPlanningTypeSelectionHandler(
        orderTrackingCtx.mergedOrders[0].planningType
      );
      newOrderCtx.paymentTypeSelectionHandler(
        orderTrackingCtx.mergedOrders[0].paymentType
      );
    } else if (orderTrackingCtx.mergedOrders[0]?.payment_status) {
      newOrderCtx.currentOrderStepChangeHandler("PRODUCTS_SELECTION");
      newOrderCtx.resetContext();
    }
  }, [orderTrackingCtx.mergedOrders]);
  const handleRecoveryFromStorage = async () => {
    const storage = new Storage();
    await storage.create();
    const recoveredPaymentIntent = await storage.get("paymentIntent");
    if (recoveredPaymentIntent) {
      setPaymentIntent(recoveredPaymentIntent);
    }
  };

  const handleSaveOnStorage = async (paymentIntent: string) => {
    const storage = new Storage();
    await storage.create();
    await storage.set("paymentIntent", paymentIntent);
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.redirect_status && parsed.payment_intent) {
      setRedirectStatus(parsed.redirect_status as string);
      setPaymentIntent(parsed.payment_intent as string);
      if (parsed.redirect_status === "succeeded") {
        handleSaveOnStorage(parsed.payment_intent as string);
      }
    } else {
      handleRecoveryFromStorage();
    }
  }, []);

  const lastOrderSucceeded = orderTrackingCtx.mergedOrders[0]?.payment_status;
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="web-confirm-main-container">
          <RestaurantHeaderInfo />
          {restaurantDataCtx.isInit &&
          redirectStatus &&
          orderTrackingCtx.mergedOrders.length ? (
            <div className="web-confirm">
              {lastOrderSucceeded === "PAID" || !lastOrderSucceeded ? (
                <div className="web-confirm__success-container">
                  <IonIcon icon={checkmarkCircleOutline} />
                  <span>{i18n.t("pages.OrderOk")}</span>
                  <span className="web-confirm__success-container__phone">
                    {i18n.t("pages.ContactUs")}{" "}
                    {restaurantDataCtx.restaurantInfos.phone}
                  </span>
                  <div className="web-confirm__success-container-button">
                    <BaseButton
                      onClick={() => {
                        orderTrackingCtx.toggleModalOpenHandler();
                      }}
                    >
                      {i18n.t("pages.Tracking")}
                    </BaseButton>
                    <BaseButton
                      outline
                      expand
                      type="button"
                      onClick={() => {
                        history.push(`/${restaurantDataCtx.restaurantId}`);
                      }}
                    >
                      {i18n.t("pages.Return")}
                    </BaseButton>
                  </div>
                </div>
              ) : lastOrderSucceeded === "FAILED" ? (
                <div className="web-confirm__error-container">
                  <IonIcon icon={alertCircleOutline} />
                  <span>{i18n.t("pages.OrderProblem")} </span>
                  <span className="web-confirm__success-container__phone">
                    {i18n.t("pages.ContactUs")}{" "}
                    {restaurantDataCtx.restaurantInfos.phone}
                  </span>
                  <div className="web-confirm__success-container-button">
                    <BaseButton
                      big
                      expand
                      type="button"
                      onClick={() => {
                        history.push(`/${restaurantDataCtx.restaurantId}`);
                      }}
                    >
                      {i18n.t("pages.retry")}
                    </BaseButton>
                  </div>
                </div>
              ) : lastOrderSucceeded === "WAITING" ? (
                <div className="web-confirm__warning-container">
                  <IonIcon icon={alertCircleOutline} />
                  <span>{i18n.t("order.paymentProcessing")} </span>
                  <span style={{ color: "var(--ion-color-secondary)" }}>
                    {i18n.t("order.dontClose")}{" "}
                  </span>
                  <div style={{ margin: "1rem 0" }}>
                    <AppLoading />
                  </div>
                  <div className="web-confirm__success-container-button">
                    <BaseButton
                      big
                      expand
                      type="button"
                      onClick={() => {
                        history.push(`/${restaurantDataCtx.restaurantId}`);
                      }}
                    >
                      {i18n.t("pages.Return")}
                    </BaseButton>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <AppLoading />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WebConfirm;
