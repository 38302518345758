import { IonContent, IonImg, IonPage, IonIcon, IonModal } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import {
  bicycle,
  colorFill,
  fastFood,
  receipt,
  settings,
  warningOutline,
} from "ionicons/icons";
import OrderTrackingCart from "./OrderTrackingCart/OrderTrackingCart";
import RestaurantDataContext from "../../../../contexts/restaurant-data.ctx";
import "./OrderTrackingItem.css";
import { Order } from "../../../../types";
import OrdersCartItem from "../../OrdersCartItem/OrdersCartItem";
import { constants } from "../../../../utils/constants";
import i18n from "../../../../translations/i18n";
import { useIonAlert } from "@ionic/react";
import OrderTrackingContext from "../../../../contexts/orders-tracking.ctx";
import BaseButton from "../../../@Base/BaseButton/BaseButton";
import NewOrderContext from "../../../../contexts/new-order.ctx";
import { customToFixed } from "../../../../utils/customTofixed";
interface ContainerProps {
  order: Order;
  restaurantDataCtx: any;
  dismiss: Function;
  // onConfirmAndDismiss: Function;
}

const getTotalPriceFromProducts = (order: Order) => {
  const reducedAmount = order.products
    .map((product) => {
      return product.price | 0;
    })
    .reduce((prev, current) => {
      return current + prev;
    }, 0);

  return reducedAmount == 0
    ? "-"
    : (reducedAmount / 100).toFixed(2).toLocaleString();
};

const getFormatedTimeFromDate = (date: Date) => {
  let tmpHour = date.getHours();
  let tmpMinutes = date.getMinutes();
  let formatedHour = tmpHour < 10 ? `0${tmpHour}` : `${tmpHour}`;
  let formatedMinutes = tmpMinutes < 10 ? `0${tmpMinutes}` : `${tmpMinutes}`;
  return `${formatedHour}:${formatedMinutes}`;
};

const OrderTrackingItem: React.FC<ContainerProps> = (props) => {
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [amount, setAmount] = useState(0);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const orderTrackingCtx = useContext(OrderTrackingContext);
  const newOrderCtx = useContext(NewOrderContext);
  const history = useHistory();
  const [present, dismiss] = useIonAlert();
  const [presentReview, dismissReview] = useIonAlert();

  const retryPayment = () => {
    newOrderCtx.setCartHandler(orderTrackingCtx.mergedOrders[0].products);
    newOrderCtx.orderPlanningTimeSelectionHandler(
      orderTrackingCtx.mergedOrders[0].plannedTime
    );
    newOrderCtx.orderTypeSelectionHandler(
      orderTrackingCtx.mergedOrders[0].type
    );
    newOrderCtx.customerSelectionHandler(
      orderTrackingCtx.mergedOrders[0].customer || {}
    );
    newOrderCtx.orderPlanningTypeSelectionHandler(
      orderTrackingCtx.mergedOrders[0].planningType
    );
    newOrderCtx.paymentTypeSelectionHandler(
      orderTrackingCtx.mergedOrders[0].paymentType
    );
    newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
    props.dismiss();
    history.push(`/${restaurantDataCtx.restaurantId}`);
  };
  useEffect(() => {
    setDeliveryFees(
      props.order.delivery_fees ? (props.order.delivery_fees * 100) / 10000 : 0
    );
    setAmount(
      props.order.total_price ? (props.order.total_price * 100) / 10000 : 0
    );
  }, [
    restaurantDataCtx.restaurantInfos.deliveryFees,
    restaurantDataCtx.restaurantInfos.freeDeliveryFeesTrigger,
    restaurantDataCtx.restaurantInfos.availableCitiesOnDelivery,
    props.order.products,
  ]);

  const plannedTime =
    props.order.planningType == "PLANNED" && props.order.plannedTime
      ? getFormatedTimeFromDate(props.order.plannedTime)
      : null;

  const estimatedTime =
    props.order.planningType == "NOW" && props.order.estimated_time
      ? getFormatedTimeFromDate(props.order.estimated_time)
      : null;

  var deliveryTimeDateObj = props.order.createdAt
    ? props.restaurantDataCtx.restaurantInfos.currentDeliveryTime
      ? new Date(
          props.order.createdAt.getTime() +
            props.restaurantDataCtx.restaurantInfos.currentDeliveryTime * 60000
        )
      : new Date(props.order.createdAt.getTime() + 30 * 60000)
    : null;

  var clickAndCollectTimeDateObj = props.order.createdAt
    ? props.restaurantDataCtx.restaurantInfos.currentClickAndCollectTime
      ? new Date(
          props.order.createdAt.getTime() +
            props.restaurantDataCtx.restaurantInfos.currentClickAndCollectTime *
              60000
        )
      : new Date(props.order.createdAt.getTime() + 20 * 60000)
    : null;

  const estimatedDeliveryTime = deliveryTimeDateObj
    ? getFormatedTimeFromDate(deliveryTimeDateObj)
    : null;
  const estimatedClickAndCollectTime = clickAndCollectTimeDateObj
    ? getFormatedTimeFromDate(clickAndCollectTimeDateObj)
    : null;

  return (
    <div className="order-tracking-item__main-container">
      <div className="order-tracking-item__customer-info-container">
        <div className="order-tracking-item__customer-info-name">
          {props.order.customer?.firstname} {props.order.customer?.lastname}
        </div>
        <div className="order-tracking-item__customer-info-text">
          {props.order.customer?.addressFirstLine}
        </div>
        <div className="order-tracking-item__customer-info-text-bold">
          {props.order.customer?.addressSecondLine}
        </div>
        <div className="order-tracking-item__customer-info-text">
          {props.order.customer?.addressZipcode}{" "}
          {props.order.customer?.addressCity}
        </div>
        <div className="order-tracking-item__customer-info-text">
          {props.order.customer?.phone}
        </div>
      </div>

      <div className="order-tracking-item__order-number-container">
        <div className="order-tracking-item__order-number-amount">
          {i18n.t("order.OrderNumber")} {props.order.number}
        </div>
        <div className="order-tracking-item__order-type">
          {props.order.type == "DELIVERY"
            ? i18n.t("order.Delivery")
            : i18n.t("order.Collect")}
        </div>
      </div>

      <div className="order-tracking-item__order-info-container">
        <div className="order-tracking-item__order-status-container">
          <div className="order-tracking-item__order-status-text">
            {i18n.t("order.Status")}
          </div>

          {props.order.specialStatus !== "LOCAL_UNPAID" ? (
            props.order.payment_status == "WAITING" ? (
              <div className="order-tracking-item__order-status">
                {" "}
                <div
                  className={"order-tracking-item__order-status-circle-orange"}
                ></div>
                <div className="order-tracking-item__order-status-text-big">
                  {i18n.t("order.PaymentWaiting")}
                </div>
              </div>
            ) : props.order.payment_status == "FAILED" ? (
              <div className="order-tracking-item__order-status">
                {" "}
                <IonIcon
                  icon={warningOutline}
                  style={{ color: "red", marginRight: "0.25rem" }}
                ></IonIcon>
                <div
                  className="order-tracking-item__order-status-text-big"
                  style={{ color: "red" }}
                >
                  {i18n.t("checkout.paymentDeclined")}
                </div>
              </div>
            ) : (
              <div className="order-tracking-item__order-status">
                {" "}
                <div
                  className={
                    props.order.status == "OUT" ||
                    props.order.status == "RECORDED"
                      ? "order-tracking-item__order-status-circle-orange"
                      : props.order.status == "NOT_IN_PRODUCTION"
                      ? "order-tracking-item__order-status-circle-gray"
                      : "order-tracking-item__order-status-circle-green"
                  }
                ></div>
                <div className="order-tracking-item__order-status-text-big">
                  {props.order.status == "DELIVERED"
                    ? i18n.t("order.Delivered")
                    : props.order.status == "OUT"
                    ? i18n.t("order.PickedByDriver")
                    : props.order.status == "RECORDED"
                    ? i18n.t("order.Progress")
                    : props.order.status == "READY"
                    ? i18n.t("order.Ready")
                    : props.order.status == "NOT_IN_PRODUCTION"
                    ? i18n.t("order.Pending")
                    : i18n.t("order.Ready")}
                </div>
              </div>
            )
          ) : (
            <div className="order-tracking-item__order-status">
              {" "}
              <div
                className={
                  props.order.status == "OUT" ||
                  props.order.status == "RECORDED"
                    ? "order-tracking-item__order-status-circle-orange"
                    : props.order.status == "NOT_IN_PRODUCTION"
                    ? "order-tracking-item__order-status-circle-gray"
                    : "order-tracking-item__order-status-circle-green"
                }
              ></div>
              <div className="order-tracking-item__order-status-text-big">
                {props.order.status == "DELIVERED"
                  ? i18n.t("order.Delivered")
                  : props.order.status == "OUT"
                  ? i18n.t("order.PickedByDriver")
                  : props.order.status == "RECORDED"
                  ? i18n.t("order.Progress")
                  : props.order.status == "READY"
                  ? i18n.t("order.Ready")
                  : props.order.status == "NOT_IN_PRODUCTION"
                  ? i18n.t("order.Pending")
                  : i18n.t("order.Ready")}
              </div>
            </div>
          )}
        </div>
        {props.order.payment_status == "FAILED" ? (
          <>
            <div className="order-tracking-item__order-status-container">
              <div
                className="order-tracking-item__order-status-text"
                style={{ fontWeight: 500, fontSize: 16 }}
              >
                {i18n.t("order.OrderAgain")}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <BaseButton outline onClick={retryPayment}>
                {i18n.t("pages.retry")}
              </BaseButton>
            </div>
          </>
        ) : props.order.payment_status == "WAITING" ? (
          <>
            <div className="order-tracking-item__order-status-container">
              <div className="order-tracking-item__order-status-text">
                {i18n.t("order.PaymentNow")}
              </div>
              <div className="order-tracking-item__order-status">
                {" "}
                <div
                  className={"order-tracking-item__order-status-circle-orange"}
                ></div>
                <div className="order-tracking-item__order-status-text-big">
                  {i18n.t("order.PaymentWaiting")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="order-tracking-item__order-status-container">
              <div className="order-tracking-item__order-status-text">
                {props.order.type == "DELIVERY"
                  ? i18n.t("order.Scheduled")
                  : i18n.t("order.EstimatedOrder")}
              </div>
              <div className="order-tracking-item__order-status-text-big">
                {props.order.planningType == "PLANNED"
                  ? plannedTime
                  : props.order.estimated_time
                  ? estimatedTime
                  : props.order.type == "DELIVERY"
                  ? estimatedDeliveryTime
                  : estimatedClickAndCollectTime}
              </div>
            </div>
            <div className="order-tracking-item__order-status-text-small">
              {i18n.t("order.HoursChange")}
            </div>
            <div className="order-tracking-item__order-status-container">
              <div className="order-tracking-item__order-status-text">
                {i18n.t("order.PaymentNow")}
              </div>
              <div className="order-tracking-item__order-status">
                {" "}
                <div
                  className={
                    props.order.specialStatus == "LOCAL_UNPAID"
                      ? "order-tracking-item__order-status-circle-orange"
                      : "order-tracking-item__order-status-circle-green"
                  }
                ></div>
                <div className="order-tracking-item__order-status-text-big">
                  {props.order.specialStatus == "LOCAL_UNPAID"
                    ? i18n.t("order.Reception")
                    : i18n.t("order.Completed")}{" "}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className="order-tracking-item__order-favorite-container">
          <div className="order-tracking-item__order-status-text">
            {" "}
            La prochaine fois commandez en 1 clic grace aux commandes favorites !{" "}
          </div>
          <div className="order-tracking-item__order-favorite-button">
            {" "}
            Ajouter aux favoris{" "}
          </div>
        </div> */}

      <div className="order-tracking-item__order-total-container">
        <div className="order-tracking-item__order-total-text">
          {i18n.t("order.Total")}
        </div>
        <div className="order-tracking-item__order-total-text">
          {constants.CURRENCY === "€"
            ? customToFixed(amount) + constants.CURRENCY
            : constants.CURRENCY + customToFixed(amount)}
          {props.order.type == "DELIVERY" ? (
            deliveryFees > 0 ? (
              <div className="order-tracking-item__order-total-fees">
                (
                {constants.CURRENCY === "€"
                  ? customToFixed(amount - deliveryFees) +
                    constants.CURRENCY +
                    " + " +
                    customToFixed(deliveryFees) +
                    constants.CURRENCY +
                    " " +
                    i18n.t("order.OfDelivery")
                  : constants.CURRENCY +
                    customToFixed(amount - deliveryFees) +
                    " + " +
                    constants.CURRENCY +
                    customToFixed(deliveryFees) +
                    " " +
                    i18n.t("order.OfDelivery")}
                )
              </div>
            ) : null
          ) : null}
        </div>
      </div>
      <div className="order-tracking-item__order-cart-container">
        {props.order.products.map((product, index) => {
          return (
            <OrdersCartItem
              key={`full-cart-item-${index}-${product.id}`}
              product={product}
              index={index}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "0 0.75rem",
        }}
      >
        {props.restaurantDataCtx.restaurantInfos.phone ? (
          <div className="order-tracking-item__order-call-container">
            {props.order.payment_status == "WAITING" ? (
              <div
                className="order-tracking-item__order-call-button"
                onClick={retryPayment}
              >
                {i18n.t("order.Payment")}
              </div>
            ) : (
              <a
                href={`tel:${props.restaurantDataCtx.restaurantInfos.phone}`}
                className="order-tracking-item__order-call-button"
              >
                {i18n.t("pages.Call")}
              </a>
            )}
          </div>
        ) : null}
        <div
          className="order-tracking-item__order-confirm-button"
          onClick={() => {
            present({
              header: i18n.t("order.Confirm"),
              message: i18n.t("order.clientConfirmOrderWarning"),
              buttons: [
                {
                  text: i18n.t("order.Confirm"),
                  handler: async (d) => {
                    await orderTrackingCtx.setOrderClientAsDone(props.order.id);
                    if (restaurantDataCtx.restaurantInfos.reviewLink) {
                      presentReview({
                        header: i18n.t("order.supportUs"),
                        message: i18n.t("order.leaveReviewIsImportant"),
                        buttons: [
                          {
                            text: i18n.t("order.leaveReview"),
                            handler: async (d) => {
                              window.open(
                                restaurantDataCtx.restaurantInfos.reviewLink,
                                "_blank"
                              );
                            },
                          },
                          {
                            text: i18n.t("order.cancel"),
                            handler: async (d) => {
                              dismissReview();
                            },
                          },
                        ],
                        onDidDismiss: (e) => {},
                      });
                    }
                  },
                },
                {
                  text: i18n.t("order.cancel"),
                  handler: async (d) => {
                    dismiss();
                  },
                },
              ],
              onDidDismiss: (e) => {},
            });
          }}
        >
          {i18n.t("order.Confirm")}
        </div>
      </div>
    </div>
  );
};

export default OrderTrackingItem;
