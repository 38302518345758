import config from "../configs/google-cloud-service.config";
import { Loader } from "@googlemaps/js-api-loader";

const gcServiceConfig = config();
const GOOGLE_MAPS_API_KEY = gcServiceConfig.googleMapsApiKey;

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places", "geometry"],
});

export default class GoogleCloudService {
  async getPlacesSuggestions(
    input: string,
    deviceLatLng: { lat: number; lng: number }
  ) {
    return new Promise((res, rej) => {
      loader
        .load()
        .then((google) => {
          const autocomplete = new google.maps.places.AutocompleteService();
          var location = new google.maps.LatLng(deviceLatLng);
          autocomplete.getPlacePredictions(
            { input, location, radius: 25000 },
            (predictions, status) => {
              const predictionsList: google.maps.places.AutocompletePrediction[] =
                [];
              predictions?.forEach((prediction) => {
                predictionsList.push(prediction);
              });
              res(predictionsList);
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
  async getPlaceGeocoding(
    placeId: string
  ): Promise<google.maps.GeocoderResponse> {
    return new Promise((res, rej) => {
      loader
        .load()
        .then((google) => {
          const geocoder = new google.maps.Geocoder();
          geocoder
            .geocode({ placeId: placeId })
            .then((results) => {
              res(results);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
  async checkDeliveryRange(
    placeId: string,
    maxDeliveryRadius: number,
    restaurantLatitude: number,
    restaurantLongitude: number
  ) {
    return new Promise((resolve, reject) => {
      loader
        .load()
        .then((google) => {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ placeId: placeId }, (results, status) => {
            if (status === "OK" && results && results.length > 0) {
              const userLocation = results[0].geometry.location;
              const restaurantLocation = new google.maps.LatLng(
                restaurantLatitude,
                restaurantLongitude
              );

              const distanceInMeters =
                google.maps.geometry.spherical.computeDistanceBetween(
                  userLocation,
                  restaurantLocation
                );

              const distanceInKm = distanceInMeters / 1000;

              resolve(distanceInKm <= maxDeliveryRadius);
            } else {
              reject(
                "Geocode was not successful for the following reason: " + status
              );
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
  async calculateRealDistance(
    placeIdOrigin: string,
    placeIdDestination: string
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      loader
        .load()
        .then((google) => {
          const directionsService = new google.maps.DirectionsService();
          directionsService.route(
            {
              origin: { placeId: placeIdOrigin },
              destination: { placeId: placeIdDestination },
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (
                status === "OK" &&
                response &&
                response.routes &&
                response.routes.length > 0
              ) {
                const route = response.routes[0];
                resolve(
                  route.legs && route.legs.length > 0 && route.legs[0].distance
                    ? route.legs[0].distance.value / 1000
                    : 0
                ); // Return distance in kilometers
              } else {
                reject(`Error fetching directions: ${status}`);
              }
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }
}
