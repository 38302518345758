import { useContext, useEffect, useState } from "react";
import NewCustomerContext from "../../../contexts/new-customer.ctx";
import NewOrderContext from "../../../contexts/new-order.ctx";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import AddressFetcher from "../../@Shared/AddressFetcher/AddressFetcher";
import { getPreciseDistance } from "geolib";
import { GCS } from "../../../services";
import { Geolocation } from "@ionic-native/geolocation";
import "./CustomerAddressSelector.css";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { customerData } from "../../../types";
import { useIonAlert } from "@ionic/react";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const CustomerAddressSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const newCustomerCtx = useContext(NewCustomerContext);
  const authCtx = useContext(AuthContext);
  const [deviceGeoCoordinates, setDeviceGeoCoordinates] = useState({
    lat: 46, // Center of france
    lng: 2, // Center of france
  });
  const [deviceGeoCoordinatesIsInit, setDeviceGeoCoordinatesIsInit] =
    useState(false);
  const [present, dismiss] = useIonAlert();
  useEffect(() => {
    if (!deviceGeoCoordinatesIsInit) {
      Geolocation.getCurrentPosition()
        .then((resp) => {
          setDeviceGeoCoordinates({
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          });
          setDeviceGeoCoordinatesIsInit(true);
        })
        .catch((e) => {
          console.log(e);
          setDeviceGeoCoordinatesIsInit(true);
        });
    }
  }, []);

  const goToPreviousStep = () => {
    if (newOrderCtx.orderPlanningType == "NOW") {
      newOrderCtx.currentOrderStepChangeHandler("IS_PLANNED_ORDER");
    } else if (newOrderCtx.orderPlanningType == "PLANNED") {
      newOrderCtx.currentOrderStepChangeHandler("ORDER_TIME_SELECTION");
    }
  };

  const handleGoToPayment = async () => {
    const checkCustomerAddressData =
      await newCustomerCtx.checkAndSetCustomerAddress();
    if (checkCustomerAddressData) {
      await authCtx.updateUserData({
        firstname: checkCustomerAddressData.customer?.firstname,
        lastname: checkCustomerAddressData.customer?.lastname,
        phone: checkCustomerAddressData.customer?.phone,
        address_second_line:
          checkCustomerAddressData.customer?.addressComplement,
        selected_maps_suggestion:
          checkCustomerAddressData.customer?.selectedSuggestion,
        city: checkCustomerAddressData.customer?.addressCity,
        zipcode: checkCustomerAddressData.customer?.addressZipCode,
      } as customerData);
      newOrderCtx.customerSelectionHandler(checkCustomerAddressData.customer);
      newOrderCtx.setDistanceToRestaurantHandler(
        checkCustomerAddressData.distanceToRestaurant
      );

      if (restaurantDataCtx.restaurantInfos.enableDoorPayment) {
        newOrderCtx.currentOrderStepChangeHandler("PAYMENT_TYPE_SELECTION");
      } else {
        newOrderCtx.currentOrderStepChangeHandler("WEB_PAYMENT");
      }
    }
  };

  return (
    <div
      className="customer-address-selector"
      data-testid="customer-address-selector"
    >
      <div className="customer-addresse-selector__form">
        <span className="customer-address-selector__form__title">
          {i18n.t("customer.Information")}
        </span>
        <BaseTextInput
          label={i18n.t("customer.FirstName")}
          controller={{
            onChange: (data: any) =>
              newCustomerCtx.firstnameInputHandler(data.target.value),
            value: newCustomerCtx.firstname,
          }}
        />
        <BaseTextInput
          label={i18n.t("customer.LastName")}
          controller={{
            onChange: (data: any) =>
              newCustomerCtx.lastnameInputHandler(data.target.value),
            value: newCustomerCtx.lastname,
          }}
        />
        <BaseTextInput
          label={i18n.t("customer.Phone")}
          controller={{
            onChange: (data: any) =>
              newCustomerCtx.phoneInputHandler(data.target.value),
            value: newCustomerCtx.phone,
          }}
        />
        <AddressFetcher
          selectedPlace={newCustomerCtx.selectedSuggestion}
          onSelect={newCustomerCtx.suggestionSelectionHandler}
          value={newCustomerCtx.selectedSuggestion}
          isLoading={newCustomerCtx.isSearching}
          isLoadingSetter={newCustomerCtx.isSearchingHandler}
          deviceGeoCoordinates={deviceGeoCoordinates}
        />
        <BaseTextInput
          label={i18n.t("customer.AddInformation")}
          controller={{
            onChange: (data: any) =>
              newCustomerCtx.addressComplementInputHandler(data.target.value),
            value: newCustomerCtx.addressComplement,
          }}
        />
        <BaseButton
          expand
          margins={"32px 0 0 0"}
          disabled={
            !newCustomerCtx.firstname ||
            !newCustomerCtx.lastname ||
            !newCustomerCtx.phone ||
            !newCustomerCtx.selectedSuggestion.place_id
          }
          isLoading={newCustomerCtx.isLoading || newCustomerCtx.isSearching}
          onClick={() => handleGoToPayment()}
        >
          {i18n.t("order.Next")}
        </BaseButton>
        <BaseButton
          expand
          outline
          margins={"20px 0 0 0"}
          // disabled={isPreviousButtonDisabled()}
          onClick={() => {
            goToPreviousStep();
          }}
        >
          {i18n.t("customer.Previous")}
        </BaseButton>
      </div>
    </div>
  );
};

export default CustomerAddressSelector;
